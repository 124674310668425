import {
    cloneObject,
    getRandomInt,
    numbroFormatCurrency,
    numbroFormatInt
} from "./helperFunctions";
import Decimal from "break_infinity.js";

let stockID = 0;
const maxNumberOfSingleStock = 1000000;
const maxPortfolioSize = 10; // Max number of different stocks to hold
const buyStockChance = 0.25; // Chance of buying a stock in decimal
const minTimeHold = 16000;  // Number of milliseconds to hold a stock
const sellStockChance = 0.3; // Chance of selling a stock in decimal
let lastStockSale = 0;

/**
 * Decide if we should buy a stock and how much we should spend
 * @param decimalHedgeFundBalance
 * @param investStrategy
 * @param decimalHedgeFundCash
 * @param stocks
 * @param secondsAway
 * @returns {{newStocks: *, bought: boolean, newStock: {}}}
 */
export function maybeBuyStock(decimalHedgeFundBalance, investStrategy, decimalHedgeFundCash, stocks, secondsAway) {

    // Stock List Display Routine
    let riskiness = calculateRiskiness(investStrategy);

    let budget = decimalHedgeFundBalance.div(riskiness).ceil();
    let r = new  Decimal(11).sub(riskiness);

    const decimalOne = new Decimal(1);
    const riskinessIsOne =  riskiness.equals(decimalOne);

    // Maybe keep a cash reserve
    let reserves = decimalHedgeFundBalance.div(r).ceil();
    if ( riskinessIsOne){
        // Go full risk
        reserves = new Decimal(0);
    }

    // Determine the amount for this purchase
    let leftOverCash = decimalHedgeFundCash.sub(budget);

    const leftOverCashSmallerThanReserves = leftOverCash.lessThan(reserves)

    if (leftOverCashSmallerThanReserves && riskinessIsOne && decimalHedgeFundCash.greaterThan(decimalHedgeFundBalance.div(new Decimal(10)))){
        budget = decimalHedgeFundCash;
    } else if (leftOverCashSmallerThanReserves && riskinessIsOne){
        budget = new Decimal(0);
    } else if (leftOverCashSmallerThanReserves){
        budget = decimalHedgeFundCash.sub(reserves);
    }

    leftOverCash = decimalHedgeFundCash.sub(new Decimal(budget));
    // Maybe buy stock
    let stockCreation = {bought: false, newStock: {}, newStocks: stocks};

    if (stocks.length < maxPortfolioSize && decimalHedgeFundCash.greaterThanOrEqualTo(new Decimal(5)) && budget.greaterThanOrEqualTo(decimalOne) && leftOverCash.greaterThanOrEqualTo(reserves)){
        if (secondsAway || Math.random() < buyStockChance){
            stockCreation = createStock(budget, stocks);
            stockCreation.bought = true;
        }
    }
    return stockCreation;
}

/**
 * Decide if we should sell a stock
 * @param stocks
 * @returns {boolean}
 */
export function maybeSellStock(stocks) {

    let sell = false;
    const now = Date.now();
    const minHoldTimeReached = (now - lastStockSale) >= minTimeHold;
    if( stocks.length > 0 && minHoldTimeReached && Math.random() <= sellStockChance ) {
        lastStockSale = now;
        sell = true;
    }

    return sell;
}

/**
 * Create a new asset with name, purchase price and amount and add it to our stock array
 * @param investmentAmount
 * @param stocks
 * @returns {{newStocks: [], newStock: {symbol: string, amount: number, total, price: number, id: number, profit: number, age: number}}}
 */
function createStock(investmentAmount, stocks){

    stockID++;

    // Create asset name
    let sym = generateSymbol();

    // Create stock price
    let roll = new Decimal(Math.random());
    let stockPrice = new Decimal(0);
    if (roll>.99){
        stockPrice = new Decimal(Math.random()*3000).ceil();
    } else if (roll>.85){
        stockPrice = new Decimal(Math.random()*500).ceil();
    } else if (roll>.60){
        stockPrice = new Decimal(Math.random()*150).ceil();
    } else if (roll>.20){
        stockPrice = new Decimal(Math.random()*50).ceil();
    } else {
        stockPrice = new Decimal(Math.random()*15).ceil();
    }

    // Make sure the stock price does not exceed the investment amount
    if ( stockPrice.greaterThan(investmentAmount) ){
        stockPrice = investmentAmount.mul(roll);
    }

    // Determine number of stocks to purchase
    let amount = investmentAmount.div(stockPrice).floor();
    if ( amount.greaterThan(maxNumberOfSingleStock) ) {
        amount = new Decimal(maxNumberOfSingleStock);
    }

    // Prepare stock object
    let newStock = {
        id: stockID,
        symbol: sym,
        price: stockPrice.toString(),
        amount: amount.toString(),
        total: stockPrice.mul(amount).toString(),
        profit: 0,
        age: 0,
    }

    //console.log('createStock', newStock);

    // Add stock to list
    let newStocks = [];
    if( stocks.length > 0 ) {

        // Build new stocks array
        for (let i = 0; i < stocks.length; i++) {
            let currentStock =  cloneObject(stocks[i]);
            newStocks.push(currentStock);
        }

    }
    newStocks.push(newStock);
    return {newStocks: newStocks, newStock: newStock}
}

/**
 * Capitalize the first letter of a string
 * @param string
 * @returns {string}
 */
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Generate a nice ticker name
 * @returns {string}
 */
function generateSymbol() {
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    const consonants = ['b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'v', 'w', 'x', 'y', 'z'];
    const prefixes = ['axo', 'neb', 'cyb', 'cosmo', 'nova', 'meta', 'xeno', 'galaxy', 'astro', 'hyper'];
    const suffixes = ['tech', 'corp', 'sys', 'net', 'dynamics', 'cyber', 'core', 'gen', 'sphere', 'zone'];

    let name = '';
    // Generate a random prefix
    name += capitalizeFirstLetter(prefixes[getRandomInt(0, prefixes.length -1)]);
    // Add a random consonant-vowel-consonant syllable
    name += capitalizeFirstLetter(consonants[getRandomInt(0,  consonants.length - 1)]);
    name += vowels[getRandomInt(0, vowels.length - 1)];
    name += consonants[getRandomInt(0, consonants.length - 1)];
    // Generate a random suffix
    name += capitalizeFirstLetter(suffixes[getRandomInt(0, suffixes.length - 1)]);

    return name;

}

/**
 * Update the stock prices
 * @param decimalHedgeFundCash
 * @param stocks
 * @param tradingEngineLevel
 * @param investStrategy
 * @param secondsAway
 * @returns {{newStockBalance: number, decimalHedgeFundCash, updatedStocks: []}}
 */
export function handleUpdateStocks(decimalHedgeFundCash, stocks, tradingEngineLevel, investStrategy, secondsAway) {

    // Stock List Display Routine
    let riskiness = calculateRiskiness(investStrategy);

    let updatedStocks = [];
    let newStockBalance = new Decimal(0);
    for (let i = 0; i<stocks.length; i++) {

        let currentStock = cloneObject(stocks[i]);
        currentStock.age = currentStock.age + 1;

        if (Math.random() < .6) {
            let gain = true;
            let stockGainThreshold = calculateTradingWinRate(tradingEngineLevel, false);
            if (Math.random() > stockGainThreshold) {
                gain = false;
            }

            let currentPrice = new Decimal(currentStock.price);
            let priceMul = currentPrice.mul(new Decimal(Math.random()));
            let priceDiv = riskiness.mul(new Decimal(4));
            let delta = priceMul.div(priceDiv).ceil();

            // Multiply delta according to time spent offline
            if( secondsAway > 0 ) {
                let deltaMultiplier = 1 + (secondsAway / 100);

                if( secondsAway < 110 ) {
                    deltaMultiplier = 1.1;
                }
                delta = delta.mul(new Decimal(deltaMultiplier));
            }

            if (gain) {
                currentStock.price = currentPrice.plus(delta).toString();
            } else {
                currentStock.price = currentPrice.sub(delta).toString();
            }

            if (currentPrice.equals(0) && Math.random() > .24) {
                currentStock.price = '1';
            }

            currentStock.total = new Decimal(currentStock.price).mul(currentStock.amount).toString();

            let profit = new Decimal(currentStock.profit);
            let deltaProfitLoss = delta.mul(new Decimal(currentStock.amount));

            if (gain) {
                currentStock.profit = profit.plus(deltaProfitLoss).toString();
            } else {
                currentStock.profit = profit.sub(deltaProfitLoss).toString();
            }
        }

        newStockBalance = newStockBalance.plus(new Decimal(currentStock.total));
        updatedStocks.push(currentStock);
    }

    return {newStockBalance: newStockBalance, decimalHedgeFundCash: decimalHedgeFundCash, updatedStocks: updatedStocks }
}

/**
 * Calculate the riskiness of trades and stock updates based on the investment strategy chosen by the user
 * @param investStrategy
 * @returns {*}
 */
export function calculateRiskiness(investStrategy) {

    let riskiness;
    if (investStrategy === "low") {
        riskiness = 7;
    } else if (investStrategy === "med") {
        riskiness = 5;
    } else if (investStrategy === "high") {
        riskiness = 3;
    } else {
        riskiness = 1;
    }
    return new Decimal(riskiness);
}

export function prepareStockTableData(stocks) {


    let currentTableData = [];
    let newTableData = [];

    for (let i = 0; i < stocks.length; i++) { //m@ todo make this into an array ok?

        let symbol = stocks[i].symbol;

        // Prepare row
        let stockRow = {
            key: symbol,
            symbol: symbol,
            amount: numbroFormatInt(new Decimal(stocks[i].amount).ceil()),
            price: numbroFormatCurrency(new Decimal(stocks[i].price).ceil()),
            total: numbroFormatCurrency(new Decimal(stocks[i].total).ceil()),
            profit: numbroFormatCurrency(new Decimal(stocks[i].profit).ceil()),
        }

        // Replace row if it exists, if not add it
        let symbolExists = currentTableData.find(o => o.symbol === symbol);
        if (typeof (symbolExists) !== 'undefined') {
            newTableData = currentTableData.map(obj => [stockRow].find(o => o.symbol === obj.symbol) || obj);
        } else {
            currentTableData.push(stockRow);
            newTableData = currentTableData;
        }
    }
    return newTableData;
}

/**
 * Calculate the win rate when trading stocks.
 * Affected by trading engine level.
 * @param tradingEngineLevel
 * @param asPercentage
 * @returns {number}
 */
export function calculateTradingWinRate(tradingEngineLevel, asPercentage) {
    let value = 0.5 + tradingEngineLevel / 100;
    if( asPercentage ) {
        value = numbroFormatInt(value * 100);
    }
    return value;
}

/**
 * Calculate the price for a trading engine upgrade
 * @param tradingEngineLevel
 * @returns {number}
 */
export function calculateTradingEngineUpgradePrice(tradingEngineLevel) {
    // Calculate new upgrade price
    const tradingEngineLevelDecimal = new Decimal(tradingEngineLevel);
    return new Decimal(50000).plus(tradingEngineLevelDecimal.pow(tradingEngineLevelDecimal).multiply(new Decimal(1000)).floor())
}