import React from 'react';
import {Text, View, Image, Pressable, ImageBackground} from 'react-native';
import {AppStyles} from "../AppStyle";
import {useDispatch, useSelector} from "react-redux";
import * as stateSetters from '../redux/action';
// lodash
import { memoize } from 'lodash';

import {
    numbroFormatInt,
    numbroFormatCurrency,
    calculateSolarPanelProduction,
    calculateEnergyUsage,
    calculateEnergyUsageRatio,
    calculateNewQuantumComputerPrice,
    isProjectCompleted, calculateOPSProduction
} from "./helperFunctions";
import PurchaseItem from "./PurchaseItem";
import { playSound } from '../constants/AppSounds';
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import ProgressBar from 'react-native-progress/Bar';

const QuantumComputerController = () => {


    const dispatch= useDispatch();


    // Memoize

    const getOpsProgress = memoize(state => state.opsProgress);
    const getQuantumComputerPrice = memoize(state => state.quantumComputerPrice);
    const getOpsWaitSeconds = memoize(state => state.opsWaitSeconds);

    const getQuantumComputersActive = memoize(state => state.quantumComputersActive);

    const getSoundEnabled = memoize(state => state.soundEnabled);

    const getCompletedProjects = memoize(state => state.completedProjects);

    // Energy generation state
    const getSolarpanels = memoize(state => state.solarpanels);
    const getRobots = memoize(state => state.robots);
    const getRobotLevel = memoize(state => state.robotLevel);
    const getRobotsPartsFactoryActive = memoize(state => state.robotsPartsFactoryActive);
    const getSolarpanelsPartsFactoryActive = memoize(state => state.solarpanelsPartsFactoryActive);
    const getSpaceElevatorActive = memoize(state => state.spaceElevatorActive);
    const getSolarBoost = memoize(state => state.solarBoost);
    const getSolarpanelEfficiency = memoize(state => state.solarpanelEfficiency);

    // Usage

    const opsProgress = useSelector(getOpsProgress);
    const quantumComputerPrice = useSelector(getQuantumComputerPrice);
    const opsWaitSeconds = useSelector(getOpsWaitSeconds);

    const quantumComputersActive = useSelector(getQuantumComputersActive);

    const soundEnabled = useSelector(getSoundEnabled);

    const completedProjects = useSelector(getCompletedProjects);

    // Energy generation state
    const solarpanels = useSelector(getSolarpanels);
    const robots = useSelector(getRobots);
    const robotLevel = useSelector(getRobotLevel);
    const robotsPartsFactoryActive = useSelector(getRobotsPartsFactoryActive);
    const solarpanelsPartsFactoryActive = useSelector(getSolarpanelsPartsFactoryActive);
    const spaceElevatorActive = useSelector(getSpaceElevatorActive);
    const solarBoost = useSelector(getSolarBoost);
    const solarpanelEfficiency = useSelector(getSolarpanelEfficiency);


    const opsProductionEnabled = isProjectCompleted('projectButton8', completedProjects) || quantumComputersActive > 0;


    const solarEnergyGeneration = calculateSolarPanelProduction(solarpanels, solarpanelEfficiency, solarBoost);
    const energyUsage = calculateEnergyUsage(robots, robotLevel, quantumComputersActive, robotsPartsFactoryActive, solarpanelsPartsFactoryActive, spaceElevatorActive, solarBoost);
    const energyUsageValues = calculateEnergyUsageRatio(solarEnergyGeneration, energyUsage);
    const decreasePercentString = energyUsageValues.decreasePercent.toFixed(2);


    const currentProduction = calculateOPSProduction(quantumComputersActive) ;
    const newOPSProduction = calculateOPSProduction(quantumComputersActive+1) ;
    const productionIncrease = ((newOPSProduction - currentProduction) / opsWaitSeconds).toFixed(2);

    let currentProductionPerSec = currentProduction / opsWaitSeconds;
    if( energyUsageValues.decreasePercentDecimal > 0 ) {
        currentProductionPerSec = ((currentProduction * energyUsageValues.decreasePercentDecimal) / opsWaitSeconds);
    }
    const currentProductionPerSecString = currentProductionPerSec.toFixed(2);

    const energyNextComputer = calculateEnergyUsage(robots, robotLevel, quantumComputersActive + 1, robotsPartsFactoryActive, solarpanelsPartsFactoryActive, spaceElevatorActive, solarBoost)
    const energyDiffNextComputer = (energyNextComputer - energyUsage).toFixed(2);

    async function executeBuyQuantumComputer() {
        const newQuantumComputers = quantumComputersActive + 1;
        dispatch(stateSetters.setQuantumComputersActive(newQuantumComputers));
        let newPrice = calculateNewQuantumComputerPrice(quantumComputerPrice, newQuantumComputers);
        dispatch(stateSetters.setQuantumComputerPrice(newPrice));
        await playSound('purchased_robot', soundEnabled);
        const opsProduction = (calculateOPSProduction(newQuantumComputers)  / opsWaitSeconds).toFixed(2);
        dispatch(stateSetters.setModalContent({
            title: 'Purchase',
            text: 'Quantum computer purchase complete! OPS generation increased to ' + opsProduction + ' per second!',
            image: require('../assets/projects/quantum_computer.png')
        }
    ));
        dispatch(stateSetters.setModalVisible(true));
    }

    let purchaseMessage = 'Cost: '+numbroFormatCurrency(quantumComputerPrice)+'\nEnergy Usage: +'+energyDiffNextComputer+' MW\nEffect: +'+productionIncrease+' OPS per second';

    function handleTooltip() {
        dispatch(stateSetters.setModalContent(
            {
                title: 'Quantum Computers',
                text: 'Quantum computers generate the necessary OPS for completing projects.'

            }
        ));
        dispatch(stateSetters.setModalVisible(true));
    }

    const buttonText = energyNextComputer > solarEnergyGeneration ? 'Not enough energy' : 'Buy Quantum Computer\n('+numbroFormatCurrency(quantumComputerPrice)+')';


    return (
        <View style={AppStyles.quantumComputerControl}>
            <View>
                <Pressable onPress={() => {handleTooltip() }} >
                    <Text style={[AppStyles.title, AppStyles.white]}>Quantum Computers <MaterialCommunityIcons name="frequently-asked-questions" color={'#ffffff'} size={16}/></Text>
                </Pressable>
                { quantumComputersActive > 0 &&
                    <ProgressBar
                        progress={opsProgress}
                        showsText={true}
                        width={null}
                        borderColor={'#1bffff'}
                        style={AppStyles.progress}
                        color={'#1bffff'}
                        unfilledcolor={'#1bffff'}
                    />}
            </View>


            <View style={[AppStyles.robotPurchaseWrapper, AppStyles.row]}>
                <View style={[AppStyles.achievementBadgeImageWrap, AppStyles.turqoiseBorder]}>
                <Image source={require('../assets/projects/quantum_computer.png')}
                       style={[AppStyles.quantumComputerImage, !opsProductionEnabled &&  AppStyles.transparentImage]}
                />
                </View>
                <View style={[AppStyles.balances]}>
                    <ImageBackground source={require('../assets/interface/screen.png')} style={[AppStyles.robotStatsBGImage, AppStyles.paddingLeft]}>
                    <Text style={[ AppStyles.paddingLeft, AppStyles.mediumMarginLeft, AppStyles.white]}>{currentProductionPerSecString} OPS/sec.{
                        energyUsageValues.decreasePercent > 0 &&
                        <Text style={[AppStyles.red, AppStyles.smalltext]}>(<MaterialCommunityIcons name="flash" color={'red'} size={12}/>{decreasePercentString}%)</Text>
                    }</Text>
                    <Text style={[ AppStyles.paddingLeft, AppStyles.mediumMarginLeft, AppStyles.white]}>Computers: {quantumComputersActive}</Text>
                    </ImageBackground>
                </View>
            </View>
            <View style={[AppStyles.row]}>
                { !opsProductionEnabled ?
                    <View>
                        <Text style={AppStyles.red}>Complete the project 'Quantum Computer Factory' to build quantum computers.</Text>
                    </View>
                    :
                    <PurchaseItem
                        doPurchase={executeBuyQuantumComputer}
                        buttonText={buttonText}
                        buttonEnabled={opsProductionEnabled && energyNextComputer < solarEnergyGeneration}
                        buyTitle={'Buy Quantum Computer'}
                        buyMessage={purchaseMessage}
                        stardustPurchase={true}
                        euroDollarPrice={quantumComputerPrice}
                    />
                }
            </View>
        </View>
    );
};

export default QuantumComputerController;