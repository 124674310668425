import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import {MainStackNavigator} from "./StackNavigator";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import AutomationController from "../screens/AutomationController";
import ProjectList from "../screens/ProjectList";
import HedgeFundTrading from "../screens/HedgeFundTrading";
import Settings from "../screens/Settings";
import {useSelector} from "react-redux";
import Decimal from "break_infinity.js";
import RockSmasherGame from "../components/RockSmasherGame";
import {timeBetweenMinigames} from "../constants/magicNumbers";

const Tab = createBottomTabNavigator();

const BottomTabNavigator = () => {

    const money = useSelector( state => state.money);
    const decimalMoney = new Decimal(money);
    const robotPrice = useSelector( state => state.robotPrice);
    const robotUpgradePrice = useSelector( state => state.robotUpgradePrice);
    const solarpanelPrice = useSelector( state => state.solarpanelPrice);
    const solarpanelUpgradePrice = useSelector( state => state.solarpanelUpgradePrice);
    const quantumComputerPrice = useSelector( state => state.quantumComputerPrice);

    const robot = robotPrice <= decimalMoney || robotUpgradePrice <= decimalMoney;
    const solarPanel = solarpanelPrice <= money || solarpanelUpgradePrice <= money;

    const showAutomationTabBadge = robot || solarPanel || money >= quantumComputerPrice;

    const hasNewProject = useSelector( state => state.projectCount);
    const hedgeFundInvestment = useSelector( state => state.hedgeFundInvestment);

    const tradingEngineUpgradePrice = useSelector( state => state.tradingEngineUpgradePrice);
    const tradingUpgradeAvailable = hedgeFundInvestment && decimalMoney >= tradingEngineUpgradePrice;

    const lastMinigame = useSelector( state => state.lastMinigame);
    const minigameAvailable = Date.now() - lastMinigame >= timeBetweenMinigames;

    return (
        <Tab.Navigator
            labeled={true}
            screenOptions={{
                tabBarActiveTintColor: '#fe470e',
                tabBarStyle: {
                    backgroundColor:'rgb(0,16,33)',
                },
                tabBarLabelStyle: {
                    fontFamily: 'Share-Tech-Mono'
                },
                tabBarBadgeStyle: { backgroundColor: '#fe470e', height: 12, minWidth: 12, borderRadius: 6},
                headerStyle: {
                    backgroundColor: '#f4511e',
                },
                headerTintColor: '#fff',
                headerShown: false,
                }}
        >
            <Tab.Screen name="Space Miner" component={MainStackNavigator}
                        options={{
                            tabBarLabel: 'Space Miner',
                            tabBarIcon: ({color, size}) => (
                                <MaterialCommunityIcons name="pickaxe" color={color} size={size}/>
                            ),
                        }}
            />
            <Tab.Screen name="Automation" component={AutomationController}
                        options={{
                            tabBarLabel: 'Automation',
                            tabBarIcon: ({ color, size }) => (
                                <MaterialCommunityIcons name="robot" color={color} size={size} />
                            ),
                            tabBarBadge: showAutomationTabBadge ? true : undefined,
                        }}
            />
            <Tab.Screen name="Projects" component={ProjectList}
                        options={{
                            tabBarLabel: 'Projects',
                            tabBarIcon: ({ color, size }) => (
                                <MaterialCommunityIcons name="format-list-checkbox" color={color} size={size} />
                            ),
                            tabBarBadge: hasNewProject ? true : undefined,

                        }}
            />
            <Tab.Screen name="Minigame" component={RockSmasherGame}
                        options={{
                            tabBarLabel: 'Minigame',
                            tabBarIcon: ({ color, size }) => (
                                <MaterialCommunityIcons name="gamepad-variant-outline" color={color} size={size} />
                            ),
                            tabBarBadge: minigameAvailable ? true : undefined,
                        }}
            />
            <Tab.Screen name="Settings" component={Settings}
                        options={{
                            tabBarLabel: 'Settings',
                            tabBarIcon: ({ color, size }) => (
                                <MaterialCommunityIcons name="cog" color={color} size={size} />
                            ),
                        }}
            />
        </Tab.Navigator>
    );
};

export default BottomTabNavigator;