import * as React from 'react';
import {Modal, Pressable, Text, View} from 'react-native';
import {useDispatch, useSelector} from "react-redux";
import {AppStyles} from "../AppStyle";
import {configureAuth} from "../supabase";
import {resetState} from "../redux/action";

const ResetState = () => {
    const dispatch = useDispatch();

    const [visible, setVisible] = React.useState(false);
    const useRemoteStorage = useSelector((state) => state.useRemoteStorage);
    const showDialog = () => setVisible(true);

    const hideDialog = () => setVisible(false);

    async function applyResetEffects() {
        if( useRemoteStorage ) {
            const auth = configureAuth();
            await auth.logOut().then(
                dispatch(resetState(0))
            );
        } else {
            dispatch(resetState(0));
        }
        hideDialog();
    }

    return (
        <View>
            <Text style={[AppStyles.text, AppStyles.white]}>Reset the game: </Text>
            <Pressable
                onPress={() => { showDialog() }}
                style={[AppStyles.button, AppStyles.redButton]}
            >
                <Text pointerEvents="box-only" style={AppStyles.buttonText}>RESET GAME</Text>
            </Pressable>
            { visible &&
                <View style={AppStyles.centeredView}>
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={visible}
                        onRequestClose={() => {
                            dispatch(setVisible(!visible));
                        }}>
                        <View style={AppStyles.centeredView}>
                            <View style={AppStyles.buyItemView}>

                                <Text style={[AppStyles.title, AppStyles.white]}>Reset Game</Text>
                                <Text
                                    style={[AppStyles.text, AppStyles.textLeft, AppStyles.smalltext, AppStyles.white]}>Are you sure you want to reset the game? All progress will be lost.</Text>
                                <View style={AppStyles.row}>
                                    <Pressable
                                        style={[AppStyles.button, AppStyles.blueButton]}
                                        onPress={() => hideDialog()}>
                                        <Text style={AppStyles.buttonText}>Cancel</Text>
                                    </Pressable>
                                    <Pressable
                                        style={[AppStyles.button, AppStyles.redButton]}
                                        onPress={() => applyResetEffects()}>
                                        <Text style={AppStyles.buttonText}>RESET</Text>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </Modal>
                </View>
            }
        </View>
    );
};


export default ResetState;