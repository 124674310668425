import numbro from "numbro";
import * as Notifications from "expo-notifications";
import * as Crypto from 'expo-crypto';
import {store} from "../redux/store";
import {supabaseClient} from "../supabase";
import Decimal from "break_infinity.js";
// Magic numbers
import * as magicNumbers from '../constants/magicNumbers';

numbro.zeroFormat('0');

export function numbroFormatInt(number, max = 10000000) {

    if( number === null ) {
        number = 0;
    }


    if(number.__proto__.constructor.name === 't') {

        if (number.greaterThanOrEqualTo(new Decimal(1000000000000000))) {
            let string = number.toStringWithDecimalPlaces();
            if (string.length > 25) {
                string = number.toExponential(6)
            }
            return string;

        } else {
            number = number.toNumber();
        }
    }

    return numbro(number).format({
        thousandSeparated: true,
        mantissa: 0,
        average: number > max
    });
}

export function numbroFormatCurrency(number, max = 1000000000) {

    if (number === null) {
        number = 0;
    }


    if (number.__proto__.constructor.name === 't') {
        if (number.greaterThanOrEqualTo(new Decimal(1000000000000000))) {
            let string = number.toStringWithDecimalPlaces();
            if (string.length > 25) {
                string = number.toExponential(6)
            }
            return string;

        } else {
            number = number.toNumber();
        }
    }

    return numbro(number).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
        average: number > max,
        spaceSeparated: true,
        currencySymbol: "€$"
    });

}

/**
 * Safely clone object into new data structure
 * @param object
 * @returns {any}
 */
export function cloneObject(object) {
    return JSON.parse(JSON.stringify(object));
}


export function onDisplayNotification(title, message) {
    Notifications.scheduleNotificationAsync({
        content: {
            title: title,
            body: message,
        },
        trigger: null,
    });
}

async function calculateProofOfWork(pow) {

    // Get the difficulty from the remote server
    const difficulty = await getDifficulty();

    while(pow.proofOfWork.substring(0, difficulty) !== "0".repeat(difficulty)) {
        pow.nonce++;
        pow.proofOfWork = await Crypto.digestStringAsync(
            Crypto.CryptoDigestAlgorithm.SHA512,
            pow.stateString + pow.nonce
        );
        //console.log("Calculating: " + pow.proofOfWork);
        //console.log("Nonce: " + pow.nonce);
    }

    //console.log("Proof of Work: " + pow.proofOfWork);
    return pow;
}


const getDifficulty = async () => {
    let difficulty = 2;
    try {
        const response = await fetch('https://reactnative.dev/movies.json'); // TODO Difficulty API
        const json = await response.json();

        let dummy = json.movies[0].releaseYear;

        difficulty = Math.ceil(dummy / 1000);
        console.log('getDifficulty', difficulty);
    } catch (error) {
        console.error(error);
    }
    return difficulty;
}

const getStardustEuroDollarExchangeRateMultiplier = async () => {
    let exchangeRate = 1;
    try {
        /*
        const response = await fetch('https://reactnative.dev/movies.json'); // TODO StardustEuroDollarExchangeRate API
        const json = await response.json();
        let dummy = json.movies[0].releaseYear;
         */

        exchangeRate = 1;
        console.log('getStardustEuroDollarExchangeRateMultiplier', exchangeRate);
        return exchangeRate;
    } catch (error) {
        console.error(error);
    }
    return exchangeRate;
}

export function calculateStardustPrice(euroDollarPrice) {

    // Amount below 100k eurodollar
    let stardustAmount = 1500;

    if( euroDollarPrice >= 999999 ) {

        // The count of figures of the eurodollar amount determines the number of rounds
        const eurodollarPriceString = Math.ceil(euroDollarPrice).toString();
        const numLen = eurodollarPriceString.length - 1;

        let multiplier = 1.15;

        for(let i = 0; i <= numLen; i++) {
            stardustAmount = Math.ceil(stardustAmount * multiplier);
        }
    }

    // Apply Stardust/EuroDollar Exchange Rate Multiplier
    //const rateMultiplier = getStardustEuroDollarExchangeRateMultiplier(); // TODO Enable exchange rate multiplier
    //stardustAmount = stardustAmount * rateMultiplier;
    //console.log(stardustAmount);

    return Math.ceil(stardustAmount);
}

export const generateInstanceId = () => {
    return Crypto.randomUUID();
};


export async function hashAndUpload(instanceId, userId, previousBlock) {

        // Prepare state
        const state = store.getState();
        const stateString = JSON.stringify(state);

        // Calculate proof of work
        let pow = {nonce: 0, stateString: stateString, proofOfWork: '1'};
        pow = await calculateProofOfWork(pow);

        //console.log('POW', pow.proofOfWork, pow.nonce);

        let time = Date.now();
        const payload = {blockhash: pow.proofOfWork, timestamp: time, nonce: pow.nonce};

        // Upload to supabase
        const supabase = supabaseClient();

        let error;
        error = await supabase
            .from('states')
        .insert({instance_id: instanceId, pow: payload, state: state, user_id: userId, prev_block: previousBlock, block_hash: pow.proofOfWork})
        .then(() => {
            console.log('State uploaded to Supabase', payload);
            // Store hash, none and timestamp in state
            store.dispatch({type: 'UPLOAD_BLOCKHASH', payload: payload});
        });

        if( error ) {
           console.log('supabase insert', error);
        }

}

/**
 * Calculate the status of the unobtanium storage
 * @param decimalUnobtanium
 * @param storageCapacity
 * @returns {number} between 0 and 1
 */
export function calculateCapacityStorageStatus(decimalUnobtanium, storageCapacity) {
    let cap = 0;
    if( decimalUnobtanium > 0) {
        cap = decimalUnobtanium.div(storageCapacity);
        if (cap > 1) {
            cap = 1;
        }
        if (isNaN(cap)) {
            cap = 0;
        }
    }
    return parseFloat(cap.toString());
}

function extremelySlowGrowth(value) {
    return value + Math.log(value);
}

function verySlowGrowth(value) {
    return value * 1.1;
}

function slowGrowth(value) {
    return value * 1.75;
}

function mediumGrowth(value) {
    return value * 2;
}

function fastGrowth(value) {
    return value * 3;
}


/**
 * Calculate the upgrade price for the next level of storage
 * @param storageUpgradePrice
 * @returns {*}
 */
export function calculateStorageUpgradePrice(storageUpgradePrice) {
    //return storageUpgradePrice + (storageUpgradePrice * 1.5);
    return mediumGrowth(storageUpgradePrice);
}

/**
 * Calculate the capacity of the next level of storage
 * @param storageCapacity
 * @returns {*}
 */
export function calculateStorageCapacity(storageCapacity) {
    //return storageCapacity + (storageCapacity * 0.5);
    return fastGrowth(storageCapacity);
}

/**
 * Calculate the amount of rare unobtanium that has been found
 * @param newUnobtanium
 * @param rareUnobtaniumChance
 * @returns {number}
 */
export function calculateRareUnobtaniumAmount(newUnobtanium, rareUnobtaniumChance) {
    // Math.random() returns a number between 0 and 1.
    // Add the chance variable and check if the result is equal to or greater than 1
    let rareUnobtaniumFound = (Math.random() + rareUnobtaniumChance) >= 1;
    let amount = 0;
    if( rareUnobtaniumFound ) {
        let min = newUnobtanium * 0.1; // Minimum 10% of the current amount
        let max = newUnobtanium * 0.5; // Maximum 50% the current amount
        amount = min + Math.floor(Math.random() * (max - min + 1));
    }
    return amount;
}

interface UnobtaniumPriceAndCost {
    epoch: null;
    robots: null;
    robotLevel: null;
    boostEpochs: null;
    trustThreshold: null;
    unobtaniumPriceBoost: null;
    salesTax: null;
    unobtaniumPrice: null;
}

/**
 * Determine the price and cost of mining and selling Unobtanium
 * @param epoch
 * @param robots
 * @param robotLevel
 * @param boostEpochs
 * @param trustThreshold
 * @param unobtaniumPriceBoost
 * @param salesTax
 * @param unobtaniumPrice
 * @returns {{salesTax: *, price: number}}
 */
export function calculateUnobtaniumPriceAndTax({epoch, robots, robotLevel, boostEpochs, trustThreshold, unobtaniumPriceBoost, salesTax, unobtaniumPrice}: UnobtaniumPriceAndCost) {
    // Calculate production price
    let newPrice = Math.ceil(extremelySlowGrowth(unobtaniumPrice));

    // Increase sales tax
    let newSalesTax = calculateSalesTax(epoch);

    // Cap sales tax
    const salesTaxMax = 85;
    if( newSalesTax >= salesTaxMax ) {
        newSalesTax = salesTaxMax;
    }

    // Apply unobtaniumPriceBoost
    if( boostEpochs > 0 || trustThreshold > 1000 ) {
        newPrice *= unobtaniumPriceBoost;
    }
    return {price: newPrice, salesTax: newSalesTax}
}

/**
 * Increase the sales tax by the magic number percentage for each epoch
 * @param epoch
 * @returns {number}
 */
function calculateSalesTax(epoch) {
    return Math.pow(1 + magicNumbers.increasePercentage / 100, epoch);
}

/**
 * Calculate profit made by selling Unobtanium at current price
 * @param decimalUnobtanium
 * @param unobtaniumPrice
 * @param salesTax
 * @param transCostMult
 * @returns {number}
 */
export function calculateUnobtaniumProfit(decimalUnobtanium, unobtaniumPrice, salesTax, transCostMult) {
    const revenue = decimalUnobtanium.mul(new Decimal(unobtaniumPrice));
    const cost = decimalUnobtanium.mul(new Decimal(salesTax)).mul(new Decimal(transCostMult));
    return revenue.sub(cost);
}

/**
 * Calculate the current mining output of all robots
 * @param robots
 * @param robotMiningPerInterval
 * @param robotEfficiency
 * @param droneEfficiency
 * @param unobtaniumAmountBoost
 * @param productionBoost
 * @returns {number}
 */
export function calculateRobotProduction(robots, robotMiningPerInterval, robotEfficiency, droneEfficiency, unobtaniumAmountBoost, productionBoost) {
    let production = robots * robotMiningPerInterval * robotEfficiency * droneEfficiency * unobtaniumAmountBoost * productionBoost;
    if( production <= 0) {
        production = 0;
    }
    return production;
}

/**
 * Calculate the price of the next robot based on the current number of robots
 * @param robotPrice
 * @param robots
 * @param robotsPartsFactoryActive
 * @returns {number}
 */
export function calculateNewRobotPrice(robotPrice, robots, robotsPartsFactoryActive) {
    //let newPrice = robotPrice * 1.75;
    let newPrice = slowGrowth(robotPrice);
    if (robots > 4) {
        //newPrice = robotPrice + (robotPrice * Math.ceil(robots / 2 / 10));
        newPrice = fastGrowth(robotPrice);
    }
    if(robotsPartsFactoryActive) {
        newPrice *= 0.7;
    }

    return newPrice;
}

/**
 * Calculate the price of the next quantum computer based on the current number
 * @param quantumComputerPrice
 * @param quantumComputers
 * @returns {number}
 */
export function calculateNewQuantumComputerPrice(quantumComputerPrice, quantumComputers) {
    //let newPrice = quantumComputerPrice * 1.75;
    let newPrice = slowGrowth(quantumComputerPrice);
    if (quantumComputers > 4) {
        //newPrice = quantumComputerPrice + (quantumComputerPrice * Math.ceil(quantumComputers / 2 / 10));
        newPrice = fastGrowth(quantumComputerPrice);
    }
    return newPrice;
}

/**
 * Calculate the price of a robot upgrade based on the current level and the number of robots
 * @param robotUpgradePrice
 * @param robots
 * @returns {number}
 */
export function calculateRobotUpgradePrice(robotUpgradePrice, robots) {
    //let upgradePrice = robotUpgradePrice * 1.5;
    return fastGrowth(robotUpgradePrice);
}

/**
 * Calculate the robot efficiency at a certain upgrade level
 * @param robotLevel
 * @returns {number}
 */
export function calculateNewRobotEfficiency(robotLevel) {
    return 1 + (robotLevel * 0.5);
}

/**
 * Calculate the energy output of all solarpanels
 * @param solarpanels
 * @param solarpanelEfficiency
 * @param solarBoost
 * @returns {number}
 */
export function calculateSolarPanelProduction(solarpanels, solarpanelEfficiency, solarBoost) {
    const production = 0.5 * solarpanels * solarpanelEfficiency * solarBoost;
    return production;
}


/**
 * Calculate the energy usage
 * @param robots
 * @param robotLevel
 * @param quantumComputersActive
 * @param robotsPartsFactoryActive
 * @param solarpanelsPartsFactoryActive
 * @param spaceElevatorActive
 * @param solarBoost
 * @returns {number}
 */
export function calculateEnergyUsage(robots, robotLevel, quantumComputersActive, robotsPartsFactoryActive, solarpanelsPartsFactoryActive, spaceElevatorActive, solarBoost) {
    let energyUsage = (robots * robotLevel * magicNumbers.robotEnergyUsage)
        + (quantumComputersActive * magicNumbers.quantumComputerEnergyUsage)
        + robotsPartsFactoryActive * magicNumbers.robotsPartsFactoryEnergyUsage
        + solarpanelsPartsFactoryActive * magicNumbers.solarpanelsPartsFactoryEnergyUsage
        + spaceElevatorActive * magicNumbers.spaceElevatorEnergyUsage

    energyUsage = energyUsage * (1 - (solarBoost - 1));
    return energyUsage;
}

/**
 * Calculate the price of the next solarpanel based on the current number of solarpanels
 * @param solarpanelPrice
 * @param solarpanels
 * @param solarpanelsPartsFactoryActive
 * @returns {number}
 */
export function calculateNewSolarPanelPrice(solarpanelPrice, solarpanels, solarpanelsPartsFactoryActive) {
    //let newPrice = solarpanelPrice * 1.75;
    let newPrice = slowGrowth(solarpanelPrice);
    if (solarpanels > 4) {
        //newPrice = solarpanelPrice + (solarpanelPrice * Math.ceil(solarpanels / 2 / 10));
        newPrice = fastGrowth(solarpanelPrice);
    }
    if(solarpanelsPartsFactoryActive) {
        newPrice *= 0.7;
    }

    return newPrice;
}

/**
 * Calculate the price of a solarpanel upgrade based on the current level and the number of solarpanels
 * @param solarpanelUpgradePrice
 * @param solarpanels
 * @returns {number}
 */
export function calculateSolarPanelUpgradePrice(solarpanelUpgradePrice, solarpanels) {
    //let upgradePrice = solarpanelUpgradePrice * 1.5;
    return fastGrowth(solarpanelUpgradePrice);
}

/**
 * Calculate the solarpanel efficiency at a certain upgrade level
 * @param solarpanelLevel
 * @returns {number}
 */
export function calculateNewSolarPanelEfficiency(solarpanelLevel) {
    const efficiency = extremelySlowGrowth(solarpanelLevel);
    return efficiency;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Check if a project has been completed
 * @param projectID
 * @param completedProjects
 * @returns {boolean}
 */
export function isProjectCompleted(projectID, completedProjects) {
    let findProject = false;
    if (completedProjects.completed.length) {
        findProject = completedProjects.completed.find(element => element === projectID);
    }
    return !!findProject;
}

/**
 * Calculate profit from selling Unobtanium
 * @param salesAmount
 * @param salesPrice
 * @param salesTax
 * @param transCostMult
 * @returns {number}
 */
export function calculateSalesProfit(salesAmount, salesPrice, salesTax, transCostMult) {
    const revenue = new Decimal(salesAmount).mul(new Decimal(salesPrice)).mul(new Decimal(transCostMult));
    const tax =  revenue.mul(new Decimal(salesTax)).div(new Decimal(100));
    return revenue.sub(tax);
}

/**
 * Calculate the energy usage ratio and the production decrease percentage
 * @param solarEnergyGeneration
 * @param energyUsage
 * @returns {{decreasePercentDecimal: number, decreasePercent: number, energyUtilization: number}}
 */
export function calculateEnergyUsageRatio(solarEnergyGeneration, energyUsage) {
    let energyUtilization = 0;
    let decreasePercent = 0;
    let decreasePercentDecimal = 0.0;
    if( energyUsage > 0 ) {
        energyUtilization = energyUsage / solarEnergyGeneration;
        decreasePercent = energyUtilization > 1 ? ((energyUtilization - 1) * 100) : 0;
        if( decreasePercent >= 90 ) {
            decreasePercent *= 0.90;
        }
        decreasePercentDecimal = decreasePercent / 100;
    }


    return {energyUtilization: energyUtilization, decreasePercent: decreasePercent, decreasePercentDecimal: decreasePercentDecimal}
}

/**
 * Calculate OPS production per interval
 * (Divide the result by opsWaitSeconds to get the amount produced per second)
 * @type {number}
 */
export function calculateOPSProduction(quantumComputersActive) {
    return magicNumbers.opsProductionPerInterval * quantumComputersActive;
}

/**
 * Format milliseconds to a string split into hours:minutes:seconds
 * @param duration
 * @returns {string}
 */
export function msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
}