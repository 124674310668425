import React, { useEffect } from 'react';
import {View, Text, Pressable, StyleSheet, ImageBackground} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { memoize } from 'lodash';

import {
    resetRocks,
    setRocks,
    setLives,
    setScore,
    setMoney,
    setLastMinigame,
    setGameRunning,
    setRewardAvailable
} from '../redux/action';
import {AppStyles} from "../AppStyle";
import {msToTime, numbroFormatCurrency} from "./helperFunctions";
import Decimal from "break_infinity.js";
import * as magicNumbers from "../constants/magicNumbers";
import {timeBetweenMinigames} from "../constants/magicNumbers";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {ZoomInView} from "./ZoomInView";
import * as stateSetters from "../redux/action";
import {playSound} from "../constants/AppSounds";
import EventNotification from "./EventNotification";

let lastTick = Date.now(); // TODO refactor
const RockSmasherGame = () => {
    const dispatch = useDispatch();
    // Memoize

    const getRocks = memoize(state => state.rocks);
    const getSoundEnabled = memoize(state => state.soundEnabled);
    const getLives = memoize(state => state.lives);
    const getIsGameEnded = memoize(state => state.isGameEnded);
    const getScore = memoize(state => state.score);
    const getCountdown = memoize(state => state.countdown);
    const getMoney = memoize(state => state.money);
    const getEpoch = memoize(state => state.epoch);
    const getGameRunning = memoize(state => state.gameRunning);
    const getRewardAvailable = memoize(state => state.rewardAvailable);

    // Usage

    const rocks = useSelector(getRocks);
    const soundEnabled = useSelector(getSoundEnabled);
    const lives = useSelector(getLives);
    const isGameEnded = useSelector(getIsGameEnded);
    const score = useSelector(getScore);
    const countdown = useSelector(getCountdown);
    const money = useSelector(getMoney);
    const epoch = useSelector(getEpoch);
    const gameRunning = useSelector(getGameRunning);
    const rewardAvailable = useSelector(getRewardAvailable);

    const rewardAvailableDecimal = new Decimal(rewardAvailable);

    const timePassed = useSelector(state => state.timePassed);

    useEffect(() => {
        let timer = null;

        if (!isGameEnded && countdown > 0 && lives > 0) {
            timer = setInterval(() => {

                const now = Date.now();
                if( now - lastTick >= 1000 ) {
                    dispatch({type: 'TICK_COUNTDOWN'});
                    lastTick = now;
                }
            }, 150);
        } else if (isGameEnded || countdown <= 0 || lives <= 0) {
            dispatch(setRewardAvailable(rewardAvailableDecimal.plus(new Decimal(calculateReward(score)))));
            dispatch({ type: 'END_ROCK_SMASHER_GAME' });
        }

        return () => {
            clearInterval(timer);
        };
    }, [dispatch, countdown, lives, isGameEnded]);

    async function handleRockPress(rockIndex){
        const rock = rocks[rockIndex];
        if (rock.state === 'intact' && !isGameEnded && lives > 0) {
            const rock = rocks[rockIndex];
            if (rock.state === 'intact') {
                const hasHazard = rock.hasHazard;

                let updatedGrid = rocks;
                if (hasHazard) {
                    if (lives > 0) {
                        updatedGrid = rocks.map((r, index) =>
                            index === rockIndex ? { ...r, state: 'smashed' } : r
                        );

                        dispatch(setRocks(updatedGrid));
                        dispatch(setLives(lives - 1));
                    } else {
                        dispatch(setRewardAvailable(rewardAvailableDecimal.plus(new Decimal(calculateReward(score)))));
                        dispatch({ type: 'END_ROCK_SMASHER_GAME' });
                    }
                    await playSound('gas_release', soundEnabled);
                } else {
                    updatedGrid = rocks.map((r, index) =>
                        index === rockIndex ? { ...r, state: 'smashed' } : r
                    );

                    dispatch(setScore(score + 1));
                    await playSound('correct', soundEnabled);
                }
                dispatch(setRocks(updatedGrid));
            }
        }
    }


    /**
     * generateInitialGrid initializes an empty grid array.
     * It then loops through the rows and columns of the grid and generates a rock tile object for each cell.
     * The initial state of the rock tile is set to 'intact' and hasHazard is set to false.
     * Finally, the rock tile is added to the grid array.
     * @returns {[]}
     */
    const generateInitialGrid = () => {
        // Generate and return the initial grid of rocks
        const grid = [];

        for (let i = 0; i < magicNumbers.gridSize; i++) {
            const rock = {
                state: 'intact',
                hasHazard: Math.random() < magicNumbers.hazardChance,
            };
            grid.push(rock);
        }
        return grid;
    };

    const handleResetRocks = () => {
        dispatch(setRocks(generateInitialGrid()));
        dispatch(resetRocks());
        dispatch(setGameRunning(true));
    };

    function calculateReward(score, format) {

        let baseReward = 1000;
        if( epoch >= 30 ) {
            baseReward = 10000
        } else if( epoch > 60 ) {
            baseReward = 100000
        }

        const reward =  score * baseReward * epoch / 100;
        return format ? numbroFormatCurrency(reward) : reward;
    }

    function handleCollectReward() {
        const moneyDecimal = new Decimal(money);
        dispatch(setMoney(moneyDecimal.plus(rewardAvailableDecimal)));
        dispatch(setLastMinigame(Date.now()));
        dispatch(setRewardAvailable(0));
    }

    const blueButtonStyle = [AppStyles.button, AppStyles.blueButton];
    const pressedBlueButtonStyle = [AppStyles.button, AppStyles.pressedBlueButton, AppStyles.moveButton];
    const redButtonStyle = [AppStyles.button, AppStyles.redButton];
    const pressedRedButtonStyle = [AppStyles.button, AppStyles.pressedRedButton, AppStyles.moveButton];
    const greenButtonStyle = [AppStyles.button, AppStyles.greenButton];
    const pressedGreenButtonStyle = [AppStyles.button, AppStyles.pressedGreenButton, AppStyles.moveButton];

    const lastMinigame = useSelector( state => state.lastMinigame);
    const timeDiffMs = timeBetweenMinigames - (Date.now() - lastMinigame) ;
    const minigameAvailable = timeDiffMs <= 0;

    function handleTooltip() {
        dispatch(stateSetters.setModalContent(
            {
                title: 'Rock Smasher',
                text: 'Earn money by smashing as many rocks as possible. Caution: You lose a life if you smash a rock that releases poisonous gas. '

            }
        ));
        dispatch(stateSetters.setModalVisible(true));
    }

    const rewardAvailableTriggered = rewardAvailableDecimal.greaterThan(new Decimal(0));

    function getTileIcon(rock) {
        let tileIcon = 'octahedron';
        if(rock.state === 'smashed') {
          tileIcon = rock.hasHazard ? 'biohazard' : 'cash';
        }
        return tileIcon;
    }

    const notificationVisible = useSelector( state => state.notificationVisible);
    return (
        <ImageBackground source={require('../assets/bg_circuit.png')} style={AppStyles.backgroundImage}>
            {notificationVisible && <EventNotification/>}
        <View style={[styles.container, AppStyles.darkBackground]}>
            <Pressable onPress={() => {handleTooltip() }} >
                <Text style={[AppStyles.title, AppStyles.white]}>Rock Smasher <MaterialCommunityIcons name="frequently-asked-questions" color={'#ffffff'} size={16}/></Text>
            </Pressable>
            { (gameRunning || rewardAvailableTriggered )&&
                <View>
                    <View style={[AppStyles.row]}>
                    <Text style={[AppStyles.title, AppStyles.white,AppStyles.smallMarginLeft]}><MaterialCommunityIcons name="heart" color={'#ffffff'} size={18}/>{lives}</Text>
                    <Text style={[AppStyles.title, AppStyles.white,AppStyles.smallMarginLeft]}><MaterialCommunityIcons name="counter" color={'#ffffff'} size={18}/>{score}</Text>
                    <Text style={[AppStyles.title, AppStyles.white,AppStyles.smallMarginLeft]}><MaterialCommunityIcons name="timer-sand" color={'#ffffff'} size={18}/>{Math.max(countdown, 0)}</Text>
                    </View>
                    <View>
                        <View style={styles.gridContainer}>
                            {rocks.map((rock, index) => (
                                <Pressable
                                    key={index}
                                    style={[
                                        styles.rockTile,
                                        rock.state === 'smashed' && styles.rockTileSmashed,
                                        rock.state === 'smashed' && rock.hasHazard && styles.rockTileHazard,
                                    ]}
                                    onPress={() => handleRockPress(index)}
                                    disabled={rock.state === 'smashed' || isGameEnded}
                                >
                                    {
                                        rock.state === 'smashed' ?
                                        <ZoomInView>
                                            <MaterialCommunityIcons name={getTileIcon(rock)}
                                                                    color={rock.hasHazard ? 'limegreen' : '#000000'}
                                                                    size={48}/>
                                        </ZoomInView>
                                            :
                                            <MaterialCommunityIcons name={getTileIcon(rock)}
                                                                    color={'#000000'}
                                                                    size={48}/>
                                    }
                                </Pressable>
                            ))}
                        </View>
                    </View>
                </View>
            }

            { timeDiffMs >= 0 &&
                <Text style={[AppStyles.text, AppStyles.white]}>Next round in: {msToTime(timeDiffMs)}</Text>
            }
            { minigameAvailable && ! rewardAvailableTriggered && ! gameRunning &&
                <Pressable
                    style={({pressed}) => [
                        pressed ? pressedBlueButtonStyle : blueButtonStyle,
                    ]}
                    onPress={handleResetRocks}>
                    <Text style={[AppStyles.buttonText]}>Play</Text>
                </Pressable>
            }

            { isGameEnded && !gameRunning && rewardAvailableTriggered && (
                <View>
                    <Text style={[AppStyles.title, AppStyles.white]}>Game Over</Text>
                    <Text style={[AppStyles.text, AppStyles.white]}>You smashed {score} {score === 1 ? 'rock' : 'rocks' } and earn</Text>
                    <Text style={[AppStyles.text, AppStyles.white]}>{calculateReward(score, true)}</Text>
                    <View style={AppStyles.row}>
                    <Pressable
                               style={({pressed}) => [
                                   pressed ? pressedRedButtonStyle : redButtonStyle,
                               ]}
                               onPress={handleResetRocks}>
                        <Text style={[AppStyles.buttonText]}>Play again</Text>
                    </Pressable>
                    <Pressable
                        style={({pressed}) => [
                            pressed ? pressedGreenButtonStyle : greenButtonStyle,
                        ]}
                        onPress={handleCollectReward}>
                        <Text style={[AppStyles.buttonText]}>Collect reward</Text>
                    </Pressable>
                    </View>
                </View>
            )}
        </View>
        </ImageBackground>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    gridContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
        maxWidth: 400
    },
    rockTile: {
        width: 50,
        height: 50,
        margin: 5,
        backgroundColor: 'gray',
    },
    rockTileSmashed: {
        backgroundColor: 'lightgray',
    },
    rockTileHazard: {
        backgroundColor: 'red',
    },
});

export default RockSmasherGame;