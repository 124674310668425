import {
    SET_USER_ID,
    SET_USER_EMAIL,
    SET_USER_PHONE,
    SET_USE_REMOTE_STORAGE,
    SET_LAST_ACTIVE,
    SET_INSTANCE_ID,
    UPLOAD_BLOCKHASH,
    RESET_STATE,
    SET_TOTAL_UNOBTANIUM,
    SET_STORAGE_CAPACITY,
    SET_STORAGE_UPGRADE_PRICE,
    SET_STORAGE_CAPACITY_STATUS,
    SET_MONEY,
    SET_STARDUST,
    SET_UNOBTANIUM,
    SET_UNOBTANIUM_PRICE,
    SET_SALES_TAX,
    SET_ROBOTS,
    SET_ROBOT_PRICE,
    SET_ROBOT_UPGRADE_PRICE,
    SET_ROBOT_LEVEL,
    SET_ROBOT_EFFICIENCY,
    SET_ROBOT_INTERVAL,
    SET_ROBOT_PARTS_FACTORY_ACTIVE,
    SET_SOLARPANELS,
    SET_SOLARPANEL_PRICE,
    SET_SOLARPANEL_UPGRADE_PRICE,
    SET_SOLARPANEL_LEVEL,
    SET_SOLARPANEL_EFFICIENCY,
    SET_SOLARPANEL_PARTS_FACTORY_ACTIVE,
    SET_CHART_DATA,
    SET_EPOCH,
    SET_TIME_PASSED,
    SET_ACTIVE_PROJECT,
    SET_COMPLETED_PROJECTS,
    SET_COMPLETED_ACHIEVEMENTS,
    SET_RESEARCH_SPEED,
    SET_ACTIVE_EVENT,
    SET_MODAL_VISIBLE,
    SET_MODAL_CONTENT,
    SET_NOTIFICATION_VISIBLE,
    SET_NOTIFICATION_CONTENT,
    SET_AUTO_SELL_ENABLED,
    SET_ROBOT_BUTTON_ENABLED,
    SET_ROBOT_UPGRADE_BUTTON_ENABLED,
    SET_ROBOT_PROGRESS,
    SET_STANDARD_OPS,
    SET_OPS_PROGRESS,
    SET_OPS_WAIT_SECONDS,
    SET_CLICK_POWER,
    SET_CLICK_CRIT_CHANCE,
    SET_CLICK_CRIT_MULT,
    SET_ROBOT_MINING_PER_INTERVAL,
    SET_SOLAR_BOOST,
    SET_QUANTUM_COMPUTERS_ACTIVE,
    SET_QUANTUM_COMPUTER_PRICE,
    SET_SPACE_ELEVATOR_ACTIVE,
    SET_ALIEN_TECH_ACTIVE,
    SET_PRODUCTION_BOOST,
    SET_DRONE_COUNT,
    SET_DRONE_EFFICIENCY,
    SET_RARE_UNOBTANIUM_CHANCE,
    SET_RARE_UNOBTANIUM_CHANCE_INTERVAL,
    SET_BLACK_HOLE_ACTIVE,
    SET_BLACK_HOLE_LEVEL,
    SET_HEDGE_FUND_INVESTMENT,
    SET_HEDGE_FUND_BALANCE,
    SET_HEDGE_FUND_CASH,
    SET_HEDGE_FUND_STOCKS,
    SET_TRADING_ENGINE_LEVEL,
    SET_TRADING_ENGINE_UPGRADE_PRICE,
    SET_TRADING_ENGINE_UPGRADE_ENABLED,
    SET_TRADING_TABLE_DATA,
    SET_STOCKS,
    SET_INVEST_STRATEGY,
    SET_MARKET_ANALYSIS,
    SET_FUTURES_MARKET,
    SET_UNOBTANIUM_AMOUNT_BOOST,
    SET_UNOBTANIUM_PRICE_BOOST,
    SET_BOOST_EPOCHS,
    SET_TRUST,
    SET_TRUST_THRESHOLD,
    SET_PROJECT_COUNT,
    SET_PROJECT_COST_MULT,
    SET_TRANS_COST_MULT,
    SET_ASTEROID_ALERT,
    SET_SOUND_ENABLED,
    SET_LAST_MINIGAME,
    SET_ROCKS,
    SET_GAME_RUNNING,
    SET_REWARD_AVAILABLE,
    SET_LIVES,
    SET_SCORE,
    SET_WAS_OFFLINE,
    SET_CLOCK_MILLI_SECONDS,
    SET_LAST_UPDATE,
    SET_LAST_ACTIVE_UPDATE,
    SET_TIME_PASSED_SECONDS
} from "./action";

import {gameDuration, gameLives, lastEvent, lastMined, lastStockTrading} from "../constants/magicNumbers";

const DateNow = Date.now();

export const initialState = {

    instanceId: '',
    userId: '',
    userEmail: '',
    userPhone: '',
    blockhash: '0001',
    useRemoteStorage: false,
    timestamp: 0,
    lastActive: 0,
    nonce: 0,
    totalUnobtanium: "0",
    money: "0",
    stardust: 0,
    unobtanium: "0",
    storageCapacity: 50,
    storageUpgradePrice: 500,
    storageCapacityStatus: 0,
    unobtaniumPrice: 5,
    salesTax: 1,
    robots: 0,
    robotPrice: 500,
    robotUpgradePrice: 10000,
    robotLevel: 1,
    robotEfficiency: 1,
    robotInterval: 10,
    robotMiningPerInterval: 10,
    solarpanels: 1,
    solarpanelPrice: 2000,
    solarpanelUpgradePrice: 8000,
    solarpanelLevel: 1,
    solarpanelEfficiency: 1,
    solarpanelsPartsFactoryActive: false,
    chartData: {labels: ["1"],datasets: [{data: [0]}]},
    epoch: 0,
    timePassed: 0,
    activeProject: { id: 'None', title: '', timestampStart: 50000, completed: false },
    completedProjects: { completed: [] },
    completedAchievements: { completed: [] },
    researchSpeed: 10000,
    autoSellEnabled: false,
    robotButtonEnabled: false,
    robotUpgradeButtonEnabled: false,
    robotsPartsFactoryActive: false,
    robotProgress: 0,
    standardOps: 0,
    opsWaitSeconds: 20,
    opsProgress: 0,
    clickPower: 1,
    clickCritChance: 0.1,
    clickCritMult: 2,
    solarBoost: 1,
    quantumComputersActive: 0,
    quantumComputerPrice: 10000000,
    spaceElevatorActive: 0,
    alienTechActive: 0,
    productionBoost: 1,
    droneCount: 10,
    droneEfficiency: 0.5,
    rareUnobtaniumChance: 1,
    rareUnobtaniumChanceInterval: 120, // 2 minutes
    blackHoleActive: false,
    blackHoleLevel: 1,
    hedgeFundInvestment: false,
    tradingEngineLevel: 1, // The investment engine
    tradingEngineUpgradePrice: 50000, // The investment engine
    tradingEngineUpgradeEnabled: true, // If we have enough money to upgrade the engine
    hedgeFundBalance: "0", // The investment engine
    hedgeFundCash: "0", // The investment engine
    hedgeFundStocks: "0", // The investment engine
    tradingTableData: [], // The current table
    stocks: [], // The current tableent table
    investStrategy: 'low', // The current table
    marketAnalysis: false,
    futuresMarket: false,
    unobtaniumAmountBoost: 1,
    unobtaniumPriceBoost: 1,
    boostEpochs: 0,
    trust: 1,
    trustThreshold: 1000,
    projectCount: 0,
    projectCostMult: 1,
    transCostMult: 1,
    asteroidAlert: false,
    modalVisible: false,
    modalContent: {title: '', text: '', image: ''},
    notificationVisible: false,
    notificationContent: {title: '', text: '', image: ''},
    activeEvent: '',
    soundEnabled: false,
    lastMinigame: DateNow,
    rewardAvailable: "0",
    rocks: [],
    gameRunning: false,
    lives: 3,
    isGameEnded: false,
    score: 0,
    countdown: gameDuration,
    wasOffline: false,
    clockMilliSeconds: 0,
    lastUpdate: DateNow,
    lastActiveUpdate: DateNow,
    timePassedSeconds: 0,
    lastEvent: DateNow
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'RESET_STATE':
            return initialState;
        case 'UPLOAD_BLOCKHASH':
            return { ...state, blockhash: action.payload.blockhash, timestamp: action.payload.timestamp, nonce: action.payload.nonce };
        case 'SET_USER_ID':
            return { ...state, userId: action.payload };
        case 'SET_USER_EMAIL':
            return { ...state, userEmail: action.payload };
        case 'SET_USER_PHONE':
            return { ...state, userPhone: action.payload };
        case 'SET_USE_REMOTE_STORAGE':
            return { ...state, useRemoteStorage: action.payload };
        case 'SET_LAST_ACTIVE':
            return { ...state, lastActive: action.payload };
        case 'SET_INSTANCE_ID':
            return { ...state, instanceId: action.payload };
        case 'SET_TOTAL_UNOBTANIUM':
            return { ...state, totalUnobtanium: action.payload };
        case 'SET_STORAGE_CAPACITY':
            return { ...state, storageCapacity: action.payload };
        case 'SET_STORAGE_UPGRADE_PRICE':
            return { ...state, storageUpgradePrice: action.payload };
        case 'SET_STORAGE_CAPACITY_STATUS':
            return { ...state, storageCapacityStatus: action.payload };
        case 'SET_MONEY':
            return { ...state, money: action.payload };
        case 'SET_STARDUST':
            return { ...state, stardust: action.payload };
        case 'SET_UNOBTANIUM':
            return { ...state, unobtanium: action.payload };
        case 'SET_UNOBTANIUM_PRICE':
            return { ...state, unobtaniumPrice: action.payload };
        case 'SET_SALES_TAX':
            return { ...state, salesTax: action.payload };
        case 'SET_ROBOTS':
            return { ...state, robots: action.payload };
        case 'SET_ROBOT_PRICE':
            return { ...state, robotPrice: action.payload };
        case 'SET_ROBOT_UPGRADE_PRICE':
            return { ...state, robotUpgradePrice: action.payload };
        case 'SET_ROBOT_LEVEL':
            return { ...state, robotLevel: action.payload };
        case 'SET_ROBOT_EFFICIENCY':
            return { ...state, robotEfficiency: action.payload };
        case 'SET_ROBOT_INTERVAL':
            return { ...state, robotInterval: action.payload };
        case 'SET_ROBOT_PARTS_FACTORY_ACTIVE':
            return { ...state, robotsPartsFactoryActive: action.payload };
        case 'SET_SOLARPANELS':
            return { ...state, solarpanels: action.payload };
        case 'SET_SOLARPANEL_PRICE':
            return { ...state, solarpanelPrice: action.payload };
        case 'SET_SOLARPANEL_UPGRADE_PRICE':
            return { ...state, solarpanelUpgradePrice: action.payload };
        case 'SET_SOLARPANEL_LEVEL':
            return { ...state, solarpanelLevel: action.payload };
        case 'SET_SOLARPANEL_EFFICIENCY':
            return { ...state, solarpanelEfficiency: action.payload };
        case 'SET_SOLARPANEL_PARTS_FACTORY_ACTIVE':
            return { ...state, solarpanelsPartsFactoryActive: action.payload };
        case 'SET_CHART_DATA':
            return { ...state, chartData: action.payload };
        case 'SET_EPOCH':
            return { ...state, epoch: action.payload };
        case 'SET_TIME_PASSED':
            return { ...state, timePassed: action.payload };
        case 'SET_ACTIVE_PROJECT':
            return { ...state, activeProject: action.payload };
        case 'SET_COMPLETED_PROJECTS':
            return { ...state, completedProjects: action.payload };
        case 'SET_COMPLETED_ACHIEVEMENTS':
            return { ...state, completedAchievements: action.payload };
        case 'SET_RESEARCH_SPEED':
            return { ...state, researchSpeed: action.payload };
        case 'SET_ACTIVE_EVENT':
            return { ...state, activeEvent: action.payload };
        case 'SET_MODAL_VISIBLE':
            return { ...state, modalVisible: action.payload };
        case 'SET_MODAL_CONTENT':
            return { ...state, modalContent: action.payload };
        case 'SET_NOTIFICATION_VISIBLE':
            return { ...state, notificationVisible: action.payload };
        case 'SET_NOTIFICATION_CONTENT':
            return { ...state, notificationContent: action.payload };
        case 'SET_AUTO_SELL_ENABLED':
            return { ...state, autoSellEnabled: action.payload };
        case 'SET_ROBOT_BUTTON_ENABLED':
            return { ...state, robotButtonEnabled: action.payload };
        case 'SET_ROBOT_UPGRADE_BUTTON_ENABLED':
            return { ...state, robotUpgradeButtonEnabled: action.payload };
        case 'SET_ROBOT_PROGRESS':
            return { ...state, robotProgress: action.payload };
        case 'SET_STANDARD_OPS':
            return { ...state, standardOps: action.payload };
        case 'SET_OPS_WAIT_SECONDS':
            return { ...state, opsWaitSeconds: action.payload };
        case 'SET_OPS_PROGRESS':
            return { ...state, opsProgress: action.payload };
        case 'SET_CLICK_POWER':
            return { ...state, clickPower: action.payload };
        case 'SET_CLICK_CRIT_CHANCE':
            return { ...state, clickCritChance: action.payload };
        case 'SET_CLICK_CRIT_MULT':
            return { ...state, clickCritMult: action.payload };
        case 'SET_ROBOT_MINING_PER_INTERVAL':
            return { ...state, robotMiningPerInterval: action.payload };
        case 'SET_SOLAR_BOOST':
            return { ...state, solarBoost: action.payload };
        case 'SET_QUANTUM_COMPUTERS_ACTIVE':
            return { ...state, quantumComputersActive: action.payload };
        case 'SET_QUANTUM_COMPUTER_PRICE':
            return { ...state, quantumComputerPrice: action.payload };
        case 'SET_SPACE_ELEVATOR_ACTIVE':
            return { ...state, spaceElevatorActive: action.payload };
        case 'SET_ALIEN_TECH_ACTIVE':
            return { ...state, alienTechActive: action.payload };
        case 'SET_PRODUCTION_BOOST':
            return { ...state, productionBoost: action.payload };
        case 'SET_DRONE_COUNT':
            return { ...state, droneCount: action.payload };
        case 'SET_DRONE_EFFICIENCY':
            return { ...state, droneEfficiency: action.payload };
        case 'SET_RARE_UNOBTANIUM_CHANCE':
            return { ...state, rareUnobtaniumChance: action.payload };
        case 'SET_RARE_UNOBTANIUM_CHANCE_INTERVAL':
            return { ...state, rareUnobtaniumChanceInterval: action.payload };
        case 'SET_BLACK_HOLE_ACTIVE':
            return { ...state, blackHoleActive: action.payload };
        case 'SET_BLACK_HOLE_LEVEL':
            return { ...state, blackHoleLevel: action.payload };
        case 'SET_HEDGE_FUND_INVESTMENT':
            return { ...state, hedgeFundInvestment: action.payload };
        case 'SET_HEDGE_FUND_BALANCE':
            return { ...state, hedgeFundBalance: action.payload };
        case 'SET_HEDGE_FUND_CASH':
            return { ...state, hedgeFundCash: action.payload };
        case 'SET_HEDGE_FUND_STOCKS':
            return { ...state, hedgeFundStocks: action.payload };
        case 'SET_TRADING_ENGINE_LEVEL':
            return { ...state, tradingEngineLevel: action.payload };
        case 'SET_TRADING_ENGINE_UPGRADE_PRICE':
            return { ...state, tradingEngineUpgradePrice: action.payload };
        case 'SET_TRADING_ENGINE_UPGRADE_ENABLED':
            return { ...state, tradingEngineUpgradeEnabled: action.payload };
        case 'SET_TRADING_TABLE_DATA':
            return { ...state, tradingTableData: action.payload };
        case 'SET_STOCKS':
            return { ...state, stocks: action.payload };
        case 'SET_INVEST_STRATEGY':
            return { ...state, investStrategy: action.payload };
        case 'SET_MARKET_ANALYSIS':
            return { ...state, marketAnalysis: action.payload };
        case 'SET_FUTURES_MARKET':
            return { ...state, futuresMarket: action.payload };
        case 'SET_UNOBTANIUM_AMOUNT_BOOST':
            return { ...state, unobtaniumAmountBoost: action.payload };
        case 'SET_UNOBTANIUM_PRICE_BOOST':
            return { ...state, unobtaniumPriceBoost: action.payload };
        case 'SET_BOOST_EPOCHS':
            return { ...state, boostEpochs: action.payload };
        case 'SET_TRUST':
            return { ...state, trust: action.payload };
        case 'SET_TRUST_THRESHOLD':
            return { ...state, trustThreshold: action.payload };
        case 'SET_PROJECT_COUNT':
            return { ...state, projectCount: action.payload };
        case 'SET_PROJECT_COST_MULT':
            return { ...state, projectCostMult: action.payload };
        case 'SET_TRANS_COST_MULT':
            return { ...state, transCostMult: action.payload };
        case 'SET_ASTEROID_ALERT':
            return { ...state, asteroidAlert: action.payload };
        case 'SET_SOUND_ENABLED':
            return { ...state, soundEnabled: action.payload };
        case 'SET_LAST_MINIGAME':
            return { ...state, lastMinigame: action.payload };
        case 'SET_ROCKS':
            return { ...state, rocks: action.payload };
        case 'SET_GAME_RUNNING':
            return { ...state, gameRunning: action.payload };
        case 'SET_REWARD_AVAILABLE':
            return { ...state, rewardAvailable: action.payload };
        case 'SET_LIVES':
            return { ...state, lives: action.payload };
        case 'SET_SCORE':
            return { ...state, score: action.payload };
        case 'TICK_COUNTDOWN':
            return { ...state, countdown: state.countdown - 1 };
        case 'RESET_ROCKS':
            return {
                ...state,
                rewardAvailable: '0',
                gameRunning: true,
                isGameEnded: false,
                score: 0,
                lives: gameLives,
                countdown: gameDuration
            };
        case 'END_ROCK_SMASHER_GAME':
            return { ...state,
                gameRunning: false,
                isGameEnded: true
            };
        default:
            return state;

        case 'SET_WAS_OFFLINE':
            return { ...state, wasOffline: state.wasOffline };
        case 'SET_CLOCK_MILLI_SECONDS':
            return { ...state, clockMilliSeconds: state.clockMilliSeconds };
        case 'SET_LAST_UPDATE':
            return { ...state, lastUpdate: state.lastUpdate };
        case 'SET_LAST_ACTIVE_UPDATE':
            return { ...state, lastActiveUpdate: state.lastActiveUpdate };
        case 'SET_TIME_PASSED_SECONDS':
            return { ...state, timePassedSeconds: state.timePassedSeconds };
    }
};

export default reducer;
