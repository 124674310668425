import React from 'react';
import {Alert, Image, ImageBackground, Pressable, ScrollView, Text, View} from 'react-native';
import {AppStyles} from "../AppStyle";
import SelectDropdown from 'react-native-select-dropdown';

import {useDispatch, useSelector} from "react-redux";
import {numbroFormatCurrency, numbroFormatInt} from "../components/helperFunctions";

import * as stateSetters from '../redux/action';

import HedgeFundTable from '../components/HedgeFundTable';
import MyModal from "../components/MyModal";
import {playSound} from "../constants/AppSounds";
import * as trading from "../components/stockTradingHelpers";
import {calculateTradingWinRate} from "../components/stockTradingHelpers";
import PurchaseItem from "../components/PurchaseItem";
import Decimal from "break_infinity.js";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import MoneyHeader from "../components/MoneyHeader";
import EventNotification from "../components/EventNotification";
import Chart from "../components/Chart";
// lodash
import { memoize } from 'lodash';

const investStratOptions = [
    {title: 'Low Risk', key: 'low', image: require('../assets/risk/low.png')},
    {title: 'Medium Risk', key: 'med', image: require('../assets/risk/medium.png')},
    {title: 'High Risk', key: 'high', image: require('../assets/risk/high.png')},
    {title: 'Wallstreetbets', key: 'wsb', image: require('../assets/risk/wsb.png')},
];

const HedgeFundTrading = () => {

    const dispatch = useDispatch();

    // Memoize

    const getMoney = memoize(state => state.money);

    const getTradingEngineLevel = memoize(state => state.tradingEngineLevel);
    const getTradingEngineUpgradePrice = memoize(state => state.tradingEngineUpgradePrice);
    const getTradingEngineUpgradeEnabled = memoize(state => state.tradingEngineUpgradeEnabled);

    const getHedgeFundInvestment = memoize(state => state.hedgeFundInvestment);
    const getHedgeFundBalance = memoize(state => state.hedgeFundBalance);

    const getHedgeFundCash = memoize(state => state.hedgeFundCash);

    const getHedgeFundStocks = memoize(state => state.hedgeFundStocks);

    const getInvestStrategy = memoize(state => state.investStrategy);

    const getSoundEnabled = memoize(state => state.soundEnabled);

    // Usage

    const money = useSelector(getMoney);
    const decimalMoney = new Decimal(money);

    const tradingEngineLevel = useSelector(getTradingEngineLevel);
    const tradingEngineUpgradePrice = useSelector(getTradingEngineUpgradePrice);
    const tradingEngineUpgradePriceDecimal = new Decimal(tradingEngineUpgradePrice);
    const tradingEngineUpgradeEnabled = useSelector(getTradingEngineUpgradeEnabled);

    const hedgeFundInvestment = useSelector(getHedgeFundInvestment);
    const hedgeFundBalance = useSelector(getHedgeFundBalance);
    const decimalHedgeFundBalance = new Decimal(hedgeFundBalance);

    const hedgeFundCash = useSelector(getHedgeFundCash);
    const decimalHedgeFundCash = new Decimal(hedgeFundCash);

    const hedgeFundStocks = useSelector(getHedgeFundStocks);
    const decimalHedgeFundStocks = new Decimal(hedgeFundStocks);

    const investStrategy = useSelector(getInvestStrategy);

    const soundEnabled = useSelector(getSoundEnabled);

    //----------INVESTMENTS----------------------------------------------------------------

    // Update the level
    const newTradingEngineLevel = tradingEngineLevel + 1;
    const newWinRate = calculateTradingWinRate(newTradingEngineLevel, true)
    async function applyEngineUpgradeEffects() {

        // Subtract upgrade cost from money
        dispatch(stateSetters.setMoney(decimalMoney.sub(tradingEngineUpgradePriceDecimal)));

        dispatch(stateSetters.setTradingEngineLevel(newTradingEngineLevel));

        // Calculate new upgrade price
        const newUpgradePrice = trading.calculateTradingEngineUpgradePrice(tradingEngineLevel);
        dispatch(stateSetters.setTradingEngineUpgradePrice(newUpgradePrice));

        dispatch(stateSetters.setModalContent("Investment engine upgraded, expected profit/loss ratio now "+trading.calculateTradingWinRate(newTradingEngineLevel, true) + '%'));
        dispatch(stateSetters.setModalVisible(true));
        await playSound('robot_upgrade', soundEnabled);
    }

    const engineUpgradeMessage = 'Pay '+numbroFormatCurrency(tradingEngineUpgradePriceDecimal)+' to upgrade the trading engine and increase the chance of profitable trades to '+newWinRate+'%?';

    async function applyDepositEffects(depositAmount) {

        const allIn = decimalMoney.equals(depositAmount);

        const prevBalance = decimalHedgeFundBalance;
        const depositAmountDecimal = new Decimal(depositAmount);
        dispatch(stateSetters.setHedgeFundCash(decimalHedgeFundCash.plus(depositAmountDecimal)));
        dispatch(stateSetters.setHedgeFundBalance(decimalHedgeFundBalance.plus(depositAmountDecimal)));

        // Reset chart
        dispatch(stateSetters.setChartData({labels: ["1"],datasets: [{data: [0]}]}));

        // Maybe display Bogdanoff
        if (prevBalance === 0 && allIn) {
            dispatch(stateSetters.setModalContent({text: '', title: '', image: require('../assets/bogg.png')}));
            dispatch(stateSetters.setModalVisible(true));
            await playSound('he_bought', soundEnabled);
        }
    }

    const handleWithdrawal = () => {
        if( decimalHedgeFundCash > 0 ) {
            dispatch(stateSetters.setHedgeFundCash(0));
            dispatch(stateSetters.setMoney(decimalMoney.plus(hedgeFundCash)));
            dispatch(stateSetters.setHedgeFundBalance(decimalHedgeFundStocks));
        }
    };

    const tradingEfficiencyPercentage = trading.calculateTradingWinRate(tradingEngineLevel, true);

    function getTraderImage() {
        let image;
        switch(tradingEngineLevel) {
            case 1:
                image = require('../assets/hedge_fund_trader_1.png');
                break;
            case 2:
                image = require('../assets/hedge_fund_trader_2.png');
                break;
            case 3:
                image = require('../assets/hedge_fund_trader_3.png');
                break;
            case 4:
                image = require('../assets/hedge_fund_trader_4.png');
                break;
            case 5:
                image = require('../assets/hedge_fund_trader_5.png');
                break;
            /*case 6:
                image = require('../assets/hedge_fund_trader_6.png');
                break;
            case 7:
                image = require('../assets/hedge_fund_trader_7.png');
                break;
            case 8:
                image = require('../assets/hedge_fund_trader_8.png');
                break;
            case 9:
                image = require('../assets/hedge_fund_trader_9.png');
                break;
            case 10:
                image = require('../assets/hedge_fund_trader_10.png');
                break;
            case 11:
                image = require('../assets/hedge_fund_trader_11.png');
                break;
            case 12:
                image = require('../assets/hedge_fund_trader_12.png');
                break;*/
            default:
                // code block
                image = require('../assets/hedge_fund_trader_5.png');
        }

        // TODO Create more images for traders beyond level 5

        return image;

    }

    function getStrategyIndex() {
        return investStratOptions.findIndex(o => o.key === investStrategy);
    }

    const depositButtonEnabled = hedgeFundInvestment;
    const withdrawalButtonEnabled = decimalHedgeFundCash > 0;
    const upgradeButtonEnabled = hedgeFundInvestment;

    const withdrawalButtonStyle =[AppStyles.button, AppStyles.blueButton, !withdrawalButtonEnabled && AppStyles.disabledBlueButton ];
    const pressedButtonStyle = [AppStyles.button, AppStyles.blueButton, AppStyles.pressedBlueButton, AppStyles.moveButton];


    function handleTooltip() {
        dispatch(stateSetters.setModalContent(
            {
                title: 'Hedge Fund',
                text: 'The hedge fund utilizes sophisticated algorithms and predictive models to identify profitable investment opportunities across the vast and volatile markets of the universe.'

            }
        ));
        dispatch(stateSetters.setModalVisible(true));
    }
    const notificationVisible = useSelector( state => state.notificationVisible);

    const chartData = useSelector( state => state.chartData);
    return (
        <ImageBackground source={require('../assets/bg_circuit.png')} style={AppStyles.backgroundImage}>
            <View style={AppStyles.container}>
                {notificationVisible && <EventNotification/>}
                <View>
                <MoneyHeader />
                <ScrollView>
                <View style={AppStyles.hedgeFundContainer}>
                    <View style={AppStyles.darkBackground}>
                        <Pressable onPress={() => {handleTooltip() }} >
                            <Text style={[AppStyles.title, AppStyles.white]}>Hedge Fund <MaterialCommunityIcons name="frequently-asked-questions" color={'#ffffff'} size={16}/></Text>
                        </Pressable>
                {
                    ! hedgeFundInvestment &&
                    <Text style={[AppStyles.text, AppStyles.red]}>You need to complete the project "Hedge Fund Investment" before you can deposit.</Text>
                }

            { decimalHedgeFundBalance > 0 &&
                <View>
            <View style={[AppStyles.balances]}>
                <ImageBackground source={require('../assets/interface/screen.png')} style={[AppStyles.tradingStatsBGImage, AppStyles.paddingLeft]}>
                <View style={AppStyles.row}>
                    <View>
                        <Text style={[AppStyles.title, AppStyles.textLeft, AppStyles.white]}>Cash: {numbroFormatCurrency(decimalHedgeFundCash)}</Text>
                        <Text style={[AppStyles.title, AppStyles.textLeft, AppStyles.white]}>Stocks: {numbroFormatCurrency(decimalHedgeFundStocks)}</Text>
                        <Text style={[AppStyles.title, AppStyles.textLeft, AppStyles.white]}>Total: {numbroFormatCurrency(decimalHedgeFundBalance)}</Text>
                    </View>
                </View>
                </ImageBackground>
            </View>

                </View>
            }
            <View style={AppStyles.dropdownRow}>
                <View>
                    <SelectDropdown
                        data={investStratOptions}
                        defaultValueByIndex={getStrategyIndex()}
                        defaultButtonText="Investment Risk"
                        onSelect={(selectedItem, index) => {
                            console.log(selectedItem.key);
                            dispatch( dispatch(stateSetters.setInvestStrategy(selectedItem.key)));
                        }}
                        buttonStyle={AppStyles.dropdown3BtnStyle}
                        renderCustomizedButtonChild={(selectedItem, index) => {
                            return (
                                <View style={AppStyles.dropdown3BtnChildStyle}>
                                    {selectedItem ? (
                                        <Image source={selectedItem.image} style={AppStyles.dropdown3BtnImage} />
                                    ) : (
                                        <Text>?</Text>
                                    )}
                                    <Text style={AppStyles.dropdown3BtnTxt}>{selectedItem ? selectedItem.title : 'Select risk level'}</Text>
                                </View>
                            );
                        }}
                        dropdownStyle={AppStyles.dropdown3DropdownStyle}
                        rowStyle={AppStyles.dropdown3RowStyle}
                        renderCustomizedRowChild={(item, index) => {
                            return (
                                <View style={AppStyles.dropdown3RowChildStyle}>
                                    <Image source={item.image} style={AppStyles.dropdownRowImage} />
                                    <Text style={AppStyles.dropdown3RowTxt}>{item.title}</Text>
                                </View>
                            );
                        }}
                    />
                </View>
            </View>
            <View style={[AppStyles.row]}>
                <View style={AppStyles.flex}>
                    <PurchaseItem
                        doPurchase={applyDepositEffects}
                        buttonText={'Deposit'}
                        buttonEnabled={depositButtonEnabled}
                        buyTitle={'Hedge Fund Deposit'}
                        buyMessage={'Send money to the hedge fund and start trading.'}
                        stardustPurchase={false}
                        euroDollarPrice={decimalMoney}
                    />
                </View>
                <View style={AppStyles.flex}>
                    <Pressable
                        onPress={() => { handleWithdrawal() }}
                        disabled={!withdrawalButtonEnabled}
                        style={({pressed}) => [
                            pressed ? pressedButtonStyle : withdrawalButtonStyle,
                        ]}>
                        <Text style={AppStyles.buttonText}>Withdraw</Text>
                    </Pressable>
                </View>
            </View>
            <View style={[AppStyles.row, AppStyles.rowcenter]}>
                <View style={[AppStyles.row]}>
                    <View style={[AppStyles.turqoiseBorder]}>
                        <ImageBackground source={getTraderImage()}
                                         style={[AppStyles.traderImage]}>
                            { tradingEngineLevel > 1 &&
                                <Text style={[AppStyles.white, AppStyles.levelNumber, AppStyles.textShadow]}>
                                    Level {numbroFormatInt(tradingEngineLevel, 100)}
                                </Text>
                            }
                        </ImageBackground>
                    </View>
                    <View style={[AppStyles.darkBackground]}>

                        <PurchaseItem
                            doPurchase={applyEngineUpgradeEffects}
                            buttonText={"Upgrade Trading Engine\n("+numbroFormatCurrency(tradingEngineUpgradePriceDecimal)+")"}
                            buttonEnabled={upgradeButtonEnabled}
                            buyTitle={'Upgrade Trading Engine'}
                            buyMessage={engineUpgradeMessage}
                            stardustPurchase={true}
                            euroDollarPrice={tradingEngineUpgradePriceDecimal}
                        />
                        <Text style={[AppStyles.smallMarginLeft, AppStyles.balances, AppStyles.white]}>Trading Engine Win Rate: {tradingEfficiencyPercentage}%</Text>
                    </View>
                    </View>
            </View>
            <View>

            </View>
            <View>
                { decimalHedgeFundBalance > 0 &&
                <Chart chartData={chartData} />
                }
                <HedgeFundTable />
            </View>
        </View>
            <MyModal />
        </View>
            </ScrollView>
                </View>
            </View>
        </ImageBackground>
    )
}

export default HedgeFundTrading