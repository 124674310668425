import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Text, View} from "react-native";
import {AppStyles} from "../AppStyle";
import {supabaseClient} from "../supabase";
import {setModalContent, setMoney} from "../redux/action";
import {numbroFormatCurrency} from "./helperFunctions";
import Decimal from "break_infinity.js";


const  DAILY_LOGIN_BONUS_AMOUNT = 1000; // TODO Refactor
export const DailyLoginBonus = () => {

    const dispatch = useDispatch();
    const [bonusClaimed, setBonusClaimed] = useState(false);
    const [bonusChecked, setBonusChecked] = useState(false);
    const instanceId = useSelector( state => state.instanceId);
    const useRemoteStorage = useSelector( state => state.useRemoteStorage);
    const money = useSelector( state => state.money);
    const decimalMoney = new Decimal(money);
    const userEmail = useSelector( state => state.userEmail);

    useEffect(() => {
        const checkDailyLoginBonus = async () => {
            const now = new Date();

            // Initialize supabase
            const supabase = supabaseClient();

            // Get last login timestamp for this instance ID from supabase
            const { data, error } = await supabase
                .from('daily_login_bonus')
                .select()
                .eq('instance_id', instanceId).limit(1).maybeSingle();

            console.log('lastLogin', data);
            if( data && ! error ) {

                const lastLoginTimestamp = data.last_login;
                console.log('lastLoginTimestamp', lastLoginTimestamp);

                const lastLogin = new Date(lastLoginTimestamp);

                const minTimePassed = 24 * 60 * 60 * 1000;  // 24 hours in milliseconds

                // check if 24 hours have passed since the last login
                if (now - lastLogin > minTimePassed) {
                    console.log('claim bonus');

                    const success = await updateLastLogin(instanceId, userEmail);

                    if (success) {
                        // update the state with the bonus money
                        dispatch(setMoney(decimalMoney.plus(new Decimal(DAILY_LOGIN_BONUS_AMOUNT))));
                        setBonusClaimed(true);

                        dispatch(setModalContent('Congratulations! You claimed the daily login bonus of ' + numbroFormatCurrency(DAILY_LOGIN_BONUS_AMOUNT) + '!'));
                    }
                }
                setBonusChecked(true);
            }
        };

        if( userEmail && useRemoteStorage && ! bonusClaimed && ! bonusChecked ) {
            checkDailyLoginBonus();
        }
    });

    return (
        <View style={[AppStyles.settingsSectionWrapper, AppStyles.darkBackground, AppStyles.turqoiseBorder]}>
            {
                bonusClaimed && useRemoteStorage ?
                    <Text style={[AppStyles.text, AppStyles.white, AppStyles.smalltext]}>Bonus claimed</Text>
                    :
                    <Text style={[AppStyles.text, AppStyles.white, AppStyles.smalltext]}>Bonus not claimed. Enable remote storage in the settings and log in every day to receive a bonus!</Text>
            }
        </View>
    );
};

export async function updateLastLogin(instanceId, userEmail) {

    const time = Date.now();

    // Try to get the last login
    const { data, error } = await supabaseClient
        .from('daily_login_bonus')
        .select('id')
        .eq('instance_id', instanceId).limit(1).maybeSingle();

    let success = false;
    if( data ) {
        // Make a server call to UPDATE the last login
        const {data, error} = await supabaseClient
            .from("daily_login_bonus")
            .update({instance_id: instanceId, last_login: time, email: userEmail})
            .eq('instance_id', instanceId);
    } else {
        // Make a server call to INSERT the last login
        const {data, error} = await supabaseClient
            .from("daily_login_bonus")
            .insert({instance_id: instanceId, last_login: time, email: userEmail});
    }
    if (!error) {
        success = true;
    } else {
        console.log('updateLastLogin', error);
    }
    return success;
}