import * as stateSetters from '../redux/action';
import {isProjectCompleted} from "../components/helperFunctions";

//
// Events
//

export default function getEvents(dispatch, robotMiningPerInterval, unobtaniumPrice, unobtaniumPriceBoost, trust,
                                  standardOps, completedProjects, robots, epoch) {

    let events_array = [];

    let event1 = {
        id: "eventButton1",
        title: "Space Storm",
        description: "A space storm has damaged some of the mining equipment, decreasing production for 1 epoch.",
        trigger: function () {
            return event1.flag === 0 && Math.random() < 0.1
        },
        flag: 0,
        element: null,
        effect: function () {
            event1.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event1.title,
					text: "Space storm approaching! Production reduced by 50%.",
					image: require('../assets/events/event1.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 2));
            setTimeout(function () {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 2));
                event1.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "Space storm passed.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 60000);
        }
    }
    events_array.push(event1);


    let event2 = {
        id: "eventButton2",
        title: "Market Crash",
        description: "The Unobtanium market crashes, decreasing the sell price for two epochs.",
        trigger: function () {
            return event2.flag === 0 && Math.random() < 0.1
        },
        flag: 0,
        element: null,
        effect: function () {
            event2.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event2.title,
					text: "Market crash! Sell price decreased by 50% for two epochs.",
					image: require('../assets/events/event2.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumPrice(unobtaniumPrice / 2));
            setTimeout(function () {
                dispatch(stateSetters.setUnobtaniumPrice(unobtaniumPrice * 2));
                event2.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "Market recovered. Sell price increased.",
					image: require('../assets/events/info.png')}
				));
            }, 120000);
        }
    }
    events_array.push(event2);

    let event3 = {
        id: "eventButton3",
        title: "Robot Uprising",
        description: "A group of rogue robots are destroying mining equipment. Production rate -10%",
        trigger: function () {
            return trust >= 10 && event3.flag === 0
        },
        flag: 0,
        element: null,
        effect: function () {
            event3.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event3.title,
					text: "A group of rogue robots are destroying mining equipment. Production rate -10%.",
					image: require('../assets/events/event3.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 0.9));
            setTimeout(function () {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.11));
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "The rogue robots have been dealt with. Production rate has returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 30000);
        }
    }
    events_array.push(event3);

    let event4 = {
        id: "eventButton4",
        title: "Political Unrest",
        description: "Political unrest has caused the price of Unobtanium to fluctuate. Sell price +10%/-10%",
        trigger: function () {
            return trust >= 20 && event4.flag === 0
        },
        flag: 0,
        element: null,
        effect: function () {
            event4.flag = 1;
            let rand = Math.random();
            if (rand < 0.5) {
                dispatch(stateSetters.setNotificationContent(
				{
					title: event4.title,
					text: "Political unrest has caused the price of Unobtanium to increase. Sell price +10%",
					image: require('../assets/events/event4.png')
				}
			));
                dispatch(stateSetters.setNotificationVisible(true));
                dispatch(stateSetters.setUnobtaniumPrice(unobtaniumPrice * 1.1));
            } else {
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "Political unrest has caused the price of Unobtanium to decrease. Sell price -10%",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
                dispatch(stateSetters.setUnobtaniumPrice(unobtaniumPrice * 0.9));
            }
        }
    }
    events_array.push(event4);

    let event5 = {
        id: "eventButton5",
        title: "Solar Flare",
        description: "A solar flare has disrupted your mining operations, decreasing production for 2 epochs.",
        trigger: function () {
            return event5.flag === 0 && Math.random() < 0.15
        },
        flag: 0,
        element: null,
        effect: function () {
            event5.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event5.title,
					text: "Solar flare incoming! Mining output decreased by 25% for 2 seconds.",
					image: require('../assets/events/event5.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 1.25));
            setTimeout(function () {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.26));
                event5.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "Solar flare passed. Mining output returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 120000);
        }
    }
    events_array.push(event5);

    let event6 = {
        id: "eventButton6",
        title: "Supply Shortage",
        description: "Your supply of mining equipment has run low, decreasing production by 15% for 3 epochs.",
        trigger: function () {
            return event6.flag === 0 && Math.random() < 0.2
        },
        flag: 0,
        element: null,
        effect: function () {
            event6.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event6.title,
					text: "Supply shortage! Mining output decreased by 15% for 3 epochs.",
					image: require('../assets/events/event6.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 1.15));
            setTimeout(function () {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.16));
                event6.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "Supply shortage resolved. Mining output returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 180000);
        }
    }
    events_array.push(event6);

    let event7 = {
        id: "eventButton7",
        title: "Alien Encounter",
        description: "Alien lifeforms have interfered with your robots, decreasing production by 20% for 3 epochs.",
        trigger: function () {
            return event7.flag === 0 && Math.random() < 0.1
        },
        flag: 0,
        element: null,
        effect: function () {
            event7.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event7.title,
					text: "Alien encounter! Mining output decreased by 20% for 3 epochs.",
					image: require('../assets/events/event7.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 1.2));
            setTimeout(function () {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.2));
                event7.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "Alien encounter resolved. Mining output returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 180000);
        }
    }
    events_array.push(event7);

    let event8 = {
        id: "eventButton8",
        title: "Sabotage",
        description: "A group of rebels has infiltrated the mining site and sabotaged the robots, reducing production by 20% for 3 epochs.",
        trigger: function() {
            return event8.flag === 0 && Math.random() < 0.1;
        },
        flag: 0,
        element: null,
        effect: function() {
            event8.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event8.title,
					text: "Sabotage! Mining output decreased by 20% for 3 epochs.",
					image: require('../assets/events/event8.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 1.2));
            setTimeout(function() {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.2));
                event8.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "Sabotage resolved. Mining output returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 180000);
        }
    }
    events_array.push(event8);

    let event9 = {
        id: "eventButton9",
        title: "Cyber Attack",
        description: "A malicious hacker has launched a cyber attack on the mining site, reducing production by 10% for 1 epoch.",
        trigger: function() {
            return event9.flag === 0 && Math.random() < 0.1;
        },
        flag: 0,
        element: null,
        effect: function() {
            event9.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event9.title,
					text: "Cyber Attack! Mining output decreased by 10% for 1 epoch.",
					image: require('../assets/events/event9.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 1.1));
            setTimeout(function() {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.1));
                event9.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "Cyber Attack resolved. Mining output returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 60000);
        }
    }
    events_array.push(event9);

    let event10 = {
        id: "eventButton10",
        title: "Solar Eclipse",
        description: "A rare solar eclipse has occurred, reducing the efficiency of solar panels and decreasing production by 15% for 2 epochs.",
        trigger: function() {
            return event10.flag === 0 && Math.random() < 0.1;
        },
        flag: 0,
        element: null,
        effect: function() {
            event10.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event10.title,
					text: "Solar Eclipse! Mining output decreased by 15% for 2 epochs.",
					image: require('../assets/events/event10.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 0.85));
            setTimeout(function() {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 0.85));
                event10.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "Solar Eclipse resolved. Mining output returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 120000);
        }
    }
    events_array.push(event10);

    let event11 = {
        id: "eventButton11",
        title: "Dust Storm",
        description: "A massive dust storm has hit the mining site, reducing visibility and decreasing production by 10% for 2 epochs.",
        trigger: function () {
            return event11.flag === 0 && Math.random() < 0.2
        },
        flag: 0,
        element: null,
        effect: function () {
            event11.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event11.title,
					text: "A dust storm is approaching! Mining output decreased by 10% for 2 epochs.",
					image: require('../assets/events/event11.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 1.1));
            setTimeout(function () {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.1));
                event11.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "The dust storm has cleared. Mining output returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 120000);
        }
    }
    events_array.push(event11);

    let event12 = {
        id: "eventButton12",
        title: "Fire",
        description: "A fire has broken out at the mining site, damaging equipment and reducing production by 30% for 5 epochs.",
        trigger: function () {
            return event12.flag === 0 && Math.random() < 0.05
        },
        flag: 0,
        element: null,
        effect: function () {
            event12.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event12.title,
					text: "A fire has broken out! Mining output decreased by 30% for 5 epochs.",
					image: require('../assets/events/event12.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 1.3));
            setTimeout(function () {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.3));
                event12.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "The fire has been extinguished. Mining output returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 300000);
        }
    }
    events_array.push(event12);

    let event13 = {
        id: "eventButton13",
        title: "Contamination",
        description: "A chemical spill has contaminated the mining site, reducing production by 20% for 4 epochs.",
        trigger: function () {
            return event13.flag === 0 && Math.random() < 0.1
        },
        flag: 0,
        element: null,
        effect: function () {
            event13.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event13.title,
					text: "A chemical spill has occurred! Mining output decreased by 20% for 4 epochs.",
					image: require('../assets/events/event13.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 1.2));
            setTimeout(function () {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.2));
                event13.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "The contamination has been cleaned up. Mining output returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 240000);
        }
    }
    events_array.push(event13);

    let event14 = {
        id: "eventButton14",
        title: "Robot Malfunction",
        description: "One of your robots has malfunctioned, causing a decrease in production by 5% for 1 epoch.",
        trigger: function () {
            return event14.flag === 0 && Math.random() < 0.2
        },
        flag: 0,
        element: null,
        effect: function () {
            event14.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event14.title,
					text: "Robot malfunction! Mining output decreased by 5% for 1 epoch.",
					image: require('../assets/events/event14.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval / 1.05));
            setTimeout(function () {
                dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.05));
                event14.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "Robot malfunction resolved. Mining output returned to normal.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 60000);
        }
    }
    events_array.push(event14);

    let event15 = {
        id: "eventButton15",
        title: "Pump It",
        description: "The price of Unobtanium rises by 50% for 3 epochs.",
        trigger: function () {
            return event15.flag === 0 && Math.random() < 0.1
        },
        flag: 0,
        element: null,
        effect: function () {
            event15.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event15.title,
					text: "Pamp eet! The price of Unobtanium rises by 50% for 3 epochs.",
					image: require('../assets/events/event15.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumPriceBoost(unobtaniumPriceBoost * 1.5));
            setTimeout(function () {
                dispatch(stateSetters.setUnobtaniumPriceBoost(unobtaniumPriceBoost / 1.5));
                event15.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "The Unobtanium market price has normalized.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 180000);
        }
    }
    events_array.push(event15);

    let event16 = {
        id: "eventButton16",
        title: "Dump It",
        description: "The price of Unobtanium crashes by 50% for 3 epochs.",
        trigger: function () {
            return event16.flag === 0 && Math.random() < 0.1
        },
        flag: 0,
        element: null,
        effect: function () {
            event16.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event16.title,
					text: "Damp eet! The price of Unobtanium crashes by 50% for 3 epochs.",
					image: require('../assets/events/event16.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumPriceBoost(unobtaniumPriceBoost / 1.5));
            setTimeout(function () {
                dispatch(stateSetters.setUnobtaniumPriceBoost(unobtaniumPriceBoost * 1.5));
                event16.flag = 0;
                dispatch(stateSetters.setNotificationContent(
				{
					title: '',
					text:   "The Unobtanium market price has normalized.",
					image: require('../assets/events/info.png')}
				));
                dispatch(stateSetters.setNotificationVisible(true));
            }, 180000);
        }
    }
    events_array.push(event16);

    let event17 = {
        id: "eventButton17",
        title: "Meteorite Crash",
        description: "A meteorite crashes into the mining site.",
        trigger: function () {
            return epoch >= 10 && Math.random() < 0.1 && !isProjectCompleted('projectButton3', completedProjects)
        },
        flag: 0,
        element: null,
        effect: function () {
            event17.flag++;
            dispatch(stateSetters.setNotificationContent(
				{
					title: event17.title,
					text: "A meteorite crashed into the mining site and you just lost 25% of your robots. You should probably get a meteorite detector to prevent this from happening again.",
					image: require('../assets/events/event17.png')
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobots(Math.floor(robots * 0.75)));
        }
    }
    events_array.push(event17);


    return events_array;

}