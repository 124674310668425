import * as stateSetters from '../redux/action';
import {setAutoSellEnabled} from "../redux/action";
import Decimal from "break_infinity.js";

export default function getProjects(
    dispatch, timePassedSeconds,decimalMoney, standardOps,robotMiningPerInterval,
    rareUnobtaniumChance, solarBoost, unobtaniumAmountBoost,
    unobtaniumPriceBoost, trustThreshold, quantumComputersActive

) {
    let projects_array = [];

    const project0Image = require('../assets/projects/generate_ops.png');
    let project0 = {
        id: "projectButton0",
        title: "Generate OPS",
        priceTag: "Free",
        description: "Generates more OPS for completing projects.",
        trigger: function () {
            return true
        },
        uses: 0,
        cost: 0,
        flag: 0,
        image: project0Image,
        element: null,
        effect: function(){
            dispatch(stateSetters.setNotificationContent(
				{
					title: project0.title,
					text: "New OPS have been generated!",
					image: project0Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            project1.flag = 1;
            dispatch(stateSetters.setStandardOps(standardOps  + 1000));
        }
    }
    projects_array.push(project0);

    const project8Image = require('../assets/projects/quantum_computer_factory.png');
    let project8 = {
        id: "projectButton8",
        title: "Quantum Computer Factory",
        priceTag: "10,000 OPS",
        description: "Build a factory to produce quantum computers.",
        trigger: function () {
            return true;
        },
        uses: 1,
        cost: 10000,
        flag: 0,
        element: null,
        image: project8Image,
        effect: function(){
            project8.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project8.title,
					text: "Quantum computer factory has been built and is ready to produce!",
					image: project8Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
        }
    }
    projects_array.push(project8);

    const project1Image = require('../assets/projects/unobtanium_refinery.png');
    let project1 = {
        id: "projectButton1",
        title: "Unobtanium Refinery",
        priceTag: "10,000 OPS",
        description: "Doubles the amount of Unobtanium mined.",
        trigger: function () {
            return timePassedSeconds > 1000
        },
        uses: 1,
        cost: 10000,
        flag: 0,
        element: null,
        image: project1Image,
        effect: function(){
            project1.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project1.title,
					text: "Unobtanium refinery built!",
					image: project1Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumAmountBoost(2));
        }
    }
    projects_array.push(project1);

    const project2Image = require('../assets/projects/robotic_arm.png');
    let project2 = {
        id: "projectButton2",
        title: "Robotic Arms",
        priceTag: "5,000 OPS",
        description: "Increases the speed of robots by 50%.",
        trigger: function () {
            return timePassedSeconds > 500
        },
        uses: 1,
        cost: 5000,
        flag: 0,
        image: project2Image,
        element: null,
        effect: function(){
            project2.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project2.title,
					text: "Robotic arms installed!",
					image: project2Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotMiningPerInterval(robotMiningPerInterval * 1.5));
        }
    }
    projects_array.push(project2);

    const project3Image = require('../assets/projects/asteroid_detector.png');
    let project3 = {
        id: "projectButton3",
        title: "Meteorite Detector",
        priceTag: "1,500 OPS",
        description: "Alerts you of incoming meteorites, allowing you to avoid them.",
        trigger: function () {
            return timePassedSeconds > 2000
        },
        uses: 1,
        cost: 1500,
        flag: 0,
        element: null,
        image: project3Image,
        effect: function(){
            project3.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project3.title,
					text: "Meteorite detector installed!",
					image: project3Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setAsteroidAlert(true));
        }
    }
    projects_array.push(project3);

    const project4Image = require('../assets/projects/drone_swarm.png');
    let project4 = {
        id: "projectButton4",
        title: "Drone Swarm",
        priceTag: "2,000 OPS",
        description: "Unlocks a fleet of drones that mine Unobtanium automatically.",
        trigger: function () {
            return timePassedSeconds > 5000
        },
        uses: 1,
        cost: 2000,
        flag: 0,
        element: null,
        image: project4Image,
        effect: function(){
            project4.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project4.title,
					text: "Drone swarm deployed!",
					image: project4Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setDroneCount(10));
            dispatch(stateSetters.setDroneEfficiency(1.5));
        }
    }
    projects_array.push(project4);

    const project5Image = require('../assets/projects/geiger_counter.png');
    let project5 = {
        id: "projectButton5",
        title: "Geiger Counter",
        priceTag: "7,500 OPS",
        description: "Increases the chances of finding rare Unobtanium deposits by 20%.",
        trigger: function () {
            return timePassedSeconds > 1000
        },
        uses: 1,
        cost: 7500,
        flag: 0,
        element: null,
        image: project5Image,
        effect: function(){
            project5.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project5.title,
					text: "Geiger counter installed!",
					image: project5Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRareUnobtaniumChance(rareUnobtaniumChance + 0.2));
        }
    }
    projects_array.push(project5);

    const project6Image = require('../assets/projects/black_hole.png');
    let project6 = {
        id: "projectButton6",
        title: "Black Hole Projector",  // TODO Create game logic for Black Hole Projector
        priceTag: "25,000 OPS",
        description: "Creates a black hole that pulls in all nearby asteroids and collects their resources.",
        trigger: function () {
            return timePassedSeconds > 10000
        },
        uses: 1,
        cost: 25000,
        flag: 0,
        element: null,
        image: project6Image,
        effect: function(){
            project6.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project6.title,
					text: "Black hole projector built!",
					image: project6Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setBlackHoleActive(true));
            dispatch(stateSetters.setBlackHoleLevel(1));
        }
    }
    projects_array.push(project6);

    const project7Image = require('../assets/projects/power_grid.png');
    let project7 = {
        id: "projectButton7",
        title: "Power Grid Upgrade",
        priceTag: "40,000 OPS",
        description: "Increases the energy efficiency of your operation by 50%.",
        trigger: function () {
            return timePassedSeconds > 1000
        },
        uses: 1,
        cost: 40000,
        flag: 0,
        element: null,
        image: project7Image,
        effect: function(){
            project7.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project7.title,
					text: "Energy efficiency improved by 50%",
					image: project7Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setSolarBoost(solarBoost + .50));
        }
    }
    projects_array.push(project7);

    const project9Image = require('../assets/projects/space_elevator.png');
    let project9 = {
        id: "projectButton9",
        title: "Space Elevator",
        priceTag: "15,000 OPS",
        description: "Transports Unobtanium from the mining site to Earth instantly, reducing transportation costs and increasing your profit by 50%.",
        trigger: function () {
            return timePassedSeconds > 7500
        },
        uses: 1,
        cost: 15000,
        flag: 0,
        element: null,
        image: project9Image,
        effect: function(){
            project9.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project9.title,
					text: "Space elevator built!",
					image: project9Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setSpaceElevatorActive(1));
            dispatch(stateSetters.setTransCostMult(1.5));
        }
    }
    projects_array.push(project9);

    const project10Image = require('../assets/projects/alien_technology.png');
    let project10 = {
        id: "projectButton10",
        title: "Alien Technology",
        priceTag: "15,000 OPS",
        description: "Unlocks the ability to mine Unobtanium from alien asteroids, increasing the amount mined by 50%.",
        trigger: function () {
            return timePassedSeconds > 10000
        },
        uses: 1,
        cost: 10000,
        flag: 0,
        element: null,
        image: project10Image,
        effect: function(){
            project10.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project10.title,
					text: "Alien technology unlocked!",
					image: project10Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setAlienTechActive(1));
            dispatch(unobtaniumAmountBoost(unobtaniumAmountBoost + 0.5));
        }
    }
    projects_array.push(project10);

// Market projects
    const project11Image = require('../assets/projects/stock_market_chart.png');
    let project11 = {
        id: "projectButton11",
        title: "Market Research",
        priceTag: "500 OPS",
        description: "Sell Unobtanium for 5% more for 10 epochs.",
        trigger: function () {
            return timePassedSeconds > 120
        },
        uses: 1,
        cost: 500,
        flag: 0,
        element: null,
        image: project11Image,
        effect: function(){
            project11.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project11.title,
					text: "Market Research complete! Sell Unobtanium for 5% more for 10 epochs.",
					image: project11Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumPriceBoost(unobtaniumPriceBoost + .05));
            dispatch(stateSetters.setBoostEpochs(10));
        }
    }
    projects_array.push(project11);

    const project12Image = require('../assets/projects/projectButton12.png');
    let project12 = {
        id: "projectButton12",
        title: "Advertising Campaign",
        priceTag: "10,000 OPS",
        description: "Sell Unobtanium for 10% more for 10 epochs.",
        trigger: function () {
            return timePassedSeconds > 600
        },
        uses: 1,
        cost: 10000,
        flag: 0,
        element: null,
        image: project12Image,
        effect: function(){
            project12.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project12.title,
					text: "Advertising Campaign complete! Sell Unobtanium for 10% more for 10 epochs.",
					image: project12Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumPriceBoost(unobtaniumPriceBoost + .1));
            dispatch(stateSetters.setBoostEpochs(10));
        }
    }
    projects_array.push(project12);

    const project13Image = require('../assets/projects/mining_cartel.png');
    let project13 = {
        id: "projectButton13",
        title: "Mining Cartel",
        priceTag: "200,000 OPS",
        description: "Sell Unobtanium for 50% more for 20 epochs.",
        trigger: function () {
            return timePassedSeconds > 600
        },
        uses: 0,
        cost: 25000,
        flag: 0,
        element: null,
        image: project13Image,
        effect: function(){
            project13.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project13.title,
					text: "Mining cartel has been established. Sell Unobtanium for 50% more for 20 epochs.",
					image: project13Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumPriceBoost(unobtaniumPriceBoost + .50));
            dispatch(stateSetters.setBoostEpochs(20));
        }
    }
    projects_array.push(project13);

    const project14Image = require('../assets/projects/market_analysis_software.png');
    let project14 = {
        id: "projectButton14",
        title: "Market Analysis Robot",
        priceTag: "20,000 OPS",
        description: "Provides detailed market analysis and suggests optimal sell times.", // TODO Market Analysis Game Logic
        trigger: function () {
            return timePassedSeconds > 1000
        },
        uses: 1,
        cost: 20000,
        flag: 0,
        element: null,
        image: project14Image,
        effect: function(){
            project14.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project14.title,
					text: "Market analysis software installed",
					image: project14Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMarketAnalysis(true));
        }
    }
    projects_array.push(project14);

    const project15Image = require('../assets/projects/marketing_campaign.png');
    let project15 = {
        id: "projectButton15",
        title: "Marketing Campaign",
        priceTag: "25,000 OPS",
        description: "Increases demand for Unobtanium and raises the price by 25% for 3 epochs.",
        trigger: function () {
            return timePassedSeconds > 150
        },
        uses: 0,
        cost: 25000,
        flag: 0,
        element: null,
        image: project15Image,
        effect: function(){
            project15.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project15.title,
					text: "Marketing campaign launched",
					image: project15Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumPriceBoost(unobtaniumPriceBoost + .25));
            dispatch(stateSetters.setBoostEpochs(10));
        }
    }
    projects_array.push(project15);

    const project16Image = require('../assets/projects/corporate_sponsorship.png');
    let project16 = {
        id: "projectButton16",
        title: "Corporate Sponsorship",
        priceTag: "50,000 OPS",
        description: "Increases Unobtanium sell price by 10% for 3 epochs.",
        trigger: function () {
            return timePassedSeconds > 20000
        },
        uses: 1,
        cost: 50000,
        flag: 0,
        element: null,
        image: project16Image,
        effect: function(){
            project16.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project16.title,
					text: "Corporate sponsorship obtained",
					image: project16Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumPriceBoost(unobtaniumPriceBoost + .25));
            dispatch(stateSetters.setBoostEpochs(10));
        }
    }
    projects_array.push(project16);

    const project17Image = require('../assets/projects/futures_market_trading.png');
    let project17 = {
        id: "projectButton17",
        title: "Futures Market Trading",
        priceTag: "75,000 OPS",
        description: "Allows player to trade Unobtanium futures contracts", // TODO Create futures market game logic
        trigger: function () {
            return timePassedSeconds > 25000
        },
        uses: 1,
        cost: 75000,
        flag: 0,
        element: null,
        image: project17Image,
        effect: function(){
            project17.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project17.title,
					text: "Futures market trading enabled",
					image: project17Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setFuturesMarket(true));
        }
    }
    projects_array.push(project17);

    const project18Image = require('../assets/projects/hedge_fund_investment.png');
    let project18 = {
        id: "projectButton18",
        title: "Hedge Fund Investment",
        priceTag: "100,000 OPS",
        description: "Create your own hedge fund to invest in the markets of the universe.",
        trigger: function () {
            return timePassedSeconds > 600
        },
        uses: 1,
        cost: 100000,
        flag: 0,
        element: null,
        image: project18Image,
        effect: function(){
            project18.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project18.title,
					text: "Hedge fund has been set up",
					image: project18Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setHedgeFundInvestment(true));
        }
    }
    projects_array.push(project18);

    const project19Image = require('../assets/projects/merger_and_acquisition.png');
    let project19 = {
        id: "projectButton19",
        title: "Merger and Acquisition",
        priceTag: "200,000 OPS",
        description: "Acquires a rival mining company, increasing production capacity by 50%",
        trigger: function () {
            return timePassedSeconds > 10000
        },
        uses: 1,
        cost: 200000,
        flag: 0,
        element: null,
        image: project19Image,
        effect: function(){
            project19.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project19.title,
					text: "Acquired a rival mining company",
					image: project19Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumAmountBoost(unobtaniumAmountBoost + .5));
        }
    }
    projects_array.push(project19);

    const project20Image = require('../assets/projects/space_market_dominance.png');
    let project20 = {
        id: "projectButton20",
        title: "Space Market Dominance",
        priceTag: "1,000,000 OPS",
        description: "Gain a monopoly on Unobtanium sales, increasing the sale price by 50%",
        trigger: function () {
            return timePassedSeconds > 1000
        },
        uses: 1,
        cost: 1000000,
        flag: 0,
        element: null,
        image: project20Image,
        effect: function(){
            project20.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project20.title,
					text: "You now have a monopoly on Unobtanium sales, increasing the sale price by 50%",
					image: project20Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setUnobtaniumPriceBoost(unobtaniumPriceBoost + .5));
            dispatch(stateSetters.setTrustThreshold(trustThreshold * 1.5));
        }
    }
    projects_array.push(project20);

    const project21Image = require('../assets/projects/project21.png');
    let project21 = {
        id: "projectButton21",
        title: "Sales Manager",
        priceTag: "1,000 OPS",
        description: "Hire a sales manager that sells the mined Unobtanium as soon as it reaches the warehouse.",
        trigger: function () {
            return true
        },
        uses: 1,
        cost: 1000,
        flag: 0,
        element: null,
        image: project21Image,
        effect: function(){
            project21.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project21.title,
					text: "The sales manager has been hired and starts his work immediately.",
					image: project21Image
				}
			));
            dispatch(setAutoSellEnabled(true));
            dispatch(stateSetters.setNotificationVisible(true));
        }
    }
    projects_array.push(project21);

    const project22Image = require('../assets/projects/robot_factory.png');
    let project22 = {
        id: "projectButton22",
        title: "Robot Parts Factory",
        priceTag: "10,000 OPS + €$ 10,000,000",
        description: "Produce parts for the mining robots in-house to permanently decrease the cost for new robots by 30%.",
        trigger: function () {
            return timePassedSeconds > 500 && decimalMoney.greaterThanOrEqualTo(new Decimal(10000000))
        },
        uses: 1,
        cost: 10000,
        flag: 0,
        element: null,
        image: project22Image,
        effect: function(){
            project22.flag = 1;
            dispatch(stateSetters.setNotificationContent(
				{
					title: project22.title,
					text: "Robot parts factory has been built!",
					image: project22Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setRobotsPartsFactoryActive(true));
            dispatch(stateSetters.setMoney(decimalMoney.sub(new Decimal(10000000))));
        }
    }
    projects_array.push(project22);

    return projects_array;
}