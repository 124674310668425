import React from 'react';
import {Text, View, Image, Pressable, ImageBackground, Alert} from 'react-native';
import ProgressCircle from 'react-native-progress/Circle';
import {AppStyles} from "../AppStyle";
import {useDispatch, useSelector} from "react-redux";
import * as stateSetters from '../redux/action';
// lodash
import { memoize } from 'lodash';

import {
    numbroFormatInt,
    numbroFormatCurrency,
    calculateNewSolarPanelPrice,
    calculateNewSolarPanelEfficiency,
    calculateSolarPanelUpgradePrice,
    calculateSolarPanelProduction, calculateEnergyUsage, calculateEnergyUsageRatio
} from "./helperFunctions";
import PurchaseItem from "./PurchaseItem";
import { playSound } from '../constants/AppSounds';
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

let ackHighUsage = false;

const EnergyController = () => {


    const dispatch= useDispatch();

    // Memoize

    const getSolarpanelLevel = memoize(state => state.solarpanelLevel);

    const getSolarBoost = memoize(state => state.solarBoost);

    const getSolarpanelEfficiency = memoize(state => state.solarpanelEfficiency);

    const getSolarpanelPrice = memoize(state => state.solarpanelPrice);
    const getSolarpanelUpgradePrice = memoize(state => state.solarpanelUpgradePrice);

    const getSolarpanels = memoize(state => state.solarpanels);

    const getSoundEnabled = memoize(state => state.soundEnabled);

    const getSolarpanelsPartsFactoryActive = memoize(state => state.solarpanelsPartsFactoryActive);

    // Usage

    const solarpanelLevel = useSelector( getSolarpanelLevel);

    const solarBoost = useSelector( getSolarBoost);

    const solarpanelEfficiency = useSelector( getSolarpanelEfficiency);

    const solarpanelPrice = useSelector( getSolarpanelPrice);
    const solarpanelUpgradePrice = useSelector( getSolarpanelUpgradePrice);

    const solarpanels = useSelector( getSolarpanels);

    const soundEnabled = useSelector( getSoundEnabled);

    const solarpanelsPartsFactoryActive = useSelector( getSolarpanelsPartsFactoryActive);


    const solarEnergyGeneration = calculateSolarPanelProduction(solarpanels, solarpanelEfficiency, solarBoost);
    const addedSolarpanel = solarpanels + 1;
    const newProduction = calculateSolarPanelProduction(addedSolarpanel, solarpanelEfficiency , solarBoost);
    const productionIncrease = (newProduction - solarEnergyGeneration).toFixed(2);

    async function applyBuySolarPanelEffects() {
        const newSolarPanels = solarpanels + 1;
        dispatch(stateSetters.setSolarPanels(newSolarPanels));
        let newPrice = calculateNewSolarPanelPrice(solarpanelPrice, solarpanels, solarpanelsPartsFactoryActive);
        dispatch(stateSetters.setSolarPanelPrice(newPrice));
        await playSound('purchased_robot', soundEnabled);
    }

    let buyMessage = 'Cost: '+numbroFormatCurrency(solarpanelPrice)+'\nEffect: +'+productionIncrease+' Megawatts';


    // Increase solarpanel level
    const newSolarPanelLevel = solarpanelLevel + 1;

    const newEfficiencyAfterUpgrade = calculateNewSolarPanelEfficiency(newSolarPanelLevel);

    const newProductionAfterUpgrade = calculateSolarPanelProduction(solarpanels, newEfficiencyAfterUpgrade, solarBoost);

    const productionIncreaseAfterUpgrade = (newProductionAfterUpgrade - solarEnergyGeneration).toFixed(2);

    async function applySolarPanelUpgradeEffects() {

        dispatch(stateSetters.setSolarPanelLevel(newSolarPanelLevel));

        // Calculate new solarpanel Efficiency
        dispatch(stateSetters.setSolarPanelEfficiency(newEfficiencyAfterUpgrade));

        // Calculate new upgrade price
        let newUpgradePrice = calculateSolarPanelUpgradePrice(solarpanelUpgradePrice, solarpanels);

        dispatch(stateSetters.setSolarPanelUpgradePrice(newUpgradePrice));
        await playSound('robot_upgrade', soundEnabled);
    }

    const upgradeMessage = 'Level: '+newSolarPanelLevel+'\nCost: '+numbroFormatCurrency(solarpanelUpgradePrice)+'\nEffect: + '+productionIncreaseAfterUpgrade+' Megawatts';

    const solarpanelEfficiencyPercentage = (solarpanelEfficiency - 1) * 100;

    function getMiningSolarPanelImage() {
        let image;
        switch(solarpanelLevel) {
            case 1:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 2:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 3:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 4:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 5:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 6:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 7:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 8:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 9:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 10:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 11:
                image = require('../assets/projects/solar_panel.png');
                break;
            case 12:
                image = require('../assets/projects/solar_panel.png');
                break;
            default:
            // code block
             image = require('../assets/projects/solar_panel.png');
        }
         // TODO Create more images for solarpanels beyond level 12

        return image;

    }
    const solarEnergyGenerationString = solarEnergyGeneration.toFixed(2);

    // Memoize

    const getRobots = memoize(state => state.robots);
    const getRobotLevel = memoize(state => state.robotLevel);
    const getQuantumComputersActive = memoize(state => state.quantumComputersActive);
    const getRobotsPartsFactoryActive = memoize(state => state.robotsPartsFactoryActive);
    const getSpaceElevatorActive = memoize(state => state.spaceElevatorActive);
    const getModalVisible = memoize(state => state.modalVisible);

    // Usage

    const robots = useSelector( getRobots);
    const robotLevel = useSelector( getRobotLevel);
    const quantumComputersActive = useSelector( getQuantumComputersActive);
    const robotsPartsFactoryActive = useSelector( getRobotsPartsFactoryActive);
    const spaceElevatorActive = useSelector( getSpaceElevatorActive);
    const modalVisible = useSelector( getModalVisible);


    const energyUsage = calculateEnergyUsage(robots, robotLevel, quantumComputersActive, robotsPartsFactoryActive, solarpanelsPartsFactoryActive, spaceElevatorActive, solarBoost);
    const energyUsageValues = calculateEnergyUsageRatio(solarEnergyGeneration, energyUsage);

    function getEnergyUsageColor() {
        let energyEnergyUsageColor = '#1bffff';
        if (energyUsageValues.energyUtilization > 0.4) {
          energyEnergyUsageColor = '#1bffff';
        }
        if (energyUsageValues.energyUtilization > 0.6) {
          energyEnergyUsageColor = 'orange';
        }
        if (energyUsageValues.energyUtilization > 0.8) {
          energyEnergyUsageColor = 'darkorange';
        }
        if (energyUsageValues.energyUtilization > 1) {
          energyEnergyUsageColor = 'red';
        }

        // Maybe trigger energy usage warning
        if( !ackHighUsage && energyUsageValues.energyUtilization > 1 && ! modalVisible ) {
            const decreasePercent = energyUsageValues.decreasePercent.toFixed(2);
            dispatch(stateSetters.setModalContent({title: 'Low Energy', text: 'You are not generating enough energy! Production and mining are decreased by '+decreasePercent+'%.'}));
            dispatch(stateSetters.setModalVisible(true));
            ackHighUsage = true;
        }

        return energyEnergyUsageColor;
    }

    function handleTooltip() {
        dispatch(stateSetters.setModalContent(
            {
                title: 'Solar Panels',
                text: 'Solar panels generate the energy necessary for mining and OPS production.'

            }
        ));
        dispatch(stateSetters.setModalVisible(true));
    }

    return (
            <View style={AppStyles.solarpanelControl}>
                <View>
                    <Pressable onPress={() => {handleTooltip() }} >
                        <Text style={[AppStyles.title, AppStyles.white]}>Solar Panels <MaterialCommunityIcons name="frequently-asked-questions" color={'#ffffff'} size={16}/></Text>
                    </Pressable>
                    <View style={[AppStyles.darkBackground, {minWidth: '100%'}]}>
                    <Text style={[AppStyles.text, {color: getEnergyUsageColor()}]}>Power Consumption: {energyUsage.toFixed(2)}/{solarEnergyGenerationString} MW</Text>
                    </View>
                    <View style={[AppStyles.row]}>
                        <View style={[AppStyles.achievementBadgeImageWrap, AppStyles.turqoiseBorder]}>
                            <ImageBackground source={require('../assets/projects/solar_panel.png')}
                                             style={[AppStyles.robotImage]}>
                                { solarpanelLevel > 1 &&
                                    <Text style={[AppStyles.white, AppStyles.levelNumber, AppStyles.textShadow]}>
                                        Level {numbroFormatInt(solarpanelLevel, 100)}
                                    </Text>
                                }
                            </ImageBackground>
                        </View>
                        <View style={[AppStyles.balances]}>
                            <ImageBackground source={require('../assets/interface/screen.png')} style={[AppStyles.robotStatsBGImage, AppStyles.paddingLeft]}>
                                <View style={[ AppStyles.paddingLeft, AppStyles.mediumMarginLeft]}>
                            <ProgressCircle
                                size={50}
                                progress={energyUsageValues.energyUtilization}
                                animated={false}
                                showsText={true}
                                width={null}
                                color={getEnergyUsageColor()}
                                style={[AppStyles.progress]}
                                unfilledcolor={'#ffffff'} />
                            <Text style={AppStyles.white}>Solar Panels: {solarpanels}</Text>
                                </View>
                            </ImageBackground>
                        </View>

                        </View>
                </View>


                <View style={[AppStyles.robotPurchaseWrapper, AppStyles.row]}>

                    <View style={[AppStyles.paddingLeft, AppStyles.balances]}>



                    </View>
                </View>
                <View style={[AppStyles.row]}>
                    <PurchaseItem
                            doPurchase={applyBuySolarPanelEffects}
                            buttonText={"Buy Solar Panel (" + numbroFormatCurrency(solarpanelPrice) + ")"}
                            buttonEnabled={true}
                            buyTitle={'Buy Solar Panel'}
                            buyMessage={buyMessage}
                            stardustPurchase={true}
                            euroDollarPrice={solarpanelPrice}
                    />

                { solarpanels > 0 &&
                    <View>
                        <PurchaseItem
                            doPurchase={applySolarPanelUpgradeEffects}
                            buttonText={"Upgrade Solar Panels ("+numbroFormatCurrency(solarpanelUpgradePrice)+")"}
                            buttonEnabled={true}
                            buyTitle={'Upgrade Solar Panels'}
                            buyMessage={upgradeMessage}
                            stardustPurchase={true}
                            euroDollarPrice={solarpanelUpgradePrice}
                        />
                    </View>
                }
                </View>
            </View>
    );
};

export default EnergyController;