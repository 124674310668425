import React, { useRef, useEffect } from 'react';
import { Animated, Easing, Text, View } from 'react-native';

export const ZoomInView = props => {
    const jumpAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

    useEffect(() => {
        const jumpAnimation = Animated.sequence([
            Animated.timing(jumpAnim, {
                toValue: 1, // Jump up to -100 (negative value)
                duration: 80,
                easing: Easing.linear,
                useNativeDriver: true,
            }),
        ]);

        Animated.loop(jumpAnimation, {iterations: 2}).start();
    }, [jumpAnim]);

    return (
        <Animated.View // Special animatable View
            style={{
                ...props.style,
                transform: [{ scale: jumpAnim }], // Bind translateY to animated value
            }}>
            {props.children}
        </Animated.View>
    );
};
