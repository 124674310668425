// ./navigation/StackNavigator.js

import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import SpaceMiner from "../screens/SpaceMiner";
import AchievementBadges from "../screens/AchievementBadges";

const Stack = createNativeStackNavigator();

const screenOptionStyle = {
    headerBackTitle: "Back",
    headerStyle: {
        backgroundColor: '#f4511e',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
        fontFamily: 'Share-Tech-Mono'
    },
};

const MainStackNavigator = () => {
    return (
        <Stack.Navigator screenOptions={screenOptionStyle}>
            <Stack.Screen name="Home" component={SpaceMiner}
                          options={{ headerShown: false}}
            />
            <Stack.Screen name="Achievements" component={AchievementBadges} />
        </Stack.Navigator>
    );
}



export { MainStackNavigator };