import React from "react";
import {AppStyles} from "../AppStyle";
import {Pressable, Switch, Text, View, Image, ImageBackground } from 'react-native';
import ProgressBar from 'react-native-progress/Bar';
import {useDispatch, useSelector} from "react-redux";

import * as stateSetters from '../redux/action';
import {
    numbroFormatInt,
    numbroFormatCurrency,
    onDisplayNotification,
    calculateCapacityStorageStatus,
    calculateStorageUpgradePrice,
    calculateStorageCapacity,
    isProjectCompleted,
    calculateSalesProfit,
} from "./helperFunctions";
import {DailyLoginBonus} from "./BonusPayments";

import { playSound } from '../constants/AppSounds';
import PurchaseItem from "./PurchaseItem";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Decimal from "break_infinity.js";
import * as Linking from "expo-linking";
import { A } from '@expo/html-elements';
import {privacyPolicyUrl, imprintUrl} from "../constants/magicNumbers";
import {isWeb} from "@notifee/react-native/dist/utils";

export default function MainComponent(props) {
    const dispatch = useDispatch();

    const totalUnobtanium = useSelector( state => state.totalUnobtanium);
    const decimalTotalUnobtanium = new Decimal(totalUnobtanium);

    const epoch = useSelector( state => state.epoch);

    const money = useSelector( state => state.money);
    const decimalMoney = new Decimal(money);
    const unobtanium = useSelector( state => state.unobtanium);
    const decimalUnobtanium = new Decimal(unobtanium);

    const storageCapacity = useSelector( state => state.storageCapacity);
    const storageUpgradePrice = useSelector( state => state.storageUpgradePrice);
    const storageCapacityStatus = useSelector( state => state.storageCapacityStatus);

    const unobtaniumPrice = useSelector( state => state.unobtaniumPrice);
    const salesTax = useSelector( state => state.salesTax);

    const autoSellEnabled = useSelector( state => state.autoSellEnabled);
    const boostEpochs = useSelector( state => state.boostEpochs);
    const unobtaniumAmountBoost = useSelector( state => state.unobtaniumAmountBoost);
    const transCostMult = useSelector( state => state.transCostMult);

    // Format numbers
    const totalUnobtaniumString = numbroFormatInt(decimalTotalUnobtanium);
    const unobtaniumString = numbroFormatInt(decimalUnobtanium);
    const storageCapacityPerc = storageCapacityStatus < 1 ? numbroFormatInt(storageCapacityStatus * 100) : 100;
    const moneyString = numbroFormatInt(decimalMoney);
    const salesTaxString = salesTax.toFixed(2);
    const unobtaniumPriceString = numbroFormatCurrency(unobtaniumPrice);

    const soundEnabled = useSelector( state => state.soundEnabled);
    const completedProjects = useSelector( state => state.completedProjects);

    const robots = useSelector( state => state.robots);
    const robotProgress = useSelector( state => state.robotProgress);

    const handleMineUnobtanium = async () => {

        if( storageCapacityStatus < 1 ) {
            await playSound('thud', soundEnabled);
            const timeMul = epoch > 0 ? epoch : 1;
            const newUnobtanium = decimalUnobtanium.plus(new Decimal(5 * timeMul * unobtaniumAmountBoost));
            dispatch(stateSetters.setUnobtanium(newUnobtanium));

            let cap = calculateCapacityStorageStatus(newUnobtanium, storageCapacity);
            dispatch(stateSetters.setStorageCapacityStatus(cap));

        } else {
            if( autoSellEnabled ) {
                await sellProduce();
            } else {
                dispatch(stateSetters.setModalContent('Unobtanium storage is full! You have to sell some Unobtanium or upgrade the storage.\n\nHint: Complete the project "Sales Manager" to enable automatic selling.'));
                dispatch(stateSetters.setModalVisible(true));
            }
        }

        dispatch(stateSetters.setLastActive(Date.now()));
    }

    async function applyStorageUpgradeEffects() {
        await playSound('level_up', soundEnabled);

        // Update price
        const newPrice = calculateStorageUpgradePrice(storageUpgradePrice);
        dispatch(stateSetters.setStorageUpgradePrice(newPrice));

        // Update capacity
        const capacity = calculateStorageCapacity(storageCapacity);
        dispatch(stateSetters.setStorageCapacity(capacity));

        // Display notification
        dispatch(stateSetters.setModalContent('Unobtanium storage has been upgraded! New capacity: ' + numbroFormatInt(capacity)));
        dispatch(stateSetters.setModalVisible(true));
    }

    // Update capacity
    const capacity = calculateStorageCapacity(storageCapacity);

    const upgradePriceString = numbroFormatCurrency(storageUpgradePrice);
    const upgradeStorageMessage = 'Pay '+upgradePriceString+' to upgrade Unobtanium storage capacity to '+numbroFormatInt(capacity)+'?';
    const upgradeButtonText = "Increase Storage Capacity\n("+upgradePriceString+")";

    const sellProduce = async () => {
        await playSound('cashier', soundEnabled);

        const newTotalUnobtanium = decimalTotalUnobtanium.plus(decimalUnobtanium);

        dispatch(stateSetters.setTotalUnobtanium(newTotalUnobtanium));

        // Calculate profit and add to existing money
        const profit = calculateSalesProfit(decimalUnobtanium, unobtaniumPrice, salesTax, transCostMult);

        const newMoney = decimalMoney.plus(new Decimal(profit));

        dispatch(stateSetters.setMoney(newMoney));

        dispatch(stateSetters.setUnobtanium(new Decimal(0)));
        dispatch(stateSetters.setStorageCapacityStatus(0));
    };

    const toggleAutosell = async () => {
        if (autoSellEnabled) {
            await playSound('switch_off', soundEnabled);
        } else {
            await playSound('switch_on', soundEnabled);
        }
        dispatch(stateSetters.setAutoSellEnabled(!autoSellEnabled));
    }

    const mineButtonStyle = [AppStyles.button, AppStyles.blueButton];
    const pressedMineButtonStyle = [AppStyles.button, AppStyles.pressedBlueButton, AppStyles.moveButton];
    const disabledMineButtonStyle = [AppStyles.button, AppStyles.disabledBlueButton];

    const sellButtonStyle = [AppStyles.button, AppStyles.redButton];
    const pressedSellButtonStyle = [AppStyles.button, AppStyles.pressedRedButton, AppStyles.moveButton];
    const disabledSellButtonStyle = [AppStyles.button, AppStyles.redButton, {backgroundColor: 'rgb(143,1,1)'}];
    const epochString = numbroFormatInt(epoch);

    const upgradeButtonEnabled = decimalMoney >=  storageUpgradePrice;
    const upgradeButtonStyle = [AppStyles.button, AppStyles.blueButton, !upgradeButtonEnabled && AppStyles.disabledBlueButton ];
    const pressedButtonStyle = [AppStyles.button, AppStyles.blueButton, AppStyles.pressedBlueButton, AppStyles.moveButton];

    const capacityString = ! autoSellEnabled ? '('+storageCapacityPerc + '% capacity)' : '';

    const autoSellButtonAvailable = isProjectCompleted('projectButton21', completedProjects);

    function handleTooltip() {
        dispatch(stateSetters.setModalContent(
            {
                title: 'Sales Tax',
                text: 'The sales tax will increase over time.\n\n“The avoidance of taxes is the only intellectual pursuit that carries any reward.”\n— economist John Maynard Keynes'

            }
        ));
        dispatch(stateSetters.setModalVisible(true));
    }

    return (
        <View>
            <Text style={[AppStyles.text, AppStyles.white]}>
                The world needs more Unobtanium!
            </Text>
            { /*
            <View>
                <Pressable
                    onPress={() => {
                        onDisplayNotification('Notification Title', 'Wow, now I am really proud!')
                    }}
                    style={AppStyles.button}>
                    <Text style={AppStyles.buttonText}>Display Notification</Text>
                </Pressable>
            </View> */
            }
            <View style={AppStyles.balanceWrapper}>
                <ImageBackground source={require('../assets/interface/screen.png')} style={AppStyles.componentBGImage}>
                    <View style={[AppStyles.rowleft, AppStyles.marginLeft]}>
                        <Text style={[AppStyles.title, AppStyles.white, AppStyles.totalproduction, AppStyles.textShadow]}>Total Sold: {totalUnobtaniumString}</Text>
                    </View>
                    <View style={[AppStyles.row, AppStyles.rowleft, AppStyles.marginLeft]}>
                        <View style={[AppStyles.row, { alignItems: 'center'}]}>
                            <MaterialCommunityIcons name="cash" color={'#ffffff'} size={28}/>
                        <Text style={[AppStyles.balances, AppStyles.textShadow]}>{moneyString}</Text>
                        </View>
                    </View>
                    { ! autoSellEnabled &&
                        <View style={[AppStyles.row, AppStyles.rowleft, AppStyles.marginLeft]}>
                            <View style={[AppStyles.row, {alignItems: 'center', marginTop: -15}]}>
                                <MaterialCommunityIcons name="garage-variant" color={'#ffffff'} size={28}/>

                                {
                                 /*   <MaterialCommunityIcons name="diamond-stone" color={'#ffffff'} size={28}/>
                                <MaterialCommunityIcons name="atom" color={'#ffffff'} size={28}/>
                                <MaterialCommunityIcons name="blur" color={'#ffffff'} size={28}/>
                                <MaterialCommunityIcons name="cards-diamond-outline" color={'#ffffff'} size={28}/>
                                <MaterialCommunityIcons name="chemical-weapon" color={'#ffffff'} size={28}/>
                                <MaterialCommunityIcons name="decagram" color={'#ffffff'} size={28}/>
                                <MaterialCommunityIcons name="flare" color={'#ffffff'} size={28}/>
                                <MaterialCommunityIcons name="hexagon-slice-6" color={'#ffffff'} size={28}/>
                                <MaterialCommunityIcons name="mine" color={'#ffffff'} size={28}/>
                                <MaterialCommunityIcons name="octahedron" color={'#ffffff'} size={28}/>
                                */ }
                                <Text style={[AppStyles.balances, AppStyles.textShadow]}>{unobtaniumString} {capacityString}</Text>
                            </View>
                        </View>
                    }
                    <View style={[AppStyles.row, AppStyles.rowleft, AppStyles.marginLeft]}>
                        <View style={[AppStyles.row, { alignItems: 'center', marginTop: -15}]}>
                        <Text style={[AppStyles.balances, AppStyles.textShadow]}><MaterialCommunityIcons name="robot" color={'#ffffff'} size={28}/> {robots}</Text>
                        { robots > 0 && storageCapacityStatus < 1 &&
                            <ProgressBar
                                progress={robotProgress}
                                showsText={true}
                                width={70}
                                style={[AppStyles.progress]}
                                color={'#1bffff'}
                                unfilledcolor={'#ffffff'} />
                        }
                        </View>
                    </View>
                    <View style={[AppStyles.marginLeft]}>
                        <Pressable onPress={() => {handleTooltip() }} >
                            <Text style={[AppStyles.balances, AppStyles.textShadow]}>Sales Tax: {salesTaxString}% <MaterialCommunityIcons name="frequently-asked-questions" color={'#ffffff'} size={16}/></Text>
                        </Pressable>
                        <Text style={[AppStyles.balances, AppStyles.textShadow]}>Unobtanium Price: {unobtaniumPriceString}</Text>
                        { boostEpochs > 0 &&
                            <Text style={[AppStyles.balances, AppStyles.textShadow]}>Unobtanium Price Boost Epochs: {boostEpochs}</Text>
                        }
                    </View>
                    <View style={[AppStyles.row, AppStyles.rowleft, AppStyles.marginLeft]}>
                        <Text style={[[AppStyles.balances, AppStyles.textShadow]]}>Epoch: {epochString}</Text>
                    </View>
                </ImageBackground>
            </View>
            <View>
                <Pressable
                    onPress={() => {handleMineUnobtanium() }}
                    disabled={storageCapacityStatus > 1}
                    style={({pressed}) => [
                        pressed ? pressedMineButtonStyle : storageCapacityStatus < 1 ? mineButtonStyle : disabledMineButtonStyle,
                    ]}
                >
                    <Text pointerEvents="box-only" style={AppStyles.buttonText}>Mine Unobtanium</Text>
                </Pressable>
            </View>
            <View style={[AppStyles.row, AppStyles.sellWrapper, {marginVertical: -10}]}>
                { ! autoSellEnabled &&
                    <Pressable
                        onPress={() => {
                            sellProduce()
                        }}
                        disabled={autoSellEnabled || decimalUnobtanium < 1}
                        style={({pressed}) => [
                            pressed ? pressedSellButtonStyle : !autoSellEnabled && decimalUnobtanium ? sellButtonStyle : disabledSellButtonStyle,
                        ]}
                    >
                        <Text style={AppStyles.buttonText}>Sell</Text>
                    </Pressable>
                }
                { autoSellButtonAvailable &&
                    <View style={AppStyles.row}>
                        <Switch
                            trackColor={{false: '#c6c6c6', true: '#c6c6c6'}}
                            thumbColor={autoSellEnabled ? '#c01212' : '#f4f3f4'}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleAutosell}
                            value={autoSellEnabled}
                        /><Text style={[AppStyles.text, AppStyles.white]}>Auto-Sell</Text>
                    </View>
                }
            </View>
            <View style={[ AppStyles.darkBackground, AppStyles.turqoiseBorder]}>
                <View>{ storageCapacityStatus < 1 ?
                    <ProgressBar
                        progress={storageCapacityStatus}
                        showsText={true}
                        width={null}
                        style={[AppStyles.storageProgress]}
                        color={'#1bffff'}
                        unfilledcolor={'#ffffff'}/>
                    : <Text style={ [AppStyles.text, AppStyles.red, AppStyles.smalltext, { marginBottom: 3} ]}>STORAGE  FULL</Text>
                }
                </View>
                <View style={[AppStyles.row]}>
                    <PurchaseItem
                        doPurchase={applyStorageUpgradeEffects}
                        buttonText={upgradeButtonText}
                        buttonEnabled={upgradeButtonEnabled}
                        buyTitle={'Increase Storage Capacity'}
                        buyMessage={upgradeStorageMessage}
                        stardustPurchase={true}
                        euroDollarPrice={storageUpgradePrice}
                    />
                </View>
            </View>
            <View>
                <DailyLoginBonus/>
            </View>
            <View>
                <Pressable
                    onPress={() => props.navigation.navigate('Achievements')}>
                    <Text style={[AppStyles.text, AppStyles.white]}>View Achievements</Text>
                </Pressable>
            </View>
            { isWeb &&
                <View style={AppStyles.row}>
                    <Text style={[AppStyles.smalltext,AppStyles.white]}><A href={privacyPolicyUrl}>Privacy Policy</A> | <A href={imprintUrl}>Imprint</A></Text>
                </View>
            }
        </View>
    );
};