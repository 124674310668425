import React from 'react';
import {View, ScrollView, ImageBackground, useWindowDimensions} from 'react-native';
import {AppStyles} from "../AppStyle";
import RobotController from "../components/RobotController";
import EnergyController from "../components/EnergyController";
import MyModal from "../components/MyModal";
import QuantumComputerController from "../components/QuantumComputerController";
import MoneyHeader from "../components/MoneyHeader";
import EventNotification from "../components/EventNotification";
import {useSelector} from "react-redux";

const AutomationController = () => {
    const { height: windowHeight } = useWindowDimensions();
    const notificationVisible = useSelector( state => state.notificationVisible);
    return (
        <ImageBackground source={require('../assets/bg_circuit.png')} style={AppStyles.backgroundImage}>
            {notificationVisible && <EventNotification/>}
                <View style={[AppStyles.containerNoFlex, { height: windowHeight - 15 }]}>
                    <View style={[{ height: windowHeight - 140 }]}>
                        <MoneyHeader />
                        <View>
                    <ScrollView>
                        <View style={[AppStyles.row, AppStyles.listSectionWrapper, AppStyles.turqoiseBorder]}>
                            <RobotController />
                        </View>
                        <View style={[AppStyles.row, AppStyles.listSectionWrapper, AppStyles.turqoiseBorder]}>
                            <EnergyController />
                        </View>
                        <View style={[AppStyles.row, AppStyles.listSectionWrapper, AppStyles.turqoiseBorder]}>
                            <QuantumComputerController />
                        </View>
                    </ScrollView>
                        </View>
                    </View>
                </View>
            <MyModal />
        </ImageBackground>

    )}


export default AutomationController;