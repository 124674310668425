import * as React from 'react';
import {Alert, Modal, Pressable, Text, View} from 'react-native';
import {useDispatch, useSelector} from "react-redux";
import {AppStyles} from "../AppStyle";
import {calculateStardustPrice, numbroFormatCurrency, numbroFormatInt} from "./helperFunctions";
import * as stateSetters from "../redux/action";
import Decimal from "break_infinity.js";
//import InAppPurchase from "./InAppPurchase";
//import InAppPurchase from "../components/InAppPurchase"; // TODO Enable IAP

const PurchaseItem = ({doPurchase, buyTitle, buyMessage, buttonText, buttonEnabled, stardustPurchase, euroDollarPrice}) => {
    const dispatch = useDispatch();

    const money = useSelector(state => state.money);
    const decimalMoney = new Decimal(money);
    const stardust = useSelector(state => state.stardust);

    const [visible, setVisible] = React.useState(false);

    const showDialog = () => setVisible(true);

    const hideDialog = () => setVisible(false);


    const stardustPrice = calculateStardustPrice(euroDollarPrice);


    async function handleBuyStardust(){

        //dispatch(stateSetters.setModalContent(<InAppPurchase />));
        dispatch(stateSetters.setModalVisible(true));
        // TODO IAP Stardust

        const purchasedStardust = 1000;
        dispatch(stateSetters.setStardust(stardust + purchasedStardust));

    }

    async function handlePurchaseItem(buyWithStardust) {

        let purchaseOK = false;
        if( buyWithStardust ) {
            if( stardust >= stardustPrice ) {
                dispatch(stateSetters.setStardust(stardust - stardustPrice));
                purchaseOK = true;
            } else {
                // Not enough stardust
                handleBuyStardust();
            }
        } else {
            if(decimalMoney >= euroDollarPrice) {
                dispatch(stateSetters.setMoney(decimalMoney.sub(new Decimal(euroDollarPrice))));
                purchaseOK = true;
            } else {
                // Not enough money
                alert(
                    'You do not have enough EuroDollar to complete this purchase!',
                );
            }
        }

        if( purchaseOK ) {
            await doPurchase();
            setVisible(false);
        }

    }
    async function handleDeposit(depositFullAmount) {

        let purchaseOK = false;

        let depositAmount = decimalMoney;

        if( ! depositFullAmount ) {
            const halfAmount = decimalMoney.div(new Decimal(2));
            dispatch(stateSetters.setMoney(decimalMoney.sub(halfAmount)));

            depositAmount = halfAmount;
            purchaseOK = true;
        } else {
            dispatch(stateSetters.setMoney(new Decimal(0)));
            purchaseOK = true;
        }

        if( purchaseOK ) {
            await doPurchase(depositAmount);
            setVisible(false);
        }

    }

    const buyButtonEnabled = money > euroDollarPrice;
    const buyButtonStyle =[AppStyles.button, AppStyles.purchaseButton, AppStyles.greenButton, !buyButtonEnabled && AppStyles.disabledGreenButton ];
    const modalButtonStyle =[AppStyles.button, AppStyles.purchaseButton, AppStyles.blueButton, !buttonEnabled && AppStyles.disabledBlueButton ];
    const pressedButtonStyle = [AppStyles.button, AppStyles.purchaseButton, AppStyles.pressedButton, AppStyles.moveButton];

    const stardustPriceString = numbroFormatInt(stardustPrice);

    const blueButtonStyle = [AppStyles.button, AppStyles.blueButton];
    const pressedBlueButtonStyle = [AppStyles.button, AppStyles.pressedBlueButton, AppStyles.moveButton];
    const greenButtonStyle = [AppStyles.button, AppStyles.greenButton];
    const pressedGreenButtonStyle = [AppStyles.button, AppStyles.pressedGreenButton, AppStyles.moveButton];
    const redButtonStyle = [AppStyles.button, AppStyles.redButton];
    const pressedRedButtonStyle = [AppStyles.button, AppStyles.pressedRedButton, AppStyles.moveButton];
    const stardustButtonStyle = [AppStyles.button, AppStyles.smallButton, AppStyles.stardustButton];
    const pressedStardustButtonStyle = [AppStyles.button, AppStyles.smallButton, AppStyles.pressedStardustButton, AppStyles.moveButton];

    return (
        <View>
            <Pressable
                onPress={() => { showDialog() }}
                disabled={!buttonEnabled}
                style={({pressed}) => [
                    pressed ? pressedButtonStyle : modalButtonStyle,
                ]}
                >
                <Text style={AppStyles.buttonText}>{buttonText}</Text>
            </Pressable>
            { visible &&
                <View style={AppStyles.centeredView}>
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={visible}
                        onRequestClose={() => {
                            dispatch(setVisible(!visible));
                        }}>
                        <View style={AppStyles.centeredView}>
                            <View style={AppStyles.buyItemView}>

                                <Text style={[AppStyles.title, AppStyles.white]}>{buyTitle}</Text>
                                <Text
                                    style={[AppStyles.text, AppStyles.textLeft, AppStyles.smalltext, AppStyles.white]}>{buyMessage}</Text>
                                <View>
                                    { buttonText !== 'Deposit' ?
                                        <View style={AppStyles.row}>
                                            <Pressable
                                                style={buyButtonStyle}
                                                disabled={!buyButtonEnabled}
                                                onPress={() => handlePurchaseItem(false)}>
                                                <Text style={AppStyles.buttonText}>Buy now</Text>
                                            </Pressable>
                                            <Pressable
                                                style={({pressed}) => [
                                                    pressed ? pressedRedButtonStyle : redButtonStyle,
                                                ]}
                                                onPress={() => hideDialog()}>
                                                <Text style={AppStyles.buttonText}>Cancel</Text>
                                            </Pressable>
                                        </View>
                                        :
                                        <View>
                                            <Pressable
                                                style={({pressed}) => [
                                                    pressed ? pressedGreenButtonStyle : greenButtonStyle,
                                                ]}
                                                onPress={() => handleDeposit(true)}>
                                                <Text style={AppStyles.buttonText}>Deposit 100%</Text>
                                            </Pressable>
                                            <Pressable
                                                style={({pressed}) => [
                                                    pressed ? pressedBlueButtonStyle : blueButtonStyle,
                                                ]}
                                                onPress={() => handleDeposit(false)}>
                                                <Text style={AppStyles.buttonText}>Deposit 50%</Text>
                                            </Pressable>
                                            <Pressable
                                                style={({pressed}) => [
                                                    pressed ? pressedRedButtonStyle : redButtonStyle,
                                                ]}
                                                onPress={() => hideDialog()}>
                                                <Text style={AppStyles.buttonText}>Cancel</Text>
                                            </Pressable>
                                        </View>
                                    }
                                </View>
                                {stardustPurchase &&
                                    <View>
                                        <View style={AppStyles.row}>
                                            <Pressable
                                                style={({pressed}) => [
                                                    pressed ? pressedStardustButtonStyle : stardustButtonStyle,
                                                ]}
                                                onPress={() => handlePurchaseItem(true)}>
                                                <Text style={[AppStyles.buttonText, AppStyles.smalltext]}>Buy
                                                    with {stardustPriceString} Stardust</Text>
                                            </Pressable>
                                        </View>
                                        <View style={[AppStyles.row, AppStyles.textLeft, ]}>
                                            <Text style={[AppStyles.smalltext, AppStyles.textLeft, AppStyles.white]}>Stardust Balance: {stardust}</Text>
                                        </View>
                                    </View>
                                }
                            </View>
                        </View>
                    </Modal>
                </View>
            }
        </View>
    );
};


export default PurchaseItem;