import { Audio } from 'expo-av';
import {Platform} from "react-native";
import { throttle } from 'lodash';

const soundList = {
    cashier: require('../assets/sounds/cashier.mp3'),
    thud: require('../assets/sounds/thud.mp3'),
    achievement: require('../assets/sounds/achievement.mp3'),
    level_up: require('../assets/sounds/level_up.mp3'),
    switch_on: require('../assets/sounds/switch_on.mp3'),
    switch_off: require('../assets/sounds/switch_off.mp3'),
    event: require('../assets/sounds/event.mp3'),
    project_completed: require('../assets/sounds/project_completed.mp3'),
    purchased_robot: require('../assets/sounds/purchased_robot.mp3'),
    robot_upgrade: require('../assets/sounds/robot_upgrade.mp3'),
    trading_engine_upgrade: require('../assets/sounds/trading_engine_upgrade.mp3'),
    ops: require('../assets/sounds/ops.mp3'),
    phone_ringing: require('../assets/sounds/phone_ringing.mp3'),
    he_bought: require('../assets/sounds/he_bought.mp3'),
    gas_release: require('../assets/sounds/gas_release.mp3'),
    correct: require('../assets/sounds/correct.mp3'),
    // add more sounds here...
};

const loadedSounds = {};

async function loadSounds() {
    for (const [key, value] of Object.entries(soundList)) {
        const { sound } = await Audio.Sound.createAsync(value);
        loadedSounds[key] = { sound, loaded: true };
    }
}

loadSounds();

const throttleReplay = throttle(async (loadedSound) => {
    await loadedSound.sound.replayAsync().catch((error) => {
        // Handle any errors that occur
        console.error(error);
    });
}, 300);

export async function playSound(soundKey, soundEnabled) {
    if( soundEnabled ) {

        const loadedSound = loadedSounds[soundKey];

        if (Platform.OS === 'ios') {
            const source = { uri: loadedSounds[soundKey]._uri };
            const { sound } = await Audio.Sound.createAsync(source, { shouldPlay: true });
            await sound.playAsync().catch((error) => {
                // Handle any errors that occur
                console.error(error);
            });
        } else {

            if (loadedSound.loaded) {
                await throttleReplay(loadedSound);
            } else {
                console.warn(`No sound loaded for key "${soundKey}"`);

                loadedSound.sound.setOnPlaybackStatusUpdate(status => {
                    if (status.isLoaded && status.isPlaying) {
                        loadedSound.loaded = true;
                    }
                });
            }

        }
    }
}

export async function unloadSounds() {
    for (const sound of Object.values(loadedSounds)) {
        sound.unloadAsync();
    }
}
