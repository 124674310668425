import React from 'react';
import { View, Text } from 'react-native';
import { Table, Row } from 'react-native-table-component';
import {useSelector} from "react-redux";
import {AppStyles} from "../AppStyle";
import {isWeb} from "@notifee/react-native/dist/utils";

let tableDataDefaults = {
    tableHead: ['Asset','Amount','Price','Total','Profit'],
    widthArr: [90, 55, 55, 90, 100 ],
    tableData: [
        ['Bitcoin', 'BTC', '$44,331', '$2.70', '$839,702,328,904'],
        ['Ethereum', 'ETH', '$3000.9', '$3.49', '$359,080,563,225']
    ]
};

if( isWeb ) {
    tableDataDefaults.widthArr = [160, 145, 145, 160, 175 ];
}

const HedgeFundTable = () => {

    const stockObjectArray = useSelector( state => state.tradingTableData);

    // Convert array of objects to array
    const tableData = stockObjectArray.map(record=>([record.symbol, record.amount, record.price, record.total, record.profit ]));
    
    return (
        <View style={AppStyles.tableContainer}>
                <View>
                    <Table borderStyle={AppStyles.turqoiseBorder}>
                        <Row
                            data={tableDataDefaults.tableHead}
                            widthArr={tableDataDefaults.widthArr}
                            style={AppStyles.tableHead}
                            textStyle={AppStyles.tableHeadText}
                        />
                    </Table>
                    {
                        tableData.length ?

                            <Table borderStyle={AppStyles.greyBorder}>
                                {tableData.map((rowData, index) => (
                                    <Row
                                        key={index}
                                        data={rowData}
                                        widthArr={tableDataDefaults.widthArr}
                                        style={AppStyles.tableRowSection}
                                        textStyle={rowData[4].includes('-') ? AppStyles.tableRowTextLoss : AppStyles.tableRowTextProfit}
                                    />
                                ))}
                            </Table>
                        :
                        <Text style={[AppStyles.text, AppStyles.white]}>No data</Text>
                    }
                </View>
        </View>
    );
}
export default HedgeFundTable;