import React from "react";
import {
    Text,
    View,
    Image,
    Pressable,
    FlatList,
    ImageBackground,
    useWindowDimensions,
    Platform
} from 'react-native';
import ProgressBar from 'react-native-progress/Bar';
import {useDispatch, useSelector} from "react-redux";
import * as stateSetters from '../redux/action';

import {AppStyles} from "../AppStyle";
import MyModal from "../components/MyModal";
import getProjects from "../constants/Projects";
import {
    numbroFormatInt,
    cloneObject,
    isProjectCompleted,
    numbroFormatCurrency, calculateNewQuantumComputerPrice, calculateStardustPrice, calculateOPSProduction
} from "../components/helperFunctions";
import {playSound} from "../constants/AppSounds";
import PurchaseItem from "../components/PurchaseItem";
import Decimal from "break_infinity.js";
import EventNotification from "../components/EventNotification";

function Project(props) {
    const dispatch = useDispatch();

    const timePassed = useSelector(state => state.timePassed);
    const researchSpeed = useSelector(state => state.researchSpeed);

    let projectTimeElapsed = timePassed - props.activeProject.timestampStart;
    let timeLeft = Math.ceil((researchSpeed - projectTimeElapsed) / 1000);
    let projectProgress = 1 - (timeLeft / (researchSpeed / 1000));

    function handleProjectStart(projectID) {

        let currentProject = props.projects.find(o => o.id === projectID);

        if (props.standardOps >= currentProject.cost) {

            dispatch(stateSetters.setActiveProject({
                id: projectID,
                timestampStart: props.timePassed,
                title: currentProject.title,
                completed: false
            }));
            dispatch(stateSetters.setStandardOps(props.standardOps - currentProject.cost));
        } else {
            dispatch(stateSetters.setModalContent('Not enough OPS!'));
            dispatch(stateSetters.setModalVisible(true));
        }
    }



    let image = require('../assets/projects/default.png');
    if (props.projectData.image) {
        image = props.projectData.image;
    }

    let buttonStyle = [AppStyles.button, AppStyles.smallButton, AppStyles.greyButton];
    let buttonStylePressed = [AppStyles.button, AppStyles.smallButton, AppStyles.greyButton, AppStyles.moveButton];
    let buttonStyleDisabled = [AppStyles.button, AppStyles.smallButton,AppStyles.darkGreyButton];

    const notEnoughOPSString = "Not enough OPS: " + numbroFormatInt(props.standardOps) + '/' + numbroFormatInt(props.projectData.cost);
    let buttonText = props.standardOps < props.projectData.cost ? notEnoughOPSString : "Activate";

    let buttonDisabled  = props.activeProject.title.length > 0 || props.standardOps < props.projectData.cost;
    if( props.activeProject.title.length > 0 ) {
        buttonText = timeLeft;
    }

    const showProgressBar = props.activeProject.id === props.projectData.id && !props.activeProject.completed && timeLeft >= 0;
    return (
        <View style={[AppStyles.projectWrapper, AppStyles.turqoiseBorder]}>
            <View>
                <Text style={[AppStyles.title, AppStyles.projectTitle, AppStyles.yellow]}>{props.projectData.title}</Text>
            </View>
            <View style={[AppStyles.row, AppStyles.centeredView]}>
                <Image source={image} style={[AppStyles.turqoiseBorder, AppStyles.projectImage]}/>
                <View style={[{maxWidth: '70%'}]}>
                    <ImageBackground source={require('../assets/interface/list_frame_rect_filled.png')} style={[AppStyles.projectListFrameBGImage, AppStyles.paddingLeft]}>
                        <Text style={[AppStyles.smallPadding, AppStyles.mediumtext, AppStyles.white]}>{props.projectData.description}</Text>
                    </ImageBackground>
                </View>
            </View>
            { showProgressBar ?
                <View>
                    <ProgressBar
                        progress={1- projectProgress}
                        showsText={true}
                        width={null}
                        color={'#1bffff'}
                        borderColor={'#1bffff'}
                        style={AppStyles.progress}
                        unfilledcolor={'#1bffff'}
                    />
                </View> :
                <View style={[AppStyles.row, AppStyles.nomargin]}>
                    <Text style={[AppStyles.text, AppStyles.projectCost, AppStyles.smalltext]}>{props.projectData.priceTag}</Text>
                </View>
            }

            <Pressable
                disabled={buttonDisabled}
                onPress={() => {handleProjectStart(props.projectData.id)}}
                style={({pressed}) => [
                    pressed ? buttonStylePressed : buttonDisabled ? buttonStyleDisabled : buttonStyle,
                ]}>
                <Text style={[AppStyles.buttonText, !buttonDisabled && AppStyles.white]}>{buttonText}</Text>
            </Pressable>
        </View>
    );
}

export default function ProjectList(props) {

    const dispatch = useDispatch();

    const standardOps = useSelector(state => state.standardOps);
    const opsProgress = useSelector( state => state.opsProgress);

    const timePassed = useSelector(state => state.timePassed);
    const money = useSelector(state => state.money);
    const decimalMoney = new Decimal(money);
    const robotMiningPerInterval = useSelector(state => state.robotMiningPerInterval);
    const rareUnobtaniumChance = useSelector(state => state.rareUnobtaniumChance);
    const solarBoost = useSelector(state => state.solarBoost);
    const unobtaniumAmountBoost = useSelector(state => state.unobtaniumAmountBoost);
    const unobtaniumPriceBoost = useSelector(state => state.unobtaniumPriceBoost);
    const trustThreshold = useSelector(state => state.trustThreshold);


    const completedProjects = useSelector(state => state.completedProjects);
    const activeProject = useSelector(state => state.activeProject);
    const epoch = useSelector(state => state.epoch);
    const researchSpeed = useSelector(state => state.researchSpeed);

    const soundEnabled = useSelector(state => state.soundEnabled);
    const quantumComputersActive = useSelector(state => state.quantumComputersActive);
    const quantumComputerPrice = useSelector(state => state.quantumComputerPrice);
    const opsWaitSeconds = useSelector(state => state.opsWaitSeconds);

    let projects = getProjects(
        dispatch, timePassed/1000, decimalMoney, standardOps, robotMiningPerInterval,
        rareUnobtaniumChance, solarBoost, unobtaniumAmountBoost,
        unobtaniumPriceBoost, trustThreshold, quantumComputersActive);

    const standardOpsString = numbroFormatInt(standardOps);
    let projectTimeElapsed = timePassed - activeProject.timestampStart;
    let timeLeft = Math.ceil((researchSpeed - projectTimeElapsed) / 1000);

    const opsProductionEnabled = isProjectCompleted('projectButton8', completedProjects) || quantumComputersActive > 0;

    const currentProduction = calculateOPSProduction(quantumComputersActive) ;
    const currentProductionPerSec = (currentProduction / opsWaitSeconds).toFixed(2);
    const { height: windowHeight } = useWindowDimensions();

    const notificationVisible = useSelector( state => state.notificationVisible);

    return (
        <ImageBackground source={require('../assets/bg_circuit.png')} style={AppStyles.backgroundImage}>
        <View style={AppStyles.container} >
            {notificationVisible && <EventNotification/>}
            <View style={AppStyles.projectComponentWrapper}>
                <View>
                    <View style={[AppStyles.opsScreenWrapper, AppStyles.balanceWrapper]}>
                        <ImageBackground source={require('../assets/interface/screen.png')} style={[AppStyles.smallComponentBGImage]}>
                            <Text style={[AppStyles.white, AppStyles.ops, AppStyles.textShadow, Platform.OS === 'web' && { marginLeft: '15%' }]}>{standardOpsString} OPS</Text>
                            { opsProductionEnabled &&
                                <View>
                                    <ProgressBar
                                        progress={opsProgress}
                                        showsText={true}
                                        width={null}
                                        color={'white'}
                                        borderColor={'white'}
                                        style={AppStyles.opsProgress}
                                        unfilledcolor={'white'}
                                    />
                                    <Text style={[AppStyles.white, AppStyles.ops, AppStyles.textShadow, AppStyles.smalltext]}>{currentProductionPerSec}/sec</Text>
                                </View>
                            }
                        </ImageBackground>
                    </View>
                </View>
                <View style={AppStyles.projectListScrollview}>
                    <FlatList
                        style={{ height: windowHeight - 313 }}
                        data={projects}
                        renderItem={({item, index}) => {
                            return (
                                !isProjectCompleted(item.id, completedProjects) &&
                                item.trigger() ? (
                                    <Project
                                        key={item.id} projectData={item}
                                        standardOps={standardOps}
                                        activeProject={activeProject}
                                        timePassed={timePassed}
                                        completedProjects={completedProjects}
                                        projects={projects}

                                        displayMessage={props.displayMessage}
                                        modalVisible={props.modalVisible}
                                        setModalVisible={props.setModalVisible}
                                        modalContent={props.modalContent}
                                        setModalContent={props.setModalContent}
                                    />
                                ) : null
                            );
                        }}

                        keyExtractor={item => item.id}
                    />
                </View>

            </View>
            <MyModal/>
        </View>
        </ImageBackground>
    );
}
