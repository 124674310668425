import React, { useState, useEffect } from 'react';
import {Animated, View, Text, StyleSheet, Image} from 'react-native';
import {useDispatch, useSelector} from "react-redux";
import {AppStyles} from "../AppStyle";
import * as stateSetters from "../redux/action";
import {setNotificationVisible} from "../redux/action";

const EventNotification = () => {
    const [fadeAnim] = useState(new Animated.Value(0));

    const dispatch = useDispatch();

    const notificationVisible = useSelector( state => state.notificationVisible);
    const notificationContent = useSelector( state => state.notificationContent);

    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
        }).start();

        const timeout = setTimeout(() => {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 2000,
                useNativeDriver: true,
            }).start(({ finished }) => {
                if (finished) {
                    // Animation finished, reset the showNotification state variable
                    dispatch(setNotificationVisible(false));
                }
            });
        }, 5000);
        return () => clearTimeout(timeout);
    }, [fadeAnim]);

        return (
            <Animated.View style={[styles.container, {opacity: fadeAnim}]}>
                <View style={AppStyles.row}>
                {notificationContent.image &&
                    <Image style={styles.imageStyle} source={notificationContent.image}/>
                }
                <View style={[{maxWidth: '80%'},AppStyles.smallMarginLeft]}>
                    { notificationContent.title.length > 0 &&
                        <Text style={[AppStyles.text, AppStyles.nomargin, AppStyles.textLeft]}>{notificationContent.title}</Text>
                    }
                    <Text style={[AppStyles.text, AppStyles.smalltext, AppStyles.textLeft]}>{notificationContent.text}</Text>
                </View>
                </View>
            </Animated.View>
        );

};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 40,
        left: 0,
        right: 0,
        zIndex: 1000,
        paddingVertical: 16,
        paddingHorizontal: 24,
        marginHorizontal: 10,
        backgroundColor: '#f2f2f2',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        justifyContent: 'center',
        alignItems: 'flex-start',
        elevation: 4,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3,
        maxWidth: 500
    },
    imageStyle: {
        height: 45,
        width: 45,
        paddingHorizontal: 20
    }
});

export default EventNotification;