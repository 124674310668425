import React from 'react';
import {View, ScrollView, ImageBackground, useWindowDimensions, Text, Platform} from 'react-native';
import {AppStyles} from "../AppStyle";
import {useSelector} from "react-redux";
import {numbroFormatInt} from "./helperFunctions";
import Decimal from "break_infinity.js";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

const MoneyHeader = () => {
    const {height: windowHeight} = useWindowDimensions();

    const money = useSelector(state => state.money);
    const moneyString = numbroFormatInt(new Decimal(money));
    return (
        <View>
            <View style={[AppStyles.moneyHeaderWrapper, AppStyles.darkBackground]}>
                <ImageBackground source={require('../assets/interface/money_header.png')} style={[AppStyles.moneyHeaderBGImage]}>
                    <Text style={[AppStyles.white, AppStyles.moneyHeaderString, AppStyles.textShadow]}><MaterialCommunityIcons name="cash" color={'#1bffff'} size={28}/> {moneyString}</Text>
                </ImageBackground>
            </View>
        </View>
    )
}


export default MoneyHeader;