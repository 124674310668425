import React from 'react';
import {Modal, Text, Pressable, View, Image, ImageBackground} from 'react-native';
import {useDispatch, useSelector} from "react-redux";
import {setModalVisible, setModalContent} from "../redux/action";
import {AppStyles} from "../AppStyle";

const MyModal = () => {
    const dispatch = useDispatch();

    const modalVisible = useSelector( state => state.modalVisible);
    const modalImageSrc = useSelector( state => state.modalImageSrc);
    const modalContent = useSelector( state => state.modalContent);

    function handleModalClose() {
        dispatch(setModalVisible(!modalVisible));
        dispatch(setModalContent(''));
    }
    const blueButtonStyle = [AppStyles.button, AppStyles.blueButton];
    const pressedBlueButtonStyle = [AppStyles.button, AppStyles.pressedBlueButton, AppStyles.moveButton];

    return (
        <View style={[AppStyles.centeredView,AppStyles.darkBackground]}>
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                    handleModalClose();
                }}>
                    <View style={AppStyles.centeredView}>
                        <View style={AppStyles.modalView}>

                            { modalContent.image &&
                                <Image source={modalContent.image} style={AppStyles.modalImageSrc}/>
                            }
                            { typeof modalContent.title === 'undefined' ?
                                <Text style={[AppStyles.text, AppStyles.white]}>{modalContent}</Text>
                                :
                                <View>
                                    <Text style={[AppStyles.title, AppStyles.white]}>{modalContent.title}</Text>
                                    <Text style={[AppStyles.smalltext, AppStyles.white]}>{modalContent.text}</Text>
                                </View>
                        }
                            <Pressable
                                style={({pressed}) => [
                                    pressed ? pressedBlueButtonStyle : blueButtonStyle,
                                ]}
                                onPress={() => handleModalClose()}>
                                <Text style={AppStyles.buttonText}>OK</Text>
                            </Pressable>
                        </View>
                    </View>
            </Modal>
        </View>
    );
};


export default MyModal;