import React from 'react';
import {Text, View, Image, Pressable, ImageBackground, Alert} from 'react-native';
import ProgressBar from 'react-native-progress/Bar';
import {AppStyles} from "../AppStyle";
import {useDispatch, useSelector} from "react-redux";
import * as stateSetters from '../redux/action';
// lodash
import { memoize } from 'lodash';
import {
    numbroFormatInt,
    numbroFormatCurrency,
    calculateNewRobotPrice,
    calculateNewRobotEfficiency,
    calculateRobotUpgradePrice,
    calculateStorageUpgradePrice,
    calculateStorageCapacity,
    calculateRobotProduction, calculateSolarPanelProduction, calculateEnergyUsage, calculateEnergyUsageRatio
} from "./helperFunctions";
import MyModal from "./MyModal";
import PurchaseItem from "./PurchaseItem";
import { playSound } from '../constants/AppSounds';
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

const RobotController = () => {


    const dispatch= useDispatch();

    // Memoize

    const getUnobtanium = memoize(state => state.unobtanium);
    const getStorageCapacityStatus = memoize(state => state.storageCapacityStatus);

    const getRobotLevel = memoize(state => state.robotLevel);

    const getRobotInterval = memoize(state => state.robotInterval);
    const getRobotMiningPerInterval = memoize(state => state.robotMiningPerInterval);
    const getSolarBoost = memoize(state => state.solarBoost);
    const getUnobtaniumAmountBoost = memoize(state => state.unobtaniumAmountBoost);
    const getProductionBoost = memoize(state => state.productionBoost);
    const getDroneEfficiency = memoize(state => state.droneEfficiency);

    const getRobotEfficiency = memoize(state => state.robotEfficiency);
    const getRobotProgress = memoize(state => state.robotProgress);

    const getRobotPrice = memoize(state => state.robotPrice);
    const getRobotUpgradePrice = memoize(state => state.robotUpgradePrice);

    const getRobotButtonEnabled = memoize(state => state.robotButtonEnabled);
    const getRobotUpgradeButtonEnabled = memoize(state => state.robotUpgradeButtonEnabled);

    const getRobots = memoize(state => state.robots);

    const getSoundEnabled = memoize(state => state.soundEnabled);

    const getRobotsPartsFactoryActive = memoize(state => state.robotsPartsFactoryActive);

    const getQuantumComputersActive = memoize(state => state.quantumComputersActive);
    const getSolarpanelsPartsFactoryActive = memoize(state => state.solarpanelsPartsFactoryActive);
    const getSpaceElevatorActive = memoize(state => state.spaceElevatorActive);
    const getSolarpanels = memoize(state => state.solarpanels);
    const getSolarpanelEfficiency = memoize(state => state.solarpanelEfficiency);

    // Usage

    const unobtanium = useSelector(getUnobtanium);
    const storageCapacityStatus = useSelector(getStorageCapacityStatus);

    const robotLevel = useSelector(getRobotLevel);

    const robotInterval = useSelector(getRobotInterval);
    const robotMiningPerInterval = useSelector(getRobotMiningPerInterval);
    const solarBoost = useSelector(getSolarBoost);
    const unobtaniumAmountBoost = useSelector(getUnobtaniumAmountBoost);
    const productionBoost = useSelector(getProductionBoost);
    const droneEfficiency = useSelector(getDroneEfficiency);

    const robotEfficiency = useSelector(getRobotEfficiency);
    const robotProgress = useSelector(getRobotProgress);

    const robotPrice = useSelector(getRobotPrice);
    const robotUpgradePrice = useSelector(getRobotUpgradePrice);

    const robotButtonEnabled = useSelector(getRobotButtonEnabled);
    const robotUpgradeButtonEnabled = useSelector(getRobotUpgradeButtonEnabled);

    const robots = useSelector(getRobots);

    const soundEnabled = useSelector(getSoundEnabled);

    const robotsPartsFactoryActive = useSelector(getRobotsPartsFactoryActive);

    const quantumComputersActive = useSelector(getQuantumComputersActive);
    const solarpanelsPartsFactoryActive = useSelector(getSolarpanelsPartsFactoryActive);
    const spaceElevatorActive = useSelector(getSpaceElevatorActive);
    const solarpanels = useSelector(getSolarpanels);
    const solarpanelEfficiency = useSelector(getSolarpanelEfficiency);


    const solarEnergyGeneration = calculateSolarPanelProduction(solarpanels, solarpanelEfficiency, solarBoost);
    const energyUsage = calculateEnergyUsage(robots, robotLevel, quantumComputersActive, robotsPartsFactoryActive, solarpanelsPartsFactoryActive, spaceElevatorActive, solarBoost);
    const energyUsageValues = calculateEnergyUsageRatio(solarEnergyGeneration, energyUsage);
    const decreasePercentString = energyUsageValues.decreasePercent.toFixed(2);


    const robotProduction = calculateRobotProduction(robots, robotMiningPerInterval, robotEfficiency, droneEfficiency, unobtaniumAmountBoost, productionBoost);
    const newProduction = calculateRobotProduction(robots + 1, robotMiningPerInterval, robotEfficiency, droneEfficiency, unobtaniumAmountBoost, productionBoost);
    const productionIncrease = ((newProduction - robotProduction) / robotInterval).toFixed(2);

    const energyNextRobot = calculateEnergyUsage(robots+1, robotLevel, quantumComputersActive, robotsPartsFactoryActive, solarpanelsPartsFactoryActive, spaceElevatorActive, solarBoost)
    const energyDiffNextRobot = (energyNextRobot - energyUsage).toFixed(2);

    async function applyBuyRobotEffects() {
        if( robots === 0) {
            dispatch(stateSetters.setRobotProgress(0.00));
        }
        const newRobots = robots + 1;
        dispatch(stateSetters.setRobots(newRobots));
        let newPrice = calculateNewRobotPrice(robotPrice, robots, robotsPartsFactoryActive);
        dispatch(stateSetters.setRobotPrice(newPrice));
        await playSound('purchased_robot', soundEnabled);
    }

    let buyMessage = 'Cost: '+numbroFormatCurrency(robotPrice)+'\nEnergy Usage: +'+energyDiffNextRobot+' MW\nMining: +'+productionIncrease+' Unobtanium per second';
    if( robots < 1 ) {
        buyMessage = 'Pay '+numbroFormatCurrency(robotPrice)+' to buy a robot and automatically mine '+numbroFormatInt(productionIncrease)+' Unobtanium per second?';
    }


    // Increase robot level
    const newRobotLevel = robotLevel + 1;

    const newEfficiencyAfterUpgrade = calculateNewRobotEfficiency(newRobotLevel);

    const newProductionAfterUpgrade = calculateRobotProduction(robots, robotMiningPerInterval, newEfficiencyAfterUpgrade, droneEfficiency, unobtaniumAmountBoost, productionBoost);

    const productionIncreaseAfterUpgrade = (newProductionAfterUpgrade - robotProduction);

    const energyNextRobotUpgrade = calculateEnergyUsage(robots, newRobotLevel, quantumComputersActive, robotsPartsFactoryActive, solarpanelsPartsFactoryActive, spaceElevatorActive, solarBoost)
    const energyDiffRobotUpgrade = (energyNextRobotUpgrade - energyUsage).toFixed(2);

    async function applyRobotUpgradeEffects() {

        dispatch(stateSetters.setRobotLevel(newRobotLevel));

        // Calculate new robot Efficiency
        dispatch(stateSetters.setRobotEfficiency(newEfficiencyAfterUpgrade));

        // Calculate new upgrade price
        let newUpgradePrice = calculateRobotUpgradePrice(robotUpgradePrice, robots);

        dispatch(stateSetters.setRobotUpgradePrice(newUpgradePrice));
        await playSound('robot_upgrade', soundEnabled);
    }

    let increaseFloat = (productionIncreaseAfterUpgrade / robotInterval).toFixed(2);

    const upgradeMessage = 'Level: '+newRobotLevel+'\nCost: '+numbroFormatCurrency(robotUpgradePrice)+'\nEnergy Usage: +'+energyDiffRobotUpgrade+' MW\nMining: + '+increaseFloat+' Unobtanium per second';

    function prepareRobotEfficiencyString() {
        let robotEfficiencyPercentage = (robotEfficiency - 1) * 100;
        if( robotEfficiency === 1 ) {
            robotEfficiencyPercentage = 100;
        }
        if( energyUsageValues.decreasePercent > 0 ) {
            robotEfficiencyPercentage = robotEfficiencyPercentage + (100-energyUsageValues.decreasePercent);
        }
        let robotEfficiencyPercentageString = robotEfficiencyPercentage.toFixed(2);
        if( robotEfficiencyPercentage > 100) {
            robotEfficiencyPercentageString = '+'+robotEfficiencyPercentage.toFixed(2);
        } else if(robotEfficiencyPercentage < 0) {
            robotEfficiencyPercentageString = '0';
        }

        return robotEfficiencyPercentageString;
    }

    function getMiningRobotImage() {
        let image;
        switch(robotLevel) {
            case 1:
                image = require('../assets/mining_robot_1.png');
                break;
            case 2:
                image = require('../assets/mining_robot_2.png');
                break;
            case 3:
                image = require('../assets/mining_robot_3.png');
                break;
            case 4:
                image = require('../assets/mining_robot_4.png');
                break;
            case 5:
                image = require('../assets/mining_robot_5.png');
                break;
            case 6:
                image = require('../assets/mining_robot_6.png');
                break;
            case 7:
                image = require('../assets/mining_robot_7.png');
                break;
            case 8:
                image = require('../assets/mining_robot_8.png');
                break;
            case 9:
                image = require('../assets/mining_robot_9.png');
                break;
            case 10:
                image = require('../assets/mining_robot_10.png');
                break;
            case 11:
                image = require('../assets/mining_robot_11.png');
                break;
            case 12:
                image = require('../assets/mining_robot_12.png');
                break;
            default:
            // code block
             image = require('../assets/mining_robot_12.png');
        }
         // TODO Create more images for robots beyond level 12

        return image;

    }

    let robotProductionString = (robotProduction / robotInterval).toFixed(2);
    if( energyUsageValues.decreasePercent > 0 ) {
        let decreasedProduction = (robotProduction * (1 - energyUsageValues.decreasePercentDecimal) / robotInterval);
        robotProductionString = decreasedProduction.toFixed(2);
        if( decreasedProduction < 0 ) {
            robotProductionString = 0;
        }
    }

    function getRobotStatus() {
        let status = 'Waiting';
        if( storageCapacityStatus < 1 ) {
            if (robotProgress && robotProgress < 0.2) {
                status = 'Traveling';
            }
            if (robotProgress > 0.2) {
                status = 'Mining';
            }
            if (robotProgress > 0.6) {
                status = 'Transporting';
            }
            if (robotProgress > 0.8) {
                status = 'Unloading';
            }
        } else {
            status = 'Storage full!';
        }
        if(  energyUsageValues.decreasePercent >= 90 || robotProduction <= 0 ) {
            status = 'Stopped!';
        }
        return status;
    }

    function handleTooltip() {
        dispatch(stateSetters.setModalContent(
            {
                title: 'Robots',
                text: 'Robots automatically mine Unobtanium and transport it to your storage where you can sell it. Purchase more robots and upgrade them to increase your production.'

            }
        ));
        dispatch(stateSetters.setModalVisible(true));
    }

    return (
            <View style={[AppStyles.robotControl]}>
                <View>
                    <Pressable onPress={() => {handleTooltip() }} >
                        <Text style={[AppStyles.title, AppStyles.white]}>Robots <MaterialCommunityIcons name="frequently-asked-questions" color={'#ffffff'} size={16}/></Text>
                    </Pressable>
                    <View style={[AppStyles.darkBackground, {minWidth: '100%'}]}>
                    <Text style={[AppStyles.text, AppStyles.turqoise]}>Unobtanium in storage: {numbroFormatInt(unobtanium)}</Text>
                    </View>
                    { robots > 0 && energyUsageValues.decreasePercent < 100 &&
                            <ProgressBar
                                progress={robotProgress}
                                showsText={true}
                                width={null}
                                style={[AppStyles.progress]}
                                color={'#1bffff'}
                                unfilledcolor={'#1bffff'} />
                    }
                </View>
                <View style={[AppStyles.robotPurchaseWrapper, AppStyles.row]}>
                    <View style={[AppStyles.achievementBadgeImageWrap, AppStyles.turqoiseBorder]}>
                        <ImageBackground source={getMiningRobotImage()}
                                         style={[AppStyles.robotImage]}>
                            { robotLevel > 1 &&
                                <Text style={[AppStyles.white, AppStyles.levelNumber, AppStyles.textShadow]}>
                                    Level {numbroFormatInt(robotLevel, 100)}
                                </Text>
                            }
                        </ImageBackground>
                    </View>
                    <View style={[AppStyles.balances]}>
                        <ImageBackground source={require('../assets/interface/screen.png')} style={[AppStyles.robotStatsBGImage, AppStyles.paddingLeft]}>
                        { robots > 0 &&
                            <Text style={[AppStyles.paddingLeft, AppStyles.mediumMarginLeft, AppStyles.white]}>Status: <Text style={storageCapacityStatus >= 1 || energyUsageValues.decreasePercent >= 90 ? AppStyles.red : AppStyles.white}>{getRobotStatus()}</Text></Text>
                        }
                        <Text style={[AppStyles.paddingLeft, AppStyles.mediumMarginLeft, AppStyles.white]}>Robots: {robots}</Text>
                        <Text style={[AppStyles.paddingLeft, AppStyles.mediumMarginLeft, AppStyles.white]}>Mining: {robotProductionString}/sec.</Text>
                        <Text style={[AppStyles.paddingLeft, AppStyles.mediumMarginLeft, AppStyles.white]}>Efficiency: {prepareRobotEfficiencyString()}% {
                            energyUsageValues.decreasePercent > 0 &&
                            <Text style={[AppStyles.red, AppStyles.smalltext]}>(<MaterialCommunityIcons name="flash" color={'red'} size={12}/>{decreasePercentString}%)</Text>
                        }</Text>
                        </ImageBackground>
                    </View>
                </View>
                <View style={[AppStyles.row]}>
                    <PurchaseItem
                            doPurchase={applyBuyRobotEffects}
                            buttonText={energyNextRobot > solarEnergyGeneration ? 'Not enough energy' : "Buy Robot (" + numbroFormatCurrency(robotPrice) + ")"}
                            buttonEnabled={energyNextRobot < solarEnergyGeneration}
                            buyTitle={'Buy Robot'}
                            buyMessage={buyMessage}
                            stardustPurchase={true}
                            euroDollarPrice={robotPrice}
                    />

                { robots > 0 &&
                    <View>
                        <PurchaseItem
                            doPurchase={applyRobotUpgradeEffects}
                            buttonText={energyNextRobotUpgrade > solarEnergyGeneration ? 'Not enough energy' : "Upgrade Robots ("+numbroFormatCurrency(robotUpgradePrice)+")"}
                            buttonEnabled={energyNextRobotUpgrade < solarEnergyGeneration}
                            buyTitle={'Upgrade Robots'}
                            buyMessage={upgradeMessage}
                            stardustPurchase={true}
                            euroDollarPrice={robotUpgradePrice}
                        />
                    </View>
                }
                </View>
            </View>
    );
};

export default RobotController;