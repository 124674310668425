import * as stateSetters from '../redux/action';
import Decimal from "break_infinity.js";

//
// MovieList
//

export default function Achievements(
    dispatch, robotMiningPerInterval, unobtaniumPrice, trust,
    standardOps, completedProjects, completedAchievements,
    decimalTotalUnobtanium, decimalMoney, robots, robotLevel, quantumComputersActive
) {

    let achievements_array = [];

    // MovieList
    const achievement1Image = require('../assets/achievements/achievement1.png');
    let achievement1 = {
        id: "achievement1",
        title: "Project Manager",
        description: "Complete 1 project.",
        category: 'projects',
        trigger: function () {
            return achievement1.flag === 0 && completedProjects.completed.length >= 1
        },
        flag: completedAchievements.completed.includes('achievement1') ? 1 : 0,
        element: null,
        bonusAmount: 3000,
        bonusType: 'OPS',
        image: achievement1Image,
        effect: function () {
            achievement1.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
                { 
                    title: 'Achievement Unlocked', 
                    
					text:'Congratulations! You earned the "Project Manager" badge and receive 3,000 OPS!', 
                    image:  achievement1Image
                })
            );
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setStandardOps(standardOps + achievement1.bonusAmount));

        }
    }
    achievements_array.push(achievement1);

    const achievement2Image = require('../assets/achievements/achievement2.png');
    let achievement2 = {
        id: "achievement2",
        title: "Assistant Project Manager",
        description: "Complete 4 projects.",
        category: 'projects',
        trigger: function () {
            return achievement2.flag === 0 && completedProjects.completed.length >= 4
        },
        flag: completedAchievements.completed.includes('achievement2') ? 1 : 0,
        element: null,
        bonusAmount: 5000,
        bonusType: 'OPS',
        image: achievement2Image,
        effect: function () {
            achievement2.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
                {
                    title: 'Achievement Unlocked', 
                    
					text:'Congratulations! You earned the "Assistant Project Manager" badge and receive 5,000 OPS!', 
                    image:  achievement2Image
                })
            );
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setStandardOps(standardOps + achievement2.bonusAmount));

        }
    }
    achievements_array.push(achievement2);

    const achievement3Image = require('../assets/achievements/achievement3.png');
    let achievement3 = {
        id: "achievement3",
        title: "Senior Project Manager",
        description: "Complete 6 projects.",
        category: 'projects',
        trigger: function () {
            return achievement3.flag === 0 && completedProjects.completed.length >= 6
        },
        flag: completedAchievements.completed.includes('achievement3') ? 1 : 0,
        element: null,
        bonusAmount: 8000,
        bonusType: 'OPS',
        image: achievement3Image,
        effect: function () {
            achievement3.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
                {
                    title: 'Achievement Unlocked', 
                    
					text:'Congratulations! You earned the "Senior Project Manager" badge and receive 8,000 OPS!', 
                    image:  achievement3Image
                })
            );
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setStandardOps(standardOps + achievement3.bonusAmount));

        }
    }
    achievements_array.push(achievement3);

    const achievement4Image = require('../assets/achievements/achievement4.png');
    let achievement4 = {
        id: "achievement4",
        title: "Director of Program Management",
        description: "Complete 20 projects.",
        category: 'projects',
        trigger: function () {
            return achievement4.flag === 0 && completedProjects.completed.length >= 20
        },
        flag: completedAchievements.completed.includes('achievement4') ? 1 : 0,
        element: null,
        bonusAmount: 15000,
        bonusType: 'OPS',
        image: achievement4Image,
        effect: function () {
            achievement4.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
                    title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Director of Program Management" badge and receive 15,000 OPS!',
					image:  achievement4Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setStandardOps(standardOps + achievement4.bonusAmount));

        }
    }
    achievements_array.push(achievement4);

    const achievement5Image = require('../assets/achievements/achievement5.png');
    let achievement5 = {
        id: "achievement5",
        title: "Small Steps",
        description: "Mine 1,000 units of Unobtanium.",
        category: 'mining',
        trigger: function () {
            return achievement5.flag === 0 && decimalTotalUnobtanium.greaterThanOrEqualTo(new Decimal(1000))
        },
        flag: completedAchievements.completed.includes('achievement5') ? 1 : 0,
        element: null,
        bonusAmount: 5000,
        bonusType: '€$',
        image: achievement5Image,
        imageNumber: 1000,
        effect: function () {
            achievement5.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Small Steps" badge and receive 5,000 Eurodollar!', 
					image:  achievement5Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement5.bonusAmount))));

        }
    }
    achievements_array.push(achievement5);

    const achievement6Image = require('../assets/achievements/achievement6.png');
    let achievement6 = {
        id: "achievement6",
        title: "Unobtanium Digmaster",
        description: "Mine and sell 100,000 units of Unobtanium.",
        category: 'mining',
        trigger: function () {
            return achievement6.flag === 0 && decimalTotalUnobtanium.greaterThanOrEqualTo(new Decimal(100000))
        },
        flag: completedAchievements.completed.includes('achievement6') ? 1 : 0,
        element: null,
        bonusAmount: 1500000,
        bonusType: '€$',
        image: achievement6Image,
        imageNumber: 100000,
        effect: function () {
            achievement6.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Unobtanium Digmaster" badge and receive 1,500,000 Eurodollar!',
					image:  achievement6Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement6.bonusAmount))));

        }
    }
    achievements_array.push(achievement6);


    const achievement7Image = require('../assets/achievements/achievement6.png');
    let achievement7 = {
        id: "achievement7",
        title: "Unobtanium Tycoon",
        description: "Mine and sell 5,000,000 units of Unobtanium.",
        category: 'mining',
        trigger: function () {
            return achievement7.flag === 0 && decimalTotalUnobtanium.greaterThanOrEqualTo(new Decimal(5000000))
        },
        flag: completedAchievements.completed.includes('achievement7') ? 1 : 0,
        element: null,
        bonusAmount: 5000000,
        bonusType: '€$',
        image: achievement7Image,
        imageNumber: 5000000,
        effect: function () {
            achievement7.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Unobtanium Tycoon" badge and receive 5,000,000 Eurodollar!',
					image:  achievement7Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement7.bonusAmount))));

        }
    }
    achievements_array.push(achievement7);

    const achievement8Image = require('../assets/achievements/achievement6.png');
    let achievement8 = {
        id: "achievement8",
        title: "Unobtanium Overlord",
        description: "Mine and sell 10,000,000 units of Unobtanium.",
        category: 'mining',
        trigger: function () {
            return achievement8.flag === 0 && decimalTotalUnobtanium.greaterThanOrEqualTo(new Decimal(10000000))
        },
        flag: completedAchievements.completed.includes('achievement8') ? 1 : 0,
        element: null,
        bonusAmount: 15000000,
        bonusType: '€$',
        image: achievement8Image,
        imageNumber: 10000000,
        effect: function () {
            achievement8.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Unobtanium Overlord" badge and receive 15,000,000 Eurodollar!',
					image:  achievement8Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement8.bonusAmount))));

        }
    }
    achievements_array.push(achievement8);

    const achievement9Image = require('../assets/achievements/achievement6.png');
    let achievement9 = {
        id: "achievement9",
        title: "Giant Leap",
        description: "Mine and sell 100,000,000 units of Unobtanium.",
        category: 'mining',
        trigger: function () {
            return achievement9.flag === 0 && decimalTotalUnobtanium.greaterThanOrEqualTo(new Decimal(100000000))
        },
        flag: completedAchievements.completed.includes('achievement9') ? 1 : 0,
        element: null,
        bonusAmount: 150000000,
        bonusType: '€$',
        image: achievement9Image,
        imageNumber: 100000000,
        effect: function () {
            achievement9.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Giant Leap" badge and receive 150,000,000 Eurodollar!',
					image:  achievement9Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement9.bonusAmount))));

        }
    }
    achievements_array.push(achievement9);

    const achievement10Image = require('../assets/achievements/achievement7.png');
    let achievement10 = {
        id: "achievement10",
        title: "Mechanic Apprentice",
        description: "Upgrade 10 robots to level 3 or higher.",
        category: 'robot_upgrades',
        trigger: function () {
            return achievement10.flag === 0 && robots >= 10 && robotLevel >= 3
        },
        flag: completedAchievements.completed.includes('achievement10') ? 1 : 0,
        element: null,
        bonusAmount: 5000000,
        bonusType: '€$',
        image: achievement10Image,
        imageNumber: 10,
        effect: function () {
            achievement10.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Mechanic Apprentice" badge and receive 5,000,000 Eurodollar!',
					image:  achievement10Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement10.bonusAmount))));
        }
    }
    achievements_array.push(achievement10);

    const achievement11Image = require('../assets/achievements/achievement7.png');
    let achievement11 = {
        id: "achievement11",
        title: "Robot Technician",
        description: "Upgrade 30 robots to level 5 or higher.",
        category: 'robot_upgrades',
        trigger: function () {
            return achievement11.flag === 0 && robots >= 30 && robotLevel >= 5
        },
        flag: completedAchievements.completed.includes('achievement11') ? 1 : 0,
        element: null,
        bonusAmount: 10000000,
        bonusType: '€$',
        image: achievement11Image,
        imageNumber: 30,
        effect: function () {
            achievement11.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Robot Technician" badge and receive 10,000,000 Eurodollar!',
					image:  achievement11Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement11.bonusAmount))));

        }
    }
    achievements_array.push(achievement11);

    const achievement12Image = require('../assets/achievements/achievement7.png');
    let achievement12 = {
        id: "achievement12",
        title: "Automation Specialist",
        description: "Upgrade 50 robots to level 5 or higher.",
        category: 'robot_upgrades',
        trigger: function () {
            return achievement12.flag === 0 && robots >= 50 && robotLevel >= 10
        },
        flag: completedAchievements.completed.includes('achievement12') ? 1 : 0,
        element: null,
        bonusAmount: 10000000,
        bonusType: '€$',
        image: achievement12Image,
        imageNumber: 50,
        effect: function () {
            achievement12.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Automation Specialist" badge and receive 10,000,000 Eurodollar!',
					image:  achievement12Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement12.bonusAmount))));

        }
    }
    achievements_array.push(achievement12);

    const achievement13Image = require('../assets/achievements/achievement7.png');
    let achievement13 = {
        id: "achievement13",
        title: "Robotics Innovator",
        description: "Upgrade 100 robots to level 10 or higher.",
        category: 'robot_upgrades',
        trigger: function () {
            return achievement13.flag === 0 && robots >= 100 && robotLevel >= 15
        },
        flag: completedAchievements.completed.includes('achievement13') ? 1 : 0,
        element: null,
        bonusAmount: 10000000,
        bonusType: '€$',
        image: achievement13Image,
        imageNumber: 100,
        effect: function () {
            achievement13.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Robotics Innovator" badge and receive 10,000,000 Eurodollar!',
					image:  achievement13Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement13.bonusAmount))));

        }
    }
    achievements_array.push(achievement13);

    const achievement14Image = require('../assets/achievements/achievement8.png');
    let achievement14 = {
        id: "achievement14",
        title: "Robot Aficionado",
        description: "Own 50 robots.",
        category: 'robots_purchased',
        trigger: function () {
            return achievement14.flag === 0 && robots >= 50
        },
        flag: completedAchievements.completed.includes('achievement14') ? 1 : 0,
        element: null,
        bonusAmount: 10000000,
        bonusType: '€$',
        image: achievement14Image,
        imageNumber: 50,
        effect: function () {
            achievement14.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Robot Aficionado" badge and receive 10,000,000 Eurodollar!',
					image:  achievement14Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement14.bonusAmount))));

        }
    }
    achievements_array.push(achievement14);

    const achievement15Image = require('../assets/achievements/achievement8.png');
    let achievement15 = {
        id: "achievement15",
        title: "Robot Connoisseur",
        description: "Own 100 robots.",
        category: 'robots_purchased',
        trigger: function () {
            return achievement15.flag === 0 && robots >= 100
        },
        flag: completedAchievements.completed.includes('achievement15') ? 1 : 0,
        element: null,
        bonusAmount: 500000000,
        bonusType: '€$',
        image: achievement15Image,
        imageNumber: 100,
        effect: function () {
            achievement15.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Robot Connoisseur" badge and receive 500,000,000 Eurodollar!',
					image:  achievement15Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement15.bonusAmount))));

        }
    }
    achievements_array.push(achievement15);

    const achievement16Image = require('../assets/achievements/achievement8.png');
    let achievement16 = {
        id: "achievement16",
        title: "Robot Collector",
        description: "Own 300 robots.",
        category: 'robots_purchased',
        trigger: function () {
            return achievement16.flag === 0 && robots >= 300
        },
        flag: completedAchievements.completed.includes('achievement16') ? 1 : 0,
        element: null,
        bonusAmount: 1000000000,
        bonusType: '€$',
        image: achievement16Image,
        imageNumber: 300,
        effect: function () {
            achievement16.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Robot Collector" badge and receive 1,000,000,000 Eurodollar!',
					image:  achievement16Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement16.bonusAmount))));

        }
    }
    achievements_array.push(achievement16);

    const achievement17Image = require('../assets/achievements/achievement8.png');
    let achievement17 = {
        id: "achievement17",
        title: "Robot Tycoon",
        description: "Own 500 robots.",
        category: 'robots_purchased',
        trigger: function () {
            return achievement17.flag === 0 && robots >= 500
        },
        flag: completedAchievements.completed.includes('achievement17') ? 1 : 0,
        element: null,
        bonusAmount: 50000000000,
        bonusType: '€$',
        image: achievement17Image,
        imageNumber: 500,
        effect: function () {
            achievement17.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Robot Tycoon" badge and receive 50,000,000,000 Eurodollar!',
					image:  achievement17Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement17.bonusAmount))));

        }
    }
    achievements_array.push(achievement17);

    const achievement18Image = require('../assets/achievements/achievement8.png');
    let achievement18 = {
        id: "achievement18",
        title: "Robot Overlord",
        description: "Own 1,000 robots.",
        category: 'robots_purchased',
        trigger: function () {
            return achievement18.flag === 0 && robots >= 1000
        },
        flag: completedAchievements.completed.includes('achievement18') ? 1 : 0,
        element: null,
        bonusAmount: 100000000000,
        bonusType: '€$',
        imageNumber: 1000,
        image: achievement18Image,
        effect: function () {
            achievement18.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Robot Overlord" badge and receive 100,000,000,000 Eurodollar!',
					image:  achievement18Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement18.bonusAmount))));

        }
    }
    achievements_array.push(achievement18);


    const achievement19Image = require('../assets/projects/quantum_computer_transparent.png');
    let achievement19 = {
        id: "achievement19",
        title: "Quantum Novice",
        description: "Own a quantum computer.",
        category: 'quantum_computers',
        trigger: function () {
            return achievement19.flag === 0 && quantumComputersActive > 0
        },
        flag: completedAchievements.completed.includes('achievement19') ? 1 : 0,
        element: null,
        bonusAmount: 5000000,
        bonusType: '€$',
        image: achievement19Image,
        effect: function () {
            achievement19.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Quantum Novice" badge and receive 5,000,000 Eurodollar!',
					image:  achievement19Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement19.bonusAmount))));

        }
    }
    achievements_array.push(achievement19);

    const achievement20Image = require('../assets/projects/quantum_computer_transparent.png');
    let achievement20 = {
        id: "achievement20",
        title: "Quantum Apprentice",
        description: "Own 3 quantum computers.",
        category: 'quantum_computers',
        trigger: function () {
            return achievement20.flag === 0 && quantumComputersActive >= 3
        },
        flag: completedAchievements.completed.includes('achievement20') ? 1 : 0,
        element: null,
        bonusAmount: 20000000,
        bonusType: '€$',
        image: achievement20Image,
        imageNumber: 3,
        effect: function () {
            achievement20.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Quantum Apprentice" badge and receive 20,000,000 Eurodollar!',
					image:  achievement20Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement20.bonusAmount))));

        }
    }
    achievements_array.push(achievement20);

    const achievement21Image = require('../assets/projects/quantum_computer_transparent.png');
    let achievement21 = {
        id: "achievement21",
        title: "Quantum Enthusiast",
        description: "Own 5 quantum computers.",
        category: 'quantum_computers',
        trigger: function () {
            return achievement21.flag === 0 && quantumComputersActive >= 5
        },
        flag: completedAchievements.completed.includes('achievement21') ? 1 : 0,
        element: null,
        bonusAmount: 50000000,
        bonusType: '€$',
        image: achievement21Image,
        imageNumber: 5,
        effect: function () {
            achievement21.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Quantum Enthusiast" badge and receive 50,000,000 Eurodollar!',
					image:  achievement21Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement21.bonusAmount))));

        }
    }
    achievements_array.push(achievement21);

    const achievement22Image = require('../assets/projects/quantum_computer_transparent.png');
    let achievement22 = {
        id: "achievement22",
        title: "Quantum Master",
        description: "Own 10 quantum computers.",
        category: 'quantum_computers',
        trigger: function () {
            return achievement22.flag === 0 && quantumComputersActive >= 10
        },
        flag: completedAchievements.completed.includes('achievement22') ? 1 : 0,
        element: null,
        bonusAmount: 150000000,
        bonusType: '€$',
        image: achievement22Image,
        imageNumber: 10,
        effect: function () {
            achievement22.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Quantum Master" badge and receive 150,000,000 Eurodollar!',
					image:  achievement22Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement22.bonusAmount))));

        }
    }
    achievements_array.push(achievement22);

    const achievement23Image = require('../assets/projects/quantum_computer_transparent.png');
    let achievement23 = {
        id: "achievement23",
        title: "Quantum Tycoon",
        description: "Own 25 quantum computers.",
        category: 'quantum_computers',
        trigger: function () {
            return achievement23.flag === 0 && quantumComputersActive >= 25
        },
        flag: completedAchievements.completed.includes('achievement23') ? 1 : 0,
        element: null,
        bonusAmount: 1000000000,
        bonusType: '€$',
        image: achievement23Image,
        imageNumber: 25,
        effect: function () {
            achievement23.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Quantum Tycoon" badge and receive 1,000,000,000 Eurodollar!',
					image:  achievement23Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement23.bonusAmount))));

        }
    }
    achievements_array.push(achievement23);

    const achievement24Image = require('../assets/projects/quantum_computer_transparent.png');
    let achievement24 = {
        id: "achievement24",
        title: "Quantum Overlord",
        description: "Own 50 quantum computers.",
        category: 'quantum_computers',
        trigger: function () {
            return achievement24.flag === 0 && quantumComputersActive >= 50
        },
        flag: completedAchievements.completed.includes('achievement24') ? 1 : 0,
        element: null,
        bonusAmount: 10000000000,
        bonusType: '€$',
        image: achievement24Image,
        imageNumber: 50,
        effect: function () {
            achievement24.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Quantum Overlord" badge and receive 10,000,000,000 Eurodollar!',
					image:  achievement24Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement24.bonusAmount))));

        }
    }
    achievements_array.push(achievement24);

    const achievement25Image = require('../assets/projects/quantum_computer_transparent.png');
    let achievement25 = {
        id: "achievement25",
        title: "Quantum Emperor",
        description: "Own 75 quantum computers.",
        category: 'quantum_computers',
        trigger: function () {
            return achievement25.flag === 0 && quantumComputersActive >= 75
        },
        flag: completedAchievements.completed.includes('achievement25') ? 1 : 0,
        element: null,
        bonusAmount: 50000000000,
        bonusType: '€$',
        image: achievement25Image,
        imageNumber: 75,
        effect: function () {
            achievement25.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Quantum Emperor" badge and receive 50,000,000,000 Eurodollar!',
					image:  achievement25Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement25.bonusAmount))));

        }
    }
    achievements_array.push(achievement25);

    const achievement26Image = require('../assets/projects/quantum_computer_transparent.png');
    let achievement26 = {
        id: "achievement26",
        title: "Quantum Deity",
        description: "Own 100 quantum computers.",
        category: 'quantum_computers',
        trigger: function () {
            return achievement26.flag === 0 && quantumComputersActive >= 100
        },
        flag: completedAchievements.completed.includes('achievement26') ? 1 : 0,
        element: null,
        bonusAmount: 200000000000,
        bonusType: '€$',
        image: achievement26Image,
        imageNumber: 100,
        effect: function () {
            achievement26.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Quantum Deity" badge and receive 200,000,000,000 Eurodollar!',
					image:  achievement26Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement26.bonusAmount))));

        }
    }
    achievements_array.push(achievement26);


    const achievement27Image = require('../assets/projects/quantum_computer_transparent.png');
    let achievement27 = {
        id: "achievement27",
        title: "Quantum Multiverse Master",
        description: "Own 200 quantum computers.",
        category: 'quantum_computers',
        trigger: function () {
            return achievement27.flag === 0 && quantumComputersActive >= 200
        },
        flag: completedAchievements.completed.includes('achievement27') ? 1 : 0,
        element: null,
        bonusAmount: 1000000000000,
        bonusType: '€$',
        image: achievement27Image,
        imageNumber: 200,
        effect: function () {
            achievement27.flag = 1;
            dispatch(stateSetters.setNotificationContent( 
				{
					title: 'Achievement Unlocked', 
					text:'Congratulations! You earned the "Quantum Multiverse Master" badge and receive 1,000,000,000,000 Eurodollar!',
					image:  achievement27Image
				}
			));
            dispatch(stateSetters.setNotificationVisible(true));
            dispatch(stateSetters.setMoney(decimalMoney.plus(new Decimal(achievement27.bonusAmount))));

        }
    }
    achievements_array.push(achievement27);

    return achievements_array;

}