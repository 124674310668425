


// Child component to allow the user to enable remote storage
import {useDispatch, useSelector} from "react-redux";
import {Switch, Text, View} from "react-native";
import {setSoundEnabled} from '../redux/action';
import {AppStyles} from "../AppStyle";

const SoundToggle = () => {
    const soundEnabled = useSelector((state) => state.soundEnabled);
    const dispatch = useDispatch();

    const handleToggleSwitch = (value) => {
        dispatch(setSoundEnabled(value));
    };

    return (
        <View style={AppStyles.row}>
        <Text style={[AppStyles.text, AppStyles.white]}>Enable sound</Text><Switch
            trackColor={{false: '#c6c6c6', true: '#c6c6c6'}}
            thumbColor={soundEnabled ? '#c01212' : '#f4f3f4'}
            ios_backgroundColor="#3e3e3e"
            onValueChange={handleToggleSwitch}
            value={soundEnabled}
        />
        </View>
    );
};

export default SoundToggle;