// Child component that display the achievements
import {AppStyles} from "../AppStyle";
import {
    View,
    Text,
    ScrollView,
    ImageBackground,
    FlatList,
    useWindowDimensions, Switch,
} from 'react-native';
import {useDispatch, useSelector} from "react-redux";
import getAchievements from "../constants/Achievements";
import {numbroFormatCurrency, numbroFormatInt} from "../components/helperFunctions";
import React, {useState} from "react";
import Decimal from "break_infinity.js";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import EventNotification from "../components/EventNotification";

function Achievement(props) {

    const completedBadgeStyle = AppStyles.achievementBadgeImage;
    const incompleteBadgeStyle = [AppStyles.achievementBadgeImage, AppStyles.transparentImage];

    const incompleteRowStyle = [AppStyles.achievementRowSection];
    const completeRowStyle = [AppStyles.achievementRowSection, AppStyles.achievementCompletedRowSection];

    return (
            <View style={[AppStyles.achievementBorder, props.achievementData.flag === 1 ? completeRowStyle :incompleteRowStyle]}>
                <View style={[AppStyles.row, AppStyles.achievementBadgeImageWrap]}>
                <View>
                    <ImageBackground source={props.achievementData.image}
                                     style={props.achievementData.flag === 1 ? completedBadgeStyle : incompleteBadgeStyle }>
                        { typeof props.achievementData.imageNumber !== 'undefined' &&
                            <Text style={[AppStyles.white, AppStyles.achievementNumber, AppStyles.textShadow]}>
                                {numbroFormatInt(props.achievementData.imageNumber, 1000)}
                            </Text>
                        }
                    </ImageBackground>
                </View>
                    <View style={[AppStyles.achievementBadgeImageWrap]}>
                    <Text style={[AppStyles.text]}>{props.achievementData.title}</Text>
                    <Text style={[AppStyles.text, AppStyles.smalltext]}>{props.achievementData.description}</Text>
                    </View>
                </View>
                <Text style={[AppStyles.text,  props.achievementData.flag === 1 ? AppStyles.projectCostCompleted : AppStyles.projectCost,  AppStyles.smalltext]}>
                    {
                        props.achievementData.flag === 1 ?
                            <MaterialCommunityIcons name="check" color={'black'} size={18}/>
                            :
                            <MaterialCommunityIcons name="lock" color={'#fb5607'} size={18}/>
                    }
                    {numbroFormatInt(props.achievementData.bonusAmount, 1000000000000)} {props.achievementData.bonusType}
                </Text>
            </View>
    );
}

const AchievementBadges = () => {

    const dispatch = useDispatch();
    const robotMiningPerInterval = useSelector((state) => state.robotMiningPerInterval);
    const unobtaniumPrice = useSelector((state) => state.unobtaniumPrice);
    const trust = useSelector((state) => state.trust);
    const standardOps = useSelector((state) => state.standardOps);
    const completedProjects = useSelector((state) => state.completedProjects);
    const completedAchievements = useSelector((state) => state.completedAchievements);
    const totalUnobtanium = useSelector((state) => state.totalUnobtanium);
    const decimalTotalUnobtanium = new Decimal(totalUnobtanium);

    const money = useSelector((state) => state.money);
    const decimalMoney = new Decimal(money);
    const robots = useSelector((state) => state.robots);
    const robotLevel = useSelector((state) => state.robotLevel);
    const quantumComputersActive = useSelector((state) => state.quantumComputersActive);


    const achievements = getAchievements(
        dispatch, robotMiningPerInterval, unobtaniumPrice, trust,
        standardOps, completedProjects, completedAchievements,
        decimalTotalUnobtanium, decimalMoney, robots, robotLevel, quantumComputersActive
    );

    const [hideCompleted, setHideCompleted] = useState(false);
    function toggleHideCompleted() {
        setHideCompleted(!hideCompleted);
    }

    const { height: windowHeight } = useWindowDimensions();

    // Filter the achievement list to display the completed achievements
    // and only one incomplete achievement per category
    let filtered_list = [];
    let existing_categories = [];

    achievements.forEach(item => {
        // Add to the list of the project is already completed
        let completed= completedAchievements.completed.includes(item.id);
        if ( completed) {
            if( !hideCompleted ) {
                filtered_list.push(item);
            }
        } else {
            // Check if the category is already in the object
            if ( ! existing_categories.includes(item.category) ) {
                filtered_list.push(item);
                existing_categories.push(item.category);
            }
        }
    });
    const notificationVisible = useSelector( state => state.notificationVisible);
    return (
        <View style={[AppStyles.darkBackground, AppStyles.achievementListWrapper]}>
            {notificationVisible && <EventNotification/>}
            <View style={AppStyles.row}>
            <Text style={[AppStyles.text, AppStyles.white]}>Hide completed</Text><Switch
            trackColor={{false: '#c6c6c6', true: '#c6c6c6'}}
            thumbColor={hideCompleted ? '#788292' : '#f4f3f4'}
            ios_backgroundColor="#3e3e3e"
            onValueChange={toggleHideCompleted}
            value={hideCompleted}
            />
            </View>
                    <View style={[AppStyles.achievementListScrollview]}>
                    <FlatList
                            style={{ height: windowHeight-160 }}
                            data={filtered_list}
                            renderItem={({item, index}) => {
                                return (
                                    <Achievement
                                        key={item.id}
                                        achievementData={item}
                                    />
                                );

                            }}
                            keyExtractor={item => item.id}
                        />
                    </View>
        </View>
    );
}

export default AchievementBadges;