// ./screens/SpaceMiner.js

import React from "react";
import {ImageBackground, View} from "react-native";
import {AppStyles} from "../AppStyle";
import MainComponent from "../components/MainComponent";
import MyModal from "../components/MyModal";
import EventNotification from "../components/EventNotification";
import {useSelector} from "react-redux";

const SpaceMiner = ({ navigation }) => {

    const notificationVisible = useSelector( state => state.notificationVisible);

    return (
        <ImageBackground source={require('../assets/bg_crystal.png')} style={AppStyles.backgroundImage}>
        <View style={AppStyles.container}>
            {notificationVisible && <EventNotification/>}
            <MainComponent navigation={navigation}/>
            <View>
                <MyModal />
            </View>
        </View>
</ImageBackground>
);
};

export default SpaceMiner;