import React from 'react';
import {View, Alert, Pressable, Text, ScrollView, ImageBackground} from 'react-native';
import {AppStyles} from "../AppStyle";
import {useDispatch, useSelector} from "react-redux";
import {resetState} from "../redux/action";
import Login from "../components/SignIn";
import { configureAuth } from '../supabase';
import RemoteStorageToggle from "../components/RemoteStorageSetting";
import SoundToggle from "../components/SoundToggle";
import ResetState from "../components/ResetState";
import EventNotification from "../components/EventNotification";


const Settings = () => {
    const notificationVisible = useSelector( state => state.notificationVisible);
    return (
        <ImageBackground source={require('../assets/bg_circuit.png')} style={AppStyles.backgroundImage}>
            {notificationVisible && <EventNotification/>}
        <View style={AppStyles.container}>
            <View>
                <ScrollView>
                    { /*
                    <View style={[AppStyles.row, AppStyles.settingsSectionWrapper, AppStyles.darkBackground, AppStyles.greyBorder]}>
                        <Login/>
                        <RemoteStorageToggle/>
                    </View>
                    */}
                    <View style={[AppStyles.row, AppStyles.settingsSectionWrapper, AppStyles.darkBackground, AppStyles.greyBorder]}>
                        <SoundToggle />
                    </View>
                    <View style={[AppStyles.settingsSectionWrapper, AppStyles.darkBackground, AppStyles.greyBorder]}>
                        <ResetState />
                    </View>
                    <View style={[AppStyles.settingsSectionWrapper, AppStyles.darkBackground, AppStyles.greyBorder]}>
                        <Text style={[AppStyles.text, AppStyles.smalltext, AppStyles.white]}>v0.1</Text>
                    </View>
                </ScrollView>
            </View>
        </View>
        </ImageBackground>

)}


export default Settings;