export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_PHONE = "SET_USER_PHONE";
export const SET_USE_REMOTE_STORAGE = "SET_USE_REMOTE_STORAGE";
export const SET_LAST_ACTIVE = "SET_LAST_ACTIVE";
export const SET_INSTANCE_ID = "SET_INSTANCE_ID";
export const UPLOAD_BLOCKHASH = "UPLOAD_BLOCKHASH";
export const RESET_STATE = "RESET_STATE";
export const SET_TOTAL_UNOBTANIUM = "SET_TOTAL_UNOBTANIUM";
export const SET_MONEY = "SET_MONEY";
export const SET_STARDUST = "SET_STARDUST";
export const SET_UNOBTANIUM = "SET_UNOBTANIUM";
export const SET_STORAGE_CAPACITY = "SET_STORAGE_CAPACITY";
export const SET_STORAGE_UPGRADE_PRICE = "SET_STORAGE_UPGRADE_PRICE";
export const SET_STORAGE_CAPACITY_STATUS = "SET_STORAGE_CAPACITY_STATUS";
export const SET_UNOBTANIUM_PRICE = "SET_UNOBTANIUM_PRICE";
export const SET_SALES_TAX = "SET_SALES_TAX";
export const SET_ROBOTS = "SET_ROBOTS";
export const SET_ROBOT_PRICE = "SET_ROBOT_PRICE";
export const SET_ROBOT_UPGRADE_PRICE = "SET_ROBOT_UPGRADE_PRICE";
export const SET_ROBOT_LEVEL = "SET_ROBOT_LEVEL";
export const SET_ROBOT_EFFICIENCY = "SET_ROBOT_EFFICIENCY";
export const SET_ROBOT_INTERVAL = "SET_ROBOT_INTERVAL";
export const SET_SOLARPANELS = "SET_SOLARPANELS";
export const SET_SOLARPANEL_PRICE = "SET_SOLARPANEL_PRICE";
export const SET_SOLARPANEL_UPGRADE_PRICE = "SET_SOLARPANEL_UPGRADE_PRICE";
export const SET_SOLARPANEL_LEVEL = "SET_SOLARPANEL_LEVEL";
export const SET_SOLARPANEL_EFFICIENCY = "SET_SOLARPANEL_EFFICIENCY";
export const SET_SOLARPANEL_PARTS_FACTORY_ACTIVE = "SET_SOLARPANEL_PARTS_FACTORY_ACTIVE";
export const SET_CHART_DATA = "SET_CHART_DATA";
export const SET_EPOCH = "SET_EPOCH";
export const SET_TIME_PASSED = "SET_TIME_PASSED";
export const SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT";
export const SET_COMPLETED_PROJECTS = "SET_COMPLETED_PROJECTS";
export const SET_COMPLETED_ACHIEVEMENTS = "SET_COMPLETED_ACHIEVEMENTS";
export const SET_RESEARCH_SPEED = "SET_RESEARCH_SPEED";
export const SET_ACTIVE_EVENT = "SET_ACTIVE_EVENT";
export const SET_MODAL_VISIBLE = "SET_MODAL_VISIBLE";
export const SET_MODAL_CONTENT = "SET_MODAL_CONTENT";
export const SET_NOTIFICATION_VISIBLE = "SET_NOTIFICATION_VISIBLE";
export const SET_NOTIFICATION_CONTENT = "SET_NOTIFICATION_CONTENT";
export const SET_NOTIFICATION_IMAGE_SRC = "SET_NOTIFICATION_IMAGE_SRC";
export const SET_AUTO_SELL_ENABLED = "SET_AUTO_SELL_ENABLED";
export const SET_ROBOT_BUTTON_ENABLED = "SET_ROBOT_BUTTON_ENABLED";
export const SET_ROBOT_UPGRADE_BUTTON_ENABLED = "SET_ROBOT_UPGRADE_BUTTON_ENABLED";
export const SET_ROBOT_PROGRESS = "SET_ROBOT_PROGRESS";
export const SET_ROBOT_PARTS_FACTORY_ACTIVE = "SET_ROBOT_PARTS_FACTORY_ACTIVE";
export const SET_STANDARD_OPS = "SET_STANDARD_OPS";
export const SET_OPS_PROGRESS = "SET_OPS_PROGRESS";
export const SET_OPS_WAIT_SECONDS = "SET_OPS_WAIT_SECONDS";
export const SET_CLICK_POWER = "SET_CLICK_POWER";
export const SET_CLICK_CRIT_CHANCE = "SET_CLICK_CRIT_CHANCE";
export const SET_CLICK_CRIT_MULT = "SET_CLICK_CRIT_MULT";
export const SET_ROBOT_MINING_PER_INTERVAL = "SET_ROBOT_MINING_PER_INTERVAL";
export const SET_SOLAR_BOOST = "SET_SOLAR_BOOST";
export const SET_QUANTUM_COMPUTERS_ACTIVE = "SET_QUANTUM_COMPUTERS_ACTIVE";
export const SET_QUANTUM_COMPUTER_PRICE = "SET_QUANTUM_COMPUTER_PRICE";
export const SET_SPACE_ELEVATOR_ACTIVE = "SET_SPACE_ELEVATOR_ACTIVE";
export const SET_ALIEN_TECH_ACTIVE = "SET_ALIEN_TECH_ACTIVE";
export const SET_PRODUCTION_BOOST = "SET_PRODUCTION_BOOST";
export const SET_DRONE_COUNT = "SET_DRONE_COUNT";
export const SET_DRONE_EFFICIENCY = "SET_DRONE_EFFICIENCY";
export const SET_RARE_UNOBTANIUM_CHANCE = "SET_RARE_UNOBTANIUM_CHANCE";
export const SET_RARE_UNOBTANIUM_CHANCE_INTERVAL = "SET_RARE_UNOBTANIUM_CHANCE_INTERVAL";
export const SET_BLACK_HOLE_ACTIVE = "SET_BLACK_HOLE_ACTIVE";
export const SET_BLACK_HOLE_LEVEL = "SET_BLACK_HOLE_LEVEL";
export const SET_HEDGE_FUND_INVESTMENT = "SET_HEDGE_FUND_INVESTMENT";
export const SET_HEDGE_FUND_BALANCE = "SET_HEDGE_FUND_BALANCE";
export const SET_HEDGE_FUND_CASH = "SET_HEDGE_FUND_CASH";
export const SET_HEDGE_FUND_STOCKS = "SET_HEDGE_FUND_STOCKS";
export const SET_TRADING_ENGINE_LEVEL = "SET_TRADING_ENGINE_LEVEL";
export const SET_TRADING_ENGINE_UPGRADE_PRICE = "SET_TRADING_ENGINE_UPGRADE_PRICE";
export const SET_TRADING_ENGINE_UPGRADE_ENABLED = "SET_TRADING_ENGINE_UPGRADE_ENABLED";
export const SET_TRADING_TABLE_DATA = "SET_TRADING_TABLE_DATA";
export const SET_STOCKS = "SET_STOCKS";
export const SET_INVEST_STRATEGY = "SET_INVEST_STRATEGY";
export const SET_MARKET_ANALYSIS = "SET_MARKET_ANALYSIS";
export const SET_FUTURES_MARKET = "SET_FUTURES_MARKET";
export const SET_UNOBTANIUM_AMOUNT_BOOST = "SET_UNOBTANIUM_AMOUNT_BOOST";
export const SET_UNOBTANIUM_PRICE_BOOST = "SET_UNOBTANIUM_PRICE_BOOST";
export const SET_BOOST_EPOCHS = "SET_BOOST_EPOCHS";
export const SET_TRUST = "SET_TRUST";
export const SET_TRUST_THRESHOLD = "SET_TRUST_THRESHOLD";
export const SET_PROJECT_COUNT = "SET_PROJECT_COUNT";
export const SET_PROJECT_COST_MULT = "SET_PROJECT_COST_MULT";
export const SET_TRANS_COST_MULT = "SET_TRANS_COST_MULT";
export const SET_ASTEROID_ALERT = "SET_ASTEROID_ALERT";
export const SET_SOUND_ENABLED = "SET_SOUND_ENABLED";

export const SET_GAME_RUNNING = "SET_GAME_RUNNING";
export const SET_ROCKS = "SET_ROCKS";
export const SET_LAST_MINIGAME = "SET_LAST_MINIGAME";
export const SET_REWARD_AVAILABLE = "SET_REWARD_AVAILABLE";
export const SET_LIVES = "SET_LIVES";
export const SET_SCORE = "SET_SCORE";
export const TICK_TIMER = "TICK_TIMER";
export const RESET_ROCKS = "RESET_ROCKS";
export const END_ROCK_SMASHER_GAME = "END_ROCK_SMASHER_GAME";
export const SET_WAS_OFFLINE = "SET_WAS_OFFLINE";
export const SET_CLOCK_MILLI_SECONDS = "SET_CLOCK_MILLI_SECONDS";
export const SET_LAST_UPDATE = "SET_LAST_UPDATE";
export const SET_LAST_ACTIVE_UPDATE = "SET_LAST_ACTIVE_UPDATE";
export const SET_TIME_PASSED_SECONDS = "SET_TIME_PASSED_SECONDS";

export const setUserId = value => ( {
    type: 'SET_USER_ID', payload: value
});
export const setUserEmail = value => ( {
    type: 'SET_USER_EMAIL', payload: value
});
export const setUserPhone = value => ( {
    type: 'SET_USER_PHONE', payload: value
});
export const setUseRemoteStorage = value => ( {
    type: 'SET_USE_REMOTE_STORAGE', payload: value
});
export const setLastActive = value => ( {
    type: 'SET_LAST_ACTIVE', payload: value
});
export const setInstanceId = value => ( {
    type: 'SET_INSTANCE_ID', payload: value
});
export const uploadBlockHash = value => ( {
    type: 'UPLOAD_BLOCKHASH', payload: value
});

export const resetState = value => ( {
    type: 'RESET_STATE', payload: value
});
export const setTotalUnobtanium = value => ( {
    type: 'SET_TOTAL_UNOBTANIUM', payload: value.toString()
});

export const setMoney = value => ( {
    type: 'SET_MONEY', payload: value.toString()
});

export const setStardust = value => ( {
    type: 'SET_STARDUST', payload: value
});

export const setUnobtanium = value => ( {
    type: 'SET_UNOBTANIUM', payload: value.toString()
});
export const setStorageCapacity = value => ( {
    type: 'SET_STORAGE_CAPACITY', payload: value
});
export const setStorageUpgradePrice = value => ( {
    type: 'SET_STORAGE_UPGRADE_PRICE', payload: value
});
export const setStorageCapacityStatus = value => ( {
    type: 'SET_STORAGE_CAPACITY_STATUS', payload: value
});

export const setUnobtaniumPrice = value => ( {
    type: 'SET_UNOBTANIUM_PRICE', payload: value
});

export const setSalesTax = value => ( {
    type: 'SET_SALES_TAX', payload: value
});

export const setRobots = value => ( {
    type: 'SET_ROBOTS', payload: value
});

export const setRobotPrice = value => ( {
    type: 'SET_ROBOT_PRICE', payload: value
});

export const setRobotUpgradePrice = value => ( {
    type: 'SET_ROBOT_UPGRADE_PRICE', payload: value
});

export const setRobotLevel = value => ( {
    type: 'SET_ROBOT_LEVEL', payload: value
});
export const setRobotEfficiency = value => ( {
    type: 'SET_ROBOT_EFFICIENCY', payload: value
});
export const setRobotInterval = value => ( {
    type: 'SET_ROBOT_INTERVAL', payload: value
});

export const setSolarPanels = value => ( {
    type: 'SET_SOLARPANELS', payload: value
});

export const setSolarPanelPrice = value => ( {
    type: 'SET_SOLARPANEL_PRICE', payload: value
});

export const setSolarPanelUpgradePrice = value => ( {
    type: 'SET_SOLARPANEL_UPGRADE_PRICE', payload: value
});

export const setSolarPanelLevel = value => ( {
    type: 'SET_SOLARPANEL_LEVEL', payload: value
});
export const setSolarPanelEfficiency = value => ( {
    type: 'SET_SOLARPANEL_EFFICIENCY', payload: value
});

export const setSolarPanelsPartsFactoryActive = value => ( {
    type: 'SET_SOLARPANEL_PARTS_FACTORY_ACTIVE', payload: value
});

export const setChartData = value => ( {
    type: 'SET_CHART_DATA', payload: value
});

export const setEpoch = value => ( {
    type: 'SET_EPOCH', payload: value
});

export const setTimePassed = value => ( {
    type: 'SET_TIME_PASSED', payload: value
});

export const setActiveProject = value => ( {
    type: 'SET_ACTIVE_PROJECT', payload: value
});

export const setCompletedProjects = value => ( {
    type: 'SET_COMPLETED_PROJECTS', payload: value
});

export const setCompletedAchievements = value => ( {
    type: 'SET_COMPLETED_ACHIEVEMENTS', payload: value
});

export const setResearchSpeed = value => ( {
    type: 'SET_RESEARCH_SPEED', payload: value
});

export const setActiveEvent = value => ( {
    type: 'SET_ACTIVE_EVENT', payload: value
});

export const setModalVisible = value => ( {
    type: 'SET_MODAL_VISIBLE', payload: value
});

export const setModalContent = value => ( {
    type: 'SET_MODAL_CONTENT', payload: value
});

export const setNotificationVisible = value => ( {
    type: 'SET_NOTIFICATION_VISIBLE', payload: value
});

export const setNotificationContent = value => ( {
    type: 'SET_NOTIFICATION_CONTENT', payload: value
});

export const setAutoSellEnabled = value => ( {
    type: 'SET_AUTO_SELL_ENABLED', payload: value
});

export const setRobotButtonEnabled = value => ( {
    type: 'SET_ROBOT_BUTTON_ENABLED', payload: value
});

export const setRobotUpgradeButtonEnabled = value => ( {
    type: 'SET_ROBOT_UPGRADE_BUTTON_ENABLED', payload: value
});

export const setRobotProgress = value => ( {
    type: 'SET_ROBOT_PROGRESS', payload: value
});

export const setRobotsPartsFactoryActive = value => ( {
    type: 'SET_ROBOT_PARTS_FACTORY_ACTIVE', payload: value
});

export const setStandardOps = value => ( {
    type: 'SET_STANDARD_OPS', payload: value
});

export const setOpsProgress = value => ( {
    type: 'SET_OPS_PROGRESS', payload: value
});

export const setOpsWaitSeconds = value => ( {
    type: 'SET_OPS_WAIT_SECONDS', payload: value
});

export const setClickPower = value => ( {
    type: 'SET_CLICK_POWER', payload: value
});

export const setClickCritChance = value => ( {
    type: 'SET_CLICK_CRIT_CHANCE', payload: value
});

export const setClickCritMult = value => ( {
    type: 'SET_CLICK_CRIT_MULT', payload: value
});

export const setRobotMiningPerInterval = value => ( {
    type: 'SET_ROBOT_MINING_PER_INTERVAL', payload: value
});

export const setSolarBoost = value => ( {
    type: 'SET_SOLAR_BOOST', payload: value
});

export const setQuantumComputersActive = value => ( {
    type: 'SET_QUANTUM_COMPUTERS_ACTIVE', payload: value
});

export const setQuantumComputerPrice = value => ( {
    type: 'SET_QUANTUM_COMPUTER_PRICE', payload: value
});

export const setSpaceElevatorActive = value => ( {
    type: 'SET_SPACE_ELEVATOR_ACTIVE', payload: value
});

export const setAlienTechActive = value => ( {
    type: 'SET_ALIEN_TECH_ACTIVE', payload: value
});

export const setProductionBoost = value => ( {
    type: 'SET_PRODUCTION_BOOST', payload: value
});

export const setDroneCount = value => ( {
    type: 'SET_DRONE_COUNT', payload: value
});

export const setDroneEfficiency = value => ( {
    type: 'SET_DRONE_EFFICIENCY', payload: value
});

export const setRareUnobtaniumChance = value => ( {
    type: 'SET_RARE_UNOBTANIUM_CHANCE', payload: value
});

export const setRareUnobtaniumChanceInterval = value => ( {
    type: 'SET_RARE_UNOBTANIUM_CHANCE_INTERVAL', payload: value
});

export const setBlackHoleActive = value => ( {
    type: 'SET_BLACK_HOLE_ACTIVE', payload: value
});

export const setBlackHoleLevel = value => ( {
    type: 'SET_BLACK_HOLE_LEVEL', payload: value
});

export const setHedgeFundInvestment = value => ( {
    type: 'SET_HEDGE_FUND_INVESTMENT', payload: value
});
export const setHedgeFundBalance = value => ( {
    type: 'SET_HEDGE_FUND_BALANCE', payload: value.toString()
});
export const setHedgeFundCash = value => ( {
    type: 'SET_HEDGE_FUND_CASH', payload: value.toString()
});
export const setHedgeFundStocks = value => ( {
    type: 'SET_HEDGE_FUND_STOCKS', payload: value.toString()
});

export const setTradingEngineLevel = value => ( {
    type: 'SET_TRADING_ENGINE_LEVEL', payload: value
});

export const setTradingEngineUpgradePrice = value => ( {
    type: 'SET_TRADING_ENGINE_UPGRADE_PRICE', payload: value.toString()
});

export const setTradingEngineUpgradeEnabled = value => ( {
    type: 'SET_TRADING_ENGINE_UPGRADE_ENABLED', payload: value
});
export const setTradingTableData = value => ( {
    type: 'SET_TRADING_TABLE_DATA', payload: value
});
export const setStocks = value => ( {
    type: 'SET_STOCKS', payload: value
});

export const setInvestStrategy = value => ( {
    type: 'SET_INVEST_STRATEGY', payload: value
});

export const setMarketAnalysis = value => ( {
    type: 'SET_MARKET_ANALYSIS', payload: value
});

export const setFuturesMarket = value => ( {
    type: 'SET_FUTURES_MARKET', payload: value
});

export const setUnobtaniumAmountBoost = value => ( {
    type: 'SET_UNOBTANIUM_AMOUNT_BOOST', payload: value
});

export const setUnobtaniumPriceBoost = value => ( {
    type: 'SET_UNOBTANIUM_PRICE_BOOST', payload: value
});

export const setBoostEpochs = value => ( {
    type: 'SET_BOOST_EPOCHS', payload: value
});

export const setTrust = value => ( {
    type: 'SET_TRUST', payload: value
});

export const setTrustThreshold = value => ( {
    type: 'SET_TRUST_THRESHOLD', payload: value
});

export const setProjectCount = value => ( {
    type: 'SET_PROJECT_COUNT', payload: value
});

export const setProjectCostMult = value => ( {
    type: 'SET_PROJECT_COST_MULT', payload: value
});

export const setTransCostMult = value => ( {
    type: 'SET_TRANS_COST_MULT', payload: value
});

export const setAsteroidAlert = value => ( {
    type: 'SET_ASTEROID_ALERT', payload: value
});

export const setSoundEnabled = value => ( {
    type: 'SET_SOUND_ENABLED', payload: value
});



export const setLastMinigame = value => ({
    type: 'SET_LAST_MINIGAME', payload: value
});
export const setRocks = value => ({
    type: 'SET_ROCKS', payload: value
});
export const setGameRunning = value => ({
    type: 'SET_GAME_RUNNING', payload: value
});
export const setRewardAvailable = value => ({
    type: 'SET_REWARD_AVAILABLE', payload: value.toString()
});
export const setLives = value => ({
    type: 'SET_LIVES', payload: value
});
export const setScore = value => ({
    type: 'SET_SCORE', payload: value
});
export const smashRock = value => ({
    type: 'SMASH_ROCK', payload: value
});
export const tickCountdown = () => ({
    type: 'TICK_COUNTDOWN',
});
export const resetRocks = () => ({
    type: 'RESET_ROCKS',
});

export const endRockSmasherGame = () => ({
    type: 'END_ROCK_SMASHER_GAME',
});
export const setWasOffline = () => ({
    type: 'SET_WAS_OFFLINE',
});
export const setClockMilliSeconds = () => ({
    type: 'SET_CLOCK_MILLI_SECONDS',
});

export const setLastUpdate = () => ({
    type: 'SET_LAST_UPDATE',
});

export const setLastActiveUpdate= () => ({
    type: 'SET_LAST_ACTIVE_UPDATE',
});

export const setTimePassedSeconds = () => ({
    type: 'SET_TIME_PASSED_SECONDS',
});
