
export const privacyPolicyUrl = 'https://trustless-technologies.com/privacy-policy/';
export const imprintUrl = 'https://trustless-technologies.com/imprint/';

// Initiate the clock
export let clockMilliSeconds = 0;
export let lastUpdate = Date.now();
export let lastActiveUpdate= Date.now();
export let wasOffline = false;
export let timePassedSeconds = 0;

export let lastChartUpdate = 0;

// Autosell interval
export const sellProduceInterval = 0.5;

// Time in seconds between price updates
export const updateSalePriceInterval = 60;
export let lastSalePriceUpdate = Date.now();

// Stock trading interval
export const stockTradingInterval = 2000;
export let lastStockTrading = Date.now();

export const timerSeconds = 0.2;

export const timerMilliSec = timerSeconds * 1000;


export let lastMined = Date.now();
export let lastProgress = 0;

// The progress in the batch production interval as decimal - used to update the progress bar
export let robotProgressDecimal = 0;

// Count the number of seconds during the production of a batch
export let robotIntervalCount = 0;


// Every 60 seconds
export const eventInterval = 180000;
// 0.2 = 20% chance of an event
export const eventChance = 0.4;
export let lastEvent = Date.now();



export let lastOPS = Date.now();

// The progress in the OPS production interval as decimal - used to update the progress bar
export let opsProgressDecimal = 0;

// Upload interval
export let lastUpload = Date.now(); // TODO refactor
export const uploadIntervalMin = 1;
export const uploadInterval = uploadIntervalMin * 60 * 1000; // Milliseconds

export let storageFullNoticeDismissed = false;


export let oldProjectCount = 1;

// Tax increase percentage
export const increasePercentage = 0.1; // 0.1 percent increase

export const robotEnergyUsage = 0.4;
export const quantumComputerEnergyUsage = 0.2;
export const robotsPartsFactoryEnergyUsage = 0.2;
export const solarpanelsPartsFactoryEnergyUsage = 0.5
export const spaceElevatorEnergyUsage = 1;

export const opsProductionPerInterval = 50;

// Rock smasher mini game
export const timeBetweenMinigames = 240000; // Minimum time between minigames
export const gridSize = 36;
export const gameDuration = 30; // Game duration in seconds
export const gameLives = 3; // Number of times we can hit a hazard
export const hazardChance = 0.3; // Chance of a rock having a hazard